import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./style.scss";

type SpinnerProps = {
  size?: number;
  color?: string;
};

const Spinner: React.FC<SpinnerProps> = ({ size = 100, color = "white" }) => (
  <div className="spinner-container">
    <Spin
      className="spinner-container-spinner"
      indicator={<LoadingOutlined style={{ fontSize: size }} spin />}
      style={{ color: color }}
    />
  </div>
);

export default Spinner;
