import "./style.scss";

import AppleLogo from "../../assets/icons/apple_logo.svg";
import GoogleLogo from "../../assets/icons/google_logo.svg";
import Loading from "../loading";

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  outlined?: boolean;
  error?: boolean;
  disabled?: boolean;
  loading?: boolean;
  size?: "md" | "sm";
  classes?: string[];
  mainColor?: string;
  secondaryColor?: string;
  highlightColor?: string;
}
const Button = ({
  outlined = false,
  error = false,
  disabled = false,
  loading = false,
  size = "md",
  classes = [],
  mainColor,
  secondaryColor,
  highlightColor,
  style,
  children,
  ...rest
}: ButtonProps) => {
  let _classes = [];
  _classes.push("hyfe_button");
  if (outlined) _classes.push("hb-outlined");
  if (disabled) _classes.push("hb-disabled");
  if (loading) _classes.push("hb-loading");
  if (loading) _classes.push("hb-disabled");
  if (error) _classes.push("hb-error");
  else _classes.push("hb-primary");

  _classes.push(size);
  _classes.push(...classes);

  const extraStyle = {
    "--hb-main-color": mainColor,
    "--hb-secondary-color": secondaryColor,
    "--hb-highlight-color": highlightColor,
  } as React.CSSProperties;

  return (
    <button className={_classes.join(" ")} {...rest} disabled={disabled} style={{ ...style, ...extraStyle }}>
      <Loading loading={loading} size={32} background={false} />
      {children}
    </button>
  );
};

export default Button;

interface AppleButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  size?: "md" | "sm";
}

export const AppleButton = ({ children, size = "md", ...rest }: AppleButtonProps) => {
  return (
    <button className={`hyfe_button apple ${size}`} {...rest}>
      <img src={AppleLogo} alt="apple_logo" />
      {children}
    </button>
  );
};
interface GoogleButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  size?: "md" | "sm";
}

export const GoogleButton = ({ children, size = "md", ...rest }: GoogleButtonProps) => {
  return (
    <button className={`hyfe_button google ${size}`} {...rest}>
      <img src={GoogleLogo} alt="google_logo" />
      {children}
    </button>
  );
};
