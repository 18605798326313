import PayWall from ".";
import Loading from "../loading";
import { AuthState } from "../../state/auth";
import { useRecoilValue } from "recoil";
import useLocalStorageState from "use-local-storage-state";
import dayjs from "dayjs";

interface PremiumWrapperProps {
  children: React.ReactNode;
  paywallType?: "subscribe" | "notice";
}

export const PremiumWrapper = ({ children, paywallType = "subscribe" }: PremiumWrapperProps) => {
  const { isPremium, isInTrialPeriod, isHyfeStaff } = useRecoilValue(AuthState);
  const [trialEndingWarningWavedTs] = useLocalStorageState<string | undefined>("trialEndingWarningWavedTs");

  const showTrialEndingWarning =
    trialEndingWarningWavedTs && dayjs(trialEndingWarningWavedTs).diff(dayjs(), "hour") > 8;

  const isPayingUser = isPremium && !isInTrialPeriod;
  const isTrialUser = isPremium && isInTrialPeriod;

  const hidePaywall =
    // Hide if premium
    isPayingUser ||
    // Hide if not premium but in trial period and already waved the paywall away this session
    (isTrialUser && trialEndingWarningWavedTs && !showTrialEndingWarning);
  // Hide if hyfe staff
  // isHyfeStaff;

  if (isPremium === undefined || isInTrialPeriod === undefined) {
    // State not settled yet
    return (
      <div>
        <Loading loading />
      </div>
    );
  } else if (!hidePaywall) {
    if (paywallType === "subscribe") {
      return <PayWall />;
    } else {
      return <div>Content only for Premium members</div>;
    }
  } else {
    return <>{children}</>;
  }
};
