import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isSameYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import { TODAY_VIEW_MODE } from "../models/event";

enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Satuday = 6,
}

const getRangeWeek = (date: Date) => {
  const firstWeekDay = startOfWeek(date);
  const endWeekDay = endOfWeek(date);
  return `${format(firstWeekDay, "ddd")}-${format(endWeekDay, "d MMM")}`;
};

const getWeeksLabel = (date: Date, index: number) => {
  const lastDay = subWeeks(date, index);
  const firstDay = subDays(date, (index + 1) * 7 - 1);
  if (format(firstDay, "MMM") === format(lastDay, "MMM")) {
    return `${format(firstDay, "dd")}-${format(lastDay, "dd MMM yyyy")}`;
  } else {
    return `${format(firstDay, "dd MMM")}-${format(lastDay, "dd MMM yyyy")}`;
  }
};

const getMonthLabel = (date: Date, index: number) => {
  const lastDay = subDays(date, index * 30);
  const firstDay = subDays(date, (index + 1) * 30 - 1);
  return `${format(firstDay, "dd MMM")}-${format(lastDay, "dd MMM yyyy")}`;
};

const getYearLabel = (date: Date, index: number) => {
  const lastDay = subMonths(date, index * 12);
  const firstDay = subMonths(date, (index + 1) * 12 - 1);
  return `${format(firstDay, "dd MMM yyyy")}-${format(lastDay, "dd MMM yyyy")}`;
};

const getRangeMonth = (date: Date) => {
  const firstMonthDay = startOfMonth(date);
  const endMonthDay = endOfMonth(date);
  return `${format(firstMonthDay, "dd")}-${format(endMonthDay, "d MMM yyyy")}`;
};

const getRangeYear = (date: Date) => {
  const firstYearDay = startOfYear(date);
  const endYearDay = endOfYear(date);
  return `${format(firstYearDay, "MMM")}-${format(endYearDay, "MMM yyyy")}`;
};

const getTime = (time_s: number) => {
  const totalMinutes = Math.floor(time_s / 60);
  const seconds = time_s % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return {
    hours,
    minutes,
    seconds,
  };
};

const getQuarterFormat = (date: Date) => {
  const hour = format(date, "HH");
  const minute = format(date, "mm");
  return `${hour}:${String(Math.floor(Number(minute) / 15) * 15).padStart(2, "0")}`;
};

const DATE_SHORT_FORMAT = "yyyy-MM-dd";
const DATE_API_FORMAT = `${DATE_SHORT_FORMAT}'T'HH:mm:ss`;
const DATE_API_REQ_FORMAT_WITH_TZ = `${DATE_API_FORMAT}XXX`;
const apiDateTimeFormat = (time: Date) => format(time, DATE_API_REQ_FORMAT_WITH_TZ);
const shortDateFormat = (time: Date) => format(time, DATE_SHORT_FORMAT);

const startOfWeekSunday = (date: Date) => startOfWeek(date, { weekStartsOn: DayOfWeek.Sunday });
const startOfWeekMonday = (date: Date) => startOfWeek(date, { weekStartsOn: DayOfWeek.Monday });
const endOfWeekSunday = (date: Date) => endOfWeek(date, { weekStartsOn: DayOfWeek.Sunday });
const endOfWeekMonday = (date: Date) => endOfWeek(date, { weekStartsOn: DayOfWeek.Monday });

const getCalendarWeekLabel = (date: Date, weekStartsOn: DayOfWeek = DayOfWeek.Sunday) => {
  const firstDay = startOfWeek(date, { weekStartsOn });
  const lastDay = endOfWeek(date, { weekStartsOn });
  if (format(firstDay, "MMM") === format(lastDay, "MMM"))
    return `${format(firstDay, "dd")}-${format(lastDay, "dd MMM yyyy")}`;
  else return `${format(firstDay, "dd MMM")}-${format(lastDay, "dd MMM yyyy")}`;
};

const getCalendarMonthLabel = (date: Date) => {
  const firstDay = startOfMonth(date);
  const lastDay = endOfMonth(date);
  return `${format(firstDay, "dd MMM")}-${format(lastDay, "dd MMM yyyy")}`;
};
const getCurrentMonthLabel = (date: Date) => {
  return `${format(date, "MMM yyyy")}`;
};

const getCalendarYearLabel = (date: Date) => {
  const firstDay = startOfYear(date);
  const lastDay = endOfYear(date);
  return `${format(firstDay, "MMM yyyy")}-${format(lastDay, "MMM yyyy")}`;
};

const getWholeYearLabel = (date: Date) => {
  return `${format(date, "yyyy")}`;
};

const getShortMonthLabel = (date: Date) => {
  return `${format(date, "MMM")}`;
};

const getLongMonthLabel = (date: Date) => {
  return `${format(date, "MMMM")}`;
};

const formatShortTime = (time: Date) => format(time, "hh:mm aaa");
const formatShortTimeSecs = (time: Date, showAMPM: boolean = false) =>
  format(time, `hh:mm:ss ${showAMPM ? "aaa" : ""}`);
const formatShortTimeMs = (time: Date, msLength: number = 3) => format(time, `hh:mm:ss.${"".padStart(msLength, "S")}`);

export type ViewModeFormatters = {
  daily: string;
  weekly: string;
  monthly: string;
  yearly: string;
};

const getLabelForDate = (date: Date, viewMode: TODAY_VIEW_MODE | string, viewModeFormatters?: ViewModeFormatters) => {
  switch (viewMode) {
    case TODAY_VIEW_MODE.VIEW_WEEKLY:
      if (viewModeFormatters?.weekly) return viewModeFormatters.weekly;
      return `${format(startOfWeekMonday(date), "dd LLL")} ${
        !isSameYear(startOfWeekMonday(date), endOfWeekMonday(date)) ? format(startOfWeekMonday(date), "y") : ""
      } - ${format(endOfWeekMonday(date), "dd LLL")} ${
        !isSameYear(startOfWeekMonday(date), endOfWeekMonday(date)) ? format(endOfWeekMonday(date), "y") : ""
      }`;
    case TODAY_VIEW_MODE.VIEW_MONTHLY:
      if (viewModeFormatters?.monthly) return viewModeFormatters.monthly;
      return `${format(date, "MMMM y")}`;
    case TODAY_VIEW_MODE.VIEW_YEARLY:
      if (viewModeFormatters?.yearly) return viewModeFormatters.yearly;
      return `${format(date, "y")}`;
    default:
      if (viewModeFormatters?.daily) return viewModeFormatters?.daily;
      return date.toLocaleDateString(["en-US", "en-GB", "es-ES", "es-PY", "en", "es"], {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
  }
};

export {
  getRangeWeek,
  getWeeksLabel,
  getMonthLabel,
  getYearLabel,
  getWholeYearLabel,
  getRangeMonth,
  getRangeYear,
  getTime,
  getQuarterFormat,
  DayOfWeek,
  startOfWeekMonday,
  endOfWeekSunday,
  endOfWeekMonday,
  startOfWeekSunday,
  getCalendarWeekLabel,
  getCalendarMonthLabel,
  getCalendarYearLabel,
  apiDateTimeFormat,
  shortDateFormat,
  getCurrentMonthLabel,
  getShortMonthLabel,
  getLongMonthLabel,
  formatShortTime,
  formatShortTimeSecs,
  formatShortTimeMs,
  getLabelForDate,
};
