import React from "react";
import "./style.scss";
import { CoughTrend, ECoughStatus, TLevel } from "../../model";
import CoughChart from "./cough-chart";
import IconDown from "../../assets/icons/icon_down.svg";

const DEFAULT_LEVELS: TLevel[] = [
  {
    color: "#DB4456",
    backgroundColor: "#FBE9EB",
    from: 0.5109133,
    to: "-",
    angle: 30,
    message: () => <span>{"Higher"}</span>,
    value: ECoughStatus.MUCH_HIGHER,
  },
  {
    color: "#EE8449",
    backgroundColor: "#FFEADE",
    from: 0.2744849,
    to: 0.5109133,
    angle: 15,
    message: () => <span>{"Higher"}</span>,
    value: ECoughStatus.SOMEWHAT_HIGHER,
  },
  {
    color: "#F3AC20",
    backgroundColor: "#FEF5E4",
    from: -0.2744849,
    to: 0.2744849,
    angle: 0,
    message: () => <span>{"About the same"}</span>,
    value: ECoughStatus.ABOUT_SAME,
  },
  {
    color: "#71B912",
    backgroundColor: "#EFFFDB",
    from: -0.5109133,
    to: -0.2744849,
    angle: -15,
    message: () => <span>{"Lower"}</span>,
    value: ECoughStatus.SOMEWHAT_LOWER,
  },
  {
    color: "#328B6B",
    backgroundColor: "#E8F6F1",
    from: "-",
    to: -0.5109133,
    angle: -30,
    message: () => (
      <>
        <span>{"Lower"}</span>
      </>
    ),
    value: ECoughStatus.GETTING_LOWER,
  },
];

type CoughWidgetProps = {
  title: string;
  data: CoughTrend;
  description: string;
  levels?: TLevel[];
};

const CoughWidget: React.FC<CoughWidgetProps> = ({
  title,
  data,
  description,
  levels = DEFAULT_LEVELS,
}) => {
  // const status = useMemo(() => {
  //   let comparison: number = 0;
  //   if (oldData === 0) {
  //     if (newData > 0) {
  //       comparison = 100;
  //     } else {
  //       comparison = 0;
  //     }
  //   } else {
  //     comparison = Math.round(((newData - oldData) / oldData) * 100);
  //   }

  //   for (let index = 0; index < levels.length; index++) {
  //     if (
  //       (comparison >= levels[index].from || levels[index].from === "-") &&
  //       (comparison < levels[index].to || levels[index].to === "-")
  //     ) {
  //       return { comparison: comparison, status: levels[index] };
  //     }
  //   }
  // }, [newData, oldData, levels]);

  const getStatus = () => {
    let comparison: number = (data.direction === "up" ? 1 : -1) * data.angle;

    for (let index = 0; index < levels.length; index++) {
      if (
        (levels[index].from === "-" ||
          comparison >= Number(levels[index].from)) &&
        (levels[index].to === "-" || comparison < Number(levels[index].to))
      ) {
        return { comparison: comparison, status: levels[index] };
      }
    }
    return { comparison: comparison, status: levels[0] };
  };

  const status = getStatus();

  return (
    <div className="cough_item">
      <div className="cough_item-wrapper">
        <div className="cough_item-wrapper-left">
          <div className="cough_item-wrapper-left-title">{title}</div>
          <div
            className="cough_item-wrapper-left-alert"
            style={{
              color: "#fff",
              backgroundColor: status?.status.color,
            }}
          >
            {(status?.status?.value === ECoughStatus.MUCH_HIGHER ||
              status?.status?.value === ECoughStatus.SOMEWHAT_HIGHER) && (
              <img
                src={IconDown}
                className="cough_item-wrapper-left-alert-icon_up"
              />
            )}
            {(status?.status?.value === ECoughStatus.SOMEWHAT_LOWER ||
              status?.status?.value === ECoughStatus.GETTING_LOWER) && (
              <img
                src={IconDown}
                className="cough_item-wrapper-left-alert-icon_down"
              />
            )}
            {status?.status?.message && status.status.message()}
          </div>
          <div className="cough_item-wrapper-left-footer">{description}</div>
        </div>
        <div className="cough_item-wrapper-right">
          <CoughChart
            status={status?.status?.value || ECoughStatus.ABOUT_SAME}
          />
        </div>
      </div>
    </div>
  );
};

export default CoughWidget;
