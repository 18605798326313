import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  endOfMonth,
  format,
  getDaysInMonth,
  isSameDay,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Tabs from "../../components/tabs";
import {
  DataType,
  EVENT_LIMIT,
  TODAY_VIEW_MODE,
  CoughClockEvent,
  CoughBurstClockResponse,
  CoughBurstEventResponse,
  CoughBurstEvent,
  EVENT_AGGREGATION,
} from "../../models/event";
import { api } from "../../utils/axios";
import "./style.scss";
import {
  apiDateTimeFormat,
  getTime,
  startOfWeekMonday,
  endOfWeekMonday,
  shortDateFormat,
  getCurrentMonthLabel,
} from "../../utils/date-operation";
import { CoughLabel } from "@hyfe/react-widget-lib";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import ReferenceBar from "../../components/reference-bar";
import { colors } from "../../components/clock-chart/utils/colors";
import ClockChart from "../../components/clock-chart";
import DatePicker from "../../components/date-picker";
import CardLoader from "../../components/card-loader";
import useSwipe from "../../components/useSwipe";
import IconArrow from "../../assets/icons/swipe_arrow.svg";
import { AppState } from "../../state/global";
import { useRecoilValue } from "recoil";
import BurstDetails from "../../components/burst-details";
import { BurstData } from "../../models/burst";
import BurstHistoryGraph from "../../components/burst-history-graph";
import { fillArrayWithRange } from "../../utils/misc";
import Loading from "../../components/loading";
import { CurrentDateState } from "../../state/currentDate";

const EmptyBurstData: BurstData = {
  totalHour: {
    hours: 0,
    minutes: 0,
    seconds: 0,
    s_time: 0,
  },
  totalBursts: 0,
  peakBurst: null,
  peakLabel: "",
  detailedBurstList: [],
};

type DIRECTION = "asc" | "desc";

const //DIRECTION_ASC: DIRECTION = "asc",
  DIRECTION_DESC: DIRECTION = "desc",
  METRIC_BURST = "cough_burst",
  DAILY_LIMIT = 24;

const DEVICE_ID = "", //"34ee3147-cd7d-45ab-89fa-d30206f6a47a",
  WEEKLY_LIMIT = 7,
  MONTHLY_LIMIT = 28,
  HISTORY_DAILY_LIMIT = 7,
  HISTORY_MONTHLY_LIMIT = 6,
  RANGE_COUNT = 1;

const getCoughBurstEvent = async (
  deviceId: any,
  aggregation: any,
  startDate: string,
  endDate: string,
  // fields: string = "uid,time,device_id,time_tracked_s,metric,count,min1,max1,avg1,sum1,stddev1,min2,max2,avg2,sum2,stddev2",
  fields: string = "time,time_tracked_s,count",
  direction: DIRECTION = DIRECTION_DESC,
  caller?: string
): Promise<CoughBurstEvent[]> => {
  try {
    const result = await api<CoughBurstEventResponse>({
      url: `/events/v2`,
      method: "GET",
      params: {
        // device_id: deviceId,
        metric: METRIC_BURST,
        aggregation: aggregation,
        start_date: startDate,
        end_date: endDate,
        fields: fields,
        direction: direction,
      },
    });
    if (typeof result?.data !== "string") return result?.data?.events ?? [];
    else {
      const res: string = result.data;
      return JSON.parse(res.replace(/(:\s*)NaN(\s*[,}])/g, "$1null$2")).events;
    }
  } catch (error) {
    console.error("burst.getCoughBurstEvent:", error);
    throw error;
  }
};

const getBurstCoughClock = async (deviceId: any, startDate: string, endDate: string) => {
  try {
    const result = await api<CoughBurstClockResponse>({
      url: `/insights/clock`,
      method: "GET",
      params: {
        // device_id: deviceId,
        metric: METRIC_BURST,
        from: startDate,
        to: endDate,
      },
    });
    return result?.data?.clock || [];
  } catch (error) {
    console.error("burst.getCoughClock:", error);
    return [];
  }
};

const Burst = () => {
  const globalState = useRecoilValue(AppState);
  const currentDate = useRecoilValue(CurrentDateState);

  const [activeTab, setActiveTab] = useState<TODAY_VIEW_MODE | string>(TODAY_VIEW_MODE.VIEW_DAILY);
  const [loading, setLoading] = useState(true);
  const [today, setToday] = useState<Date>(currentDate ?? new Date());

  const [todayCough, setTodayCough] = useState<BurstData>(EmptyBurstData);
  const [weeklyCough, setWeeklyCough] = useState<BurstData>(EmptyBurstData);
  const [monthlyCough, setMonthlyCough] = useState<BurstData>(EmptyBurstData);
  const [monthlyGraphEvents, setMonthlyGraphEvents] = useState<any>([]);

  const [historyWeeklyList, setHistoryWeeklyList] = useState<CoughBurstEvent[]>([]);
  const [historyMonthlyList, setHistoryMonthlyList] = useState<CoughBurstEvent[]>([]);
  const [widgetValue, setWidgetValue] = useState<BurstData>(EmptyBurstData);

  const parseBurstClockGraphData = (graphData: CoughClockEvent[]): BurstData => {
    if (graphData.length === 0) return EmptyBurstData;
    const count = graphData.reduce((sum: number, item: CoughClockEvent) => sum + (item?.count ?? 0), 0);
    const time = graphData.reduce((sum: number, item: CoughClockEvent) => sum + (item?.time_tracked_s ?? 0), 0);
    const timeData = getTime(time);
    // break data by time of day
    let peakBurst: CoughClockEvent | undefined;
    let strPeak: string = "";
    if (graphData?.length > 0) {
      peakBurst = graphData.reduce(
        (prev: CoughClockEvent | undefined, curr: CoughClockEvent | undefined): CoughClockEvent | undefined =>
          (curr?.time_tracked_s ?? 0) > 0 && (curr?.count ?? 0) > (prev?.count ?? 0) ? curr : prev,
        undefined
      );
      const peakBurstHour = peakBurst
        ? peakBurst?.hour === 0
          ? 12
          : peakBurst?.hour > 12
          ? peakBurst?.hour - 12
          : peakBurst?.hour
        : 0;
      const peakBurstHourEnd = peakBurstHour + 1 === 13 ? 1 : peakBurstHour + 1;
      strPeak = peakBurst
        ? `${peakBurstHour}-${peakBurstHourEnd}${peakBurst?.hour >= 12 && peakBurst?.hour <= 23 ? " pm" : " am"}`
        : "";
    }
    const peakLabel = strPeak;
    return {
      totalHour: {
        hours: timeData.hours,
        minutes: timeData.minutes,
        seconds: timeData.seconds,
        s_time: time,
      },
      totalBursts: count,
      peakBurst: peakBurst,
      peakLabel: peakLabel,
      detailedBurstList: graphData,
    } as BurstData;
  };

  const handleLoadDailyData = useCallback(async () => {
    const dailyEvents = await getBurstCoughClock(
      DEVICE_ID,
      apiDateTimeFormat(startOfDay(today)),
      apiDateTimeFormat(endOfDay(today))
    );
    setTodayCough(parseBurstClockGraphData(dailyEvents));
  }, [today]);

  const handleLoadWeeklyData = useCallback(async () => {
    const weeklyEvents = await getBurstCoughClock(
      DEVICE_ID,
      apiDateTimeFormat(startOfWeekMonday(today)),
      apiDateTimeFormat(endOfWeekMonday(today))
    );
    setWeeklyCough({
      ...parseBurstClockGraphData(weeklyEvents),
      totalBursts: weeklyEvents.reduce((sum: number, item: CoughClockEvent) => sum + (item?.count ?? 0), 0),
    });
  }, [today]);

  const handleLoadMonthlyData = useCallback(async () => {
    const monthlyEvents = await getBurstCoughClock(
      DEVICE_ID,
      apiDateTimeFormat(startOfMonth(today)),
      apiDateTimeFormat(endOfMonth(today))
    );
    setMonthlyCough({
      ...parseBurstClockGraphData(monthlyEvents),
      totalBursts: monthlyEvents.reduce((sum: number, item: CoughClockEvent) => sum + (item?.count ?? 0), 0),
    });
    const monthlyCoughEvents = await getCoughBurstEvent(
      DEVICE_ID,
      EVENT_AGGREGATION.DAILY,
      apiDateTimeFormat(startOfMonth(today)),
      apiDateTimeFormat(endOfMonth(today))
    );
    setMonthlyGraphEvents(
      fillArrayWithRange(1, getDaysInMonth(today)).map((day) => {
        const date = addDays(startOfMonth(today), day - 1);
        const item = monthlyCoughEvents.find((item: CoughBurstEvent) => isSameDay(new Date(item.time), date));
        if (!item)
          return {
            label: shortDateFormat(date),
            valueƒ: 0,
          };
        return { label: item.time, value: item.count };
      })
    );
  }, [today]);

  const handleLoadWeeklyHistory = useCallback(async () => {
    const weeklyEvents = await getCoughBurstEvent(
      DEVICE_ID,
      EVENT_AGGREGATION.DAILY,
      apiDateTimeFormat(startOfWeekMonday(today)),
      apiDateTimeFormat(endOfWeekMonday(today))
    );
    setHistoryWeeklyList(weeklyEvents);
  }, [today]);

  const handleLoadMonthlyHistory = useCallback(async () => {
    const monthlyEvents = await getCoughBurstEvent(
      DEVICE_ID,
      EVENT_AGGREGATION.MONTHLY,
      apiDateTimeFormat(startOfMonth(subMonths(today, HISTORY_MONTHLY_LIMIT))),
      apiDateTimeFormat(endOfMonth(subMonths(today, 1)))
    );
    setHistoryMonthlyList(monthlyEvents);
  }, [today]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      switch (activeTab) {
        case TODAY_VIEW_MODE.VIEW_DAILY:
          await handleLoadDailyData();
          break;
        case TODAY_VIEW_MODE.VIEW_WEEKLY:
          await handleLoadWeeklyData();
          await handleLoadWeeklyHistory();
          break;
        case TODAY_VIEW_MODE.VIEW_MONTHLY:
          await handleLoadMonthlyData();
          await handleLoadMonthlyHistory();
          break;
      }
      setLoading(false);
    })();
    return () => {};
  }, [
    today,
    activeTab,
    handleLoadDailyData,
    handleLoadWeeklyHistory,
    handleLoadWeeklyData,
    handleLoadMonthlyData,
    handleLoadMonthlyHistory,
  ]);

  const widgetConfig = useMemo(() => {
    if (activeTab === TODAY_VIEW_MODE.VIEW_DAILY) {
      return {
        rightTitle: "BURSTS TOTAL",
        leftTitle: "PEAK HOUR",
        totalHours: DAILY_LIMIT,
        historyTitle: "Top Bursts",
      };
    }

    if (activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY) {
      return {
        rightTitle: "BURSTS TOTAL",
        leftTitle: "PEAK HOUR",
        totalHours: 24 * WEEKLY_LIMIT,
        historyTitle: "Burst History",
      };
    }
    if (activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY) {
      return {
        rightTitle: "BURSTS TOTAL",
        leftTitle: "PEAK HOUR",
        totalHours: 24 * MONTHLY_LIMIT,
        historyTitle: "Burst History",
      };
    }

    return {
      rightTitle: "cough bursts today",
      totalHours: 24,
      historyTitle: "daily cough burst history",
    };
  }, [activeTab]);

  const weeklyHistoryData = useMemo(() => {
    const data: any = Array(HISTORY_DAILY_LIMIT)
      .fill(0)
      .map((item) => ({
        label: "",
        value: DataType.NOT_TRACK,
      }));
    const startDate = startOfWeekMonday(today);
    for (let i = 0; i < HISTORY_DAILY_LIMIT; i++) {
      data[i].label = format(addDays(startDate, i), "dd LLL y");
      const eventItem = historyWeeklyList.find(
        (item: any) =>
          shortDateFormat(startOfDay(new Date(item.time))) === shortDateFormat(startOfDay(addDays(startDate, i)))
      );
      if (eventItem) {
        if (eventItem.time_tracked_s >= EVENT_LIMIT.DAILY)
          data[i].value = eventItem.count; //=== 0 ? -1 : eventItem.count;
        else data[i].value = DataType.NOT_ENOUGH;
      }
    }

    return data.reverse();
  }, [historyWeeklyList, today]);

  const monthlyHistoryData = useMemo(() => {
    const data: any = Array(HISTORY_MONTHLY_LIMIT)
      .fill(0)
      .map((item) => ({
        label: "",
        value: DataType.NOT_TRACK,
      }));
    for (let i = 0; i < HISTORY_MONTHLY_LIMIT; i++) {
      data[i].label = getCurrentMonthLabel(subMonths(today, i + 1));
      const eventItem = historyMonthlyList.find(
        (item: any) =>
          shortDateFormat(startOfMonth(new Date(item.time))) === shortDateFormat(startOfMonth(subMonths(today, i + 1)))
      );
      if (eventItem) {
        if (eventItem.time_tracked_s >= EVENT_LIMIT.MONTHLY) data[i].value = eventItem.count;
        else data[i].value = DataType.NOT_ENOUGH;
      }
    }
    return data;
  }, [today, historyMonthlyList]);

  const historyData = useMemo(() => {
    if (activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY) {
      return weeklyHistoryData;
    }
    if (activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY) {
      return monthlyHistoryData;
    }
  }, [activeTab, weeklyHistoryData, monthlyHistoryData]);

  const historyGraphData = useMemo(() => {
    if (activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY) return weeklyHistoryData;
    if (activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY) return monthlyGraphEvents;
  }, [activeTab, weeklyHistoryData, monthlyGraphEvents]);

  const onSwipedLeft = () => {
    setToday((prevTime) => {
      let nextTime = prevTime;
      switch (activeTab) {
        case TODAY_VIEW_MODE.VIEW_DAILY:
          nextTime = addDays(prevTime, 1);
          break;
        case TODAY_VIEW_MODE.VIEW_WEEKLY:
          nextTime = addWeeks(prevTime, 1);
          break;
        case TODAY_VIEW_MODE.VIEW_MONTHLY:
          nextTime = addMonths(prevTime, 1);
          break;
      }
      if (nextTime.getTime() > new Date().getTime()) {
        return today;
      }
      return nextTime;
    });
  };
  const onSwipedRight = () => {
    setToday((prevTime) => {
      switch (activeTab) {
        case TODAY_VIEW_MODE.VIEW_DAILY:
          return subDays(prevTime, 1);
        case TODAY_VIEW_MODE.VIEW_WEEKLY:
          return subWeeks(prevTime, 1);
        case TODAY_VIEW_MODE.VIEW_MONTHLY:
          return subMonths(prevTime, 1);
      }
      return prevTime;
    });
  };
  const swipeHandlers = useSwipe({
    onSwipedLeft,
    onSwipedRight,
    minSwipeDistanceX: window.innerWidth / 3,
  });

  useEffect(() => {
    const loadWidgetValue = () => {
      if (activeTab === TODAY_VIEW_MODE.VIEW_DAILY) {
        return todayCough;
      }
      if (activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY) {
        return weeklyCough;
      }
      if (activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY) {
        return monthlyCough;
      }
      return EmptyBurstData;
    };
    setWidgetValue(loadWidgetValue());
    return () => {};
  }, [activeTab, todayCough, weeklyCough, monthlyCough]);
  // Solves the issue of the page not scrolling to the top when navigating to this page
  useEffect(() => {
    window.scrollY >= 0 && window.scrollTo(0, 0);
    window.document.getElementsByClassName("main_container")[0].scrollTo({ behavior: "smooth", top: 0 });
    return () => {};
  }, []);

  // render
  return (
    <ErrorBoundary message="Error.Burst">
      <div className="burst">
        <div className="burst-content">
          <div className="burst-content-container">
            <Tabs
              tabList={[
                {
                  value: TODAY_VIEW_MODE.VIEW_DAILY,
                  label: "Daily",
                },
                {
                  value: TODAY_VIEW_MODE.VIEW_WEEKLY,
                  label: "Weekly",
                },
                {
                  value: TODAY_VIEW_MODE.VIEW_MONTHLY,
                  label: "Monthly",
                },
              ]}
              value={activeTab}
              onChange={setActiveTab}
            />
            <div className="burst-content-section-title">
              <DatePicker
                date={today}
                onSelectDate={(date: Date) => {
                  setToday(date);
                }}
                viewMode={activeTab}
                titleFontSize={16}
              />
              <CoughLabel
                right={{
                  label: widgetConfig.rightTitle,
                  value: `${
                    widgetValue?.totalBursts
                      ? loading
                        ? "-"
                        : globalState.i18n.currentCurrencyFormat.format(widgetValue?.totalBursts)
                      : "-"
                  }`,
                  unit: "",
                }}
                left={{
                  label: widgetConfig.leftTitle || "",
                  value: loading
                    ? "- -"
                    : widgetValue?.peakLabel.split(" ")[0] === ""
                    ? "- -"
                    : widgetValue?.peakLabel.split(" ")[0],
                  unit: loading ? "" : widgetValue?.peakLabel.split(" ")[1] ?? "",
                }}
                options={{
                  addShadow: false,
                  flexSpaceBetween: true,
                }}
              />
            </div>
            <div className="burst-content-section burst-content-section-row">
              <div className="burst-content-section-left-arrow" onClick={onSwipedRight}>
                <img className="swipe-left" alt="<" src={IconArrow} />
              </div>
              <div className="burst-content-section-container" {...swipeHandlers}>
                {widgetValue.totalBursts > 0 || widgetValue.totalHour.s_time > 0 ? (
                  <ClockChart
                    loading={loading}
                    activeTab={activeTab}
                    currentDate={today}
                    clockValues={{
                      peakEvent: widgetValue?.peakBurst,
                      list: widgetValue?.detailedBurstList ?? [],
                    }}
                    eventTooltipLabelSingular="burst"
                    eventTooltipLabelPlural="bursts"
                  />
                ) : (
                  <>
                    {loading && <Loading loading={loading} contained={true} />}
                    <div className="burst-content-section-no-data">
                      <h3>No data</h3>
                      <span>No data for the period before the cough tracking began</span>
                    </div>
                  </>
                )}
              </div>
              <div className="burst-content-section-right-arrow" onClick={onSwipedLeft}>
                <img className="swipe-right" alt=">" src={IconArrow} />
              </div>
            </div>
          </div>
        </div>
        {!loading && widgetValue.totalBursts > 0 && (
          <div className="burst-wrapper">
            <div className="burst-wrapper-reference">
              <ReferenceBar
                title={"BURST QUANTITY"}
                sectionBgColors={colors.generateGradient(
                  colors.PRIMARY_50,
                  colors.PRIMARY_900,
                  widgetValue?.peakBurst?.count ? widgetValue?.peakBurst?.count + 1 : RANGE_COUNT,
                  true
                )}
                minValue={0}
                maxValue={widgetValue?.peakBurst?.count ?? RANGE_COUNT}
                isEmpty={(widgetValue?.peakBurst?.count ?? 0) === 0}
                emptyMessage="0"
              />
            </div>

            <div className="burst-wrapper-history">
              {!loading && widgetValue.totalBursts > 0 ? (
                <>
                  {activeTab === TODAY_VIEW_MODE.VIEW_DAILY && (
                    <>
                      <div className="burst-wrapper-history-label">{widgetConfig.historyTitle}</div>
                      <BurstDetails today={today} burstList={todayCough.detailedBurstList} />
                    </>
                  )}
                  {historyData && historyGraphData && (
                    <>
                      <div className="burst-wrapper-history-graph">
                        <BurstHistoryGraph
                          loading={loading}
                          coughData={historyGraphData.map((item: any) => item.value)}
                          labelData={historyGraphData.map((item: any) => new Date(item.label))}
                          date={today}
                          viewMode={activeTab}
                        />
                      </div>
                      <div className="burst-wrapper-history-label">{widgetConfig.historyTitle}</div>
                      {historyData.map((historyItem: any, index: number) => (
                        <div className="burst-wrapper-history-item" key={`historyItem-${index}`}>
                          <div className="burst-wrapper-history-item-label" id={historyItem.value}>
                            {historyItem.label}
                          </div>
                          {historyItem.value >= DataType.ZERO && (
                            <div className="burst-wrapper-history-item-value">{historyItem.value ?? 0}</div>
                          )}
                          {historyItem.value === DataType.NOT_ENOUGH && (
                            <div className="burst-wrapper-history-item-value-not">not enough data</div>
                          )}
                          {historyItem.value === DataType.NOT_TRACK && (
                            <div className="burst-wrapper-history-item-value-not">did not track</div>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </>
              ) : (
                loading && (
                  <>
                    <CardLoader rows={15} />
                  </>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default Burst;
