import { useEffect } from "react";
import { completeSignInWithEmailLink } from "../../lib/firebase/firebase";
import { useNavigate } from "react-router";
import Loading from "../../components/loading";

const EmailLoginHandler = () => {
  const navigate = useNavigate();
  useEffect(() => {
    completeSignInWithEmailLink().then((user) => {
      if (user === null) {
        console.error("Failed to login !");
      } else {
        const params = new URLSearchParams(window.location.search);
        const redirect = params.get("redirect");
        if (redirect != null) {
          navigate(redirect);
        } else {
          navigate("/");
        }
      }
    });
  }, [navigate]);

  return <Loading loading />;
};

export default EmailLoginHandler;
