import { DEFAULT_LEVELS } from "../assets/const/color";

const getStatus = (data: any) => {
  let comparison: number = (data.direction === "up" ? 1 : -1) * data.angle;

  for (let index = 0; index < DEFAULT_LEVELS.length; index++) {
    if (
      (comparison >= DEFAULT_LEVELS[index].from ||
        DEFAULT_LEVELS[index].from === "-") &&
      (comparison < DEFAULT_LEVELS[index].to ||
        DEFAULT_LEVELS[index].to === "-")
    ) {
      return { comparison: comparison, status: DEFAULT_LEVELS[index] };
    }
  }
};

const cleanToolTip = (divClassName: string) => {
  const foundTooltip = document.getElementById("chartjs-tooltip");
  if (foundTooltip) {
    const container = document.getElementsByClassName(divClassName)[0];
    const removeTooltip = () => {
      foundTooltip.style.opacity = "0";
    };
    if (container) {
      container.removeEventListener("scroll", removeTooltip);
      container.addEventListener("scroll", removeTooltip);
    }
  }
};

export { getStatus, cleanToolTip };
