import { TouchEvent, useState } from "react";

interface SwipeInput {
  onSwipedLeft?: (distanceTraveled: number, e: TouchEvent) => void;
  onSwipedRight?: (distanceTraveled: number, e: TouchEvent) => void;
  onPullDown?: (distanceTraveled: number, e: TouchEvent) => void;
  onPullUp?: (distanceTraveled: number, e: TouchEvent) => void;
  onMoveScreenLeft?: (distanceTraveled: number, e: TouchEvent) => void;
  onMoveScreenRight?: (distanceTraveled: number, e: TouchEvent) => void;
  onMoveScreenUp?: (distanceTraveled: number, e: TouchEvent) => void;
  onMoveScreenDown?: (distanceTraveled: number, e: TouchEvent) => void;
  minSwipeDistanceX?: number;
  minSwipeDistanceY?: number;
}

interface SwipeOutput {
  onTouchStart: (e: TouchEvent) => void;
  onTouchMove: (e: TouchEvent) => void;
  onTouchEnd: (e: TouchEvent) => void;
}

const useSwipe = (input: SwipeInput): SwipeOutput => {
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const [touchStartY, setTouchStartY] = useState(0);
  const [touchEndY, setTouchEndY] = useState(0);

  const minDefaultSwipeDistance = 50;

  const onTouchStart = (e: TouchEvent) => {
    setTouchEndX(0); // otherwise the swipe is fired even with usual touch events
    setTouchEndY(0); // otherwise the swipe is fired even with usual touch events
    setTouchStartX(e.targetTouches[0].clientX);
    setTouchStartY(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e: TouchEvent) => {
    const distanceX = touchStartX - touchEndX;
    const distanceY = touchStartY - touchEndY;
    if (input.onMoveScreenLeft && e.targetTouches[0].clientX > touchStartX) input.onMoveScreenLeft(distanceX, e);
    if (input.onMoveScreenRight && e.targetTouches[0].clientX < touchStartX) input.onMoveScreenRight(distanceX, e);
    if (input.onMoveScreenUp && e.targetTouches[0].clientY > touchStartY) input.onMoveScreenUp(distanceY, e);
    if (input.onMoveScreenDown && e.targetTouches[0].clientY < touchStartY) input.onMoveScreenDown(distanceY, e);
    setTouchEndX(e.targetTouches[0].clientX);
    setTouchEndY(e.targetTouches[0].clientY);
  };

  const onTouchEnd = (e: TouchEvent) => {
    if (!touchStartX || !touchEndX || !touchStartY || !touchEndY) return;
    const distanceX = touchStartX - touchEndX;
    const distanceY = touchStartY - touchEndY;
    const isLeftSwipe = distanceX > (input?.minSwipeDistanceX ?? minDefaultSwipeDistance);
    const isRightSwipe = distanceX < -(input?.minSwipeDistanceX ?? minDefaultSwipeDistance);
    const isPullUp = distanceY > (input?.minSwipeDistanceY ?? minDefaultSwipeDistance);
    const isPullDown = distanceY < -(input?.minSwipeDistanceY ?? minDefaultSwipeDistance);
    if (isLeftSwipe && input.onSwipedLeft) {
      input.onSwipedLeft(distanceX, e);
    }
    if (isRightSwipe && input.onSwipedRight) {
      input.onSwipedRight(distanceX, e);
    }
    if (isPullDown && input.onPullDown) {
      input.onPullDown(distanceY, e);
    }
    if (isPullUp && input.onPullUp) {
      input.onPullUp(distanceY, e);
    }
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};

export default useSwipe;
