import "./style.scss";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IconBack from "../../assets/icons/icon_back.svg";
import LogoutIcon from "../../assets/icons/icon_logout.svg";
import { BurgerIcon, CreditCardIcon } from "../../assets/component_icons";
import CPLogo from "../../assets/img/cp_logo_navbar.svg";
import CPLogoSmall from "../../assets/img/cp_logo_navbar_two_lines.svg";
import { useRecoilValue } from "recoil";
import { AuthState } from "../../state/auth";
import { Drawer } from "antd";
import { startCustomerPortalSession } from "../../lib/stripe";
import Loading from "../loading";

export type NavBarNode = {
  path: string;
  headerText: React.ReactNode | string;
  headerIcon?: React.ReactNode;
  subPaths?: NavBarNode[];
};

interface NavBarProps {
  structure: NavBarNode[];
  children?: React.ReactNode;
  lvl1_disable?: boolean;
  lvl2_disable?: boolean;
}

const NavBar = ({ structure, children, lvl1_disable = false, lvl2_disable = false }: NavBarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useRecoilValue(AuthState);
  const handleClose = () => {
    navigate(-1);
  };

  const [loading, setLoading] = useState(false);

  const handleStartCustomerPortal = async () => {
    if (loading) return;
    try {
      setLoading(true);
      await startCustomerPortalSession();
    } catch {
      window.alert("Could not access customer portal. Please contact support.");
    }
    setLoading(false);
  };

  const [lvl1_node, setLvl1_node] = useState<NavBarNode | null>(null);
  const [lvl2_node, setLvl2_node] = useState<NavBarNode | null>(null);

  useEffect(() => {
    const lvl1 = [...structure]
      // Sort them by decreasing path length so that "/" (if present) is checked as last option
      .sort((a, b) => b.path.length - a.path.length)
      .find((node) => location.pathname.split("/").slice(0, 2).join("/") === node.path || node.path === "/");

    if (lvl1 === undefined) {
      setLvl1_node(null);
      return;
    } else if (!lvl1_disable) {
      setLvl1_node(lvl1);
    }

    const lvl2 = [...(lvl1.subPaths || [])]
      .sort((a, b) => b.path.length - a.path.length)
      .find((node) => location.pathname.split("/").slice(0, 3).join("/") === node.path || node.path === "/");

    if (lvl2 === undefined) {
      setLvl2_node(null);
      return;
    } else if (!lvl2_disable) {
      setLvl2_node(lvl2);
    }
  }, [location, structure, lvl1_disable, lvl2_disable]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <div className="navbar">
      <Drawer
        onClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
        placement="right"
        title={
          <div className="navbar__drawer__title">
            <img
              src={user?.photoURL || ""}
              alt="user icon"
              referrerPolicy="no-referrer"
              className="navbar__level1__user_avatar"
              onClick={() => setIsDrawerOpen(true)}
            />
            <div className="subheading-1">{user?.displayName}</div>
          </div>
        }
      >
        <div className="navbar__drawer__items">
          <Link to={"/logout"} className="navbar__drawer__item">
            <img src={LogoutIcon} alt="logout" />
            <div>Logout</div>
          </Link>
          <div style={{ position: "relative" }}>
            <Loading loading={loading} contained={true} size={30} />
            <div className="navbar__drawer__item" onClick={handleStartCustomerPortal}>
              <CreditCardIcon />
              <div>Manage Subscription</div>
            </div>
          </div>
        </div>
      </Drawer>
      {!lvl1_disable && (
        <div className="navbar__level1__wrapper">
          <div className="navbar__level1">
            <div className="navbar__level1__burger">
              <BurgerIcon onClick={() => setIsDrawerOpen(true)} />
            </div>
            <img className="navbar__level1__logo_large" src={CPLogo} alt="Cough Pro logo" />
            <img className="navbar__level1__logo_small" src={CPLogoSmall} alt="Cough Pro logo small" />
            <div className="navbar__level1__tabs_wrapper">
              {structure.map((node, idx) => (
                <Link
                  to={node.path}
                  key={idx}
                  className={`navbar__level1__tab_item ${
                    node.path === lvl1_node?.path && "navbar__level1__tab_item--selected"
                  }`}
                >
                  {node.headerIcon && <div className="navbar__level1__tab_item__icon">{node.headerIcon}</div>}
                  <div className="navbar__level1__tab_item__text">{node.headerText}</div>
                </Link>
              ))}
            </div>
            <div className="navbar__level1__footer_wrapper">
              <img
                src={user?.photoURL || ""}
                alt="user icon"
                referrerPolicy="no-referrer"
                className="navbar__level1__user_avatar"
                onClick={() => setIsDrawerOpen(true)}
              />
              {children}
            </div>
          </div>
        </div>
      )}
      {!lvl2_disable && lvl2_node && (
        <div className="navbar__level2">
          <div className="navbar__level2-wrapper">
            <div className="navbar__level2-wrapper-action">
              <img className="navbar__level2-wrapper-action-icon" alt="close" src={IconBack} onClick={handleClose} />
            </div>
            {lvl2_node.headerIcon && <div>{lvl2_node.headerIcon}</div>}
            <div className="navbar__level2-wrapper-title">{lvl2_node.headerText}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
