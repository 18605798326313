import React, { forwardRef, useImperativeHandle, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ScriptableContext,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { DataType } from "../../models/event";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartDataLabels
);

export type LineChartProps = {
  valueData: number[];
  labelData: string[];
  skip: number;
  yAxiPosition: string;
  height: string;
  title: string;
  formaterStr?: string;
};

interface LineChartRef {
  toBase64Image: () => void;
}

const LineChart = forwardRef<LineChartRef, LineChartProps>(
  (
    { valueData, labelData, skip, yAxiPosition, height, title, formaterStr },
    ref
  ) => {
    const chartRef = useRef<any>(null);
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: title,
          position: "left",
        },
        datalabels: {
          color: function (context: any) {
            return "rgb(57, 109, 241)";
          },
          display: function (context: any) {
            // var dataset = context.dataset;
            var value = valueData[context.dataIndex];
            if (value === DataType.NOT_ENOUGH) {
              return true;
            }
            return null;
          },
          font: {
            weight: "bold",
          },
          align: "top",
          formatter: () => formaterStr || "",
        },
      } as any,
      scales: {
        x: {
          grid: {
            display: false,
            // drawBorder: false,
          },
        },
        y: {
          border: { dash: [4, 4] },
          grid: {},
          beginAtZero: true,
        },
      },
    };

    const data = {
      labels: labelData,
      datasets: [
        {
          label: "",
          data: valueData.map((item) =>
            Number(item) >= DataType.NOT_ENOUGH
              ? Number(item) === DataType.NOT_ENOUGH
                ? undefined
                : Number(item)
              : null
          ),
          borderColor: "rgb(57, 109, 241)",
          pointBorderColor: "rgb(57, 109, 241)",
          pointBackgroundColor: "rgba(255, 255, 255, 1)",
          pointRadius: 5,
          borderWidth: 2,
          spanGaps: false,
          backgroundColor: (context: ScriptableContext<"line">) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, "rgba(57, 109, 241, 1)");
            gradient.addColorStop(1, "rgba(255,255,255,0)");
            return gradient;
          },
          fill: true,
        },
      ],
    };

    const plugins = [
      {
        beforeDraw: (chartInstance: any, easing: any) => {
          const {
            ctx,
            chartArea: { top, /*bottom, left, right, width,*/ height },
          scales: { x, /* y */ },
          } = chartInstance;
          const unlogedData: number[] = [];
          const unEnoughData: number[] = [];
          valueData.forEach((item, index) => {
            if (item === DataType.NOT_TRACK) {
              unlogedData.push(index);
            }
            if (item === DataType.NOT_ENOUGH) {
              unEnoughData.push(index);
            }
          });
          ctx.fillStyle = "rgba(231, 232, 235, 1)";

          unlogedData.forEach((index) => {
            const leftX = x?._gridLineItems ? x._gridLineItems[index].x1 : 0;
            const leftY = x._gridLineItems
              ? (index + 1 === x._gridLineItems.length
                  ? x.right
                  : x._gridLineItems[index + 1].x1) - x._gridLineItems[index].x1
              : 0;
            ctx.fillRect(leftX, top, leftY, height);
          });

          ctx.fillStyle = "rgb(57, 109, 241)";

          unEnoughData.forEach((index) => {
            const leftX = x?._gridLineItems ? x._gridLineItems[index].x1 : 0;
            // const leftY = x._gridLineItems
            //   ? (index + 1 === x._gridLineItems.length
            //       ? x.right
            //       : x._gridLineItems[index + 1].x1) - x._gridLineItems[index].x1
            //   : 0;
            ctx.fillText(formaterStr, leftX - 20, height);
          });
        },
      },
    ] as any;
    useImperativeHandle(ref, () => ({
      toBase64Image: () => {
        return chartRef.current?.toBase64Image();
      },
    }));

    return (
      <div>
        <Line
          ref={chartRef}
          options={options}
          data={data}
          height={height}
          plugins={plugins}
        />
      </div>
    );
  }
);

export default LineChart;
