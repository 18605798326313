import React, { useEffect, useState } from "react";
import { CoughLabel } from "@hyfe/react-widget-lib";
import { startOfDay, endOfDay } from "date-fns";
import { CoughBurstClockResponse, CoughClockEvent } from "../../models/event";
import { api } from "../../utils/axios";
import { apiDateTimeFormat } from "../../utils/date-operation";
import CardLoader from "../card-loader";
import "./style.scss";
import { useRecoilValue } from "recoil";
import { AppState } from "../../state/global";

type BurstCardProps = {
  startTime: Date;
  rightTitle?: string;
  leftTitle?: string;
  onClickAction?: Function | undefined;
};

const METRIC_BURST = "cough_burst",
  DEVICE_ID = "";

const getCoughClock = async (deviceId: any, startDate: string, endDate: string) => {
  try {
    const result = await api<CoughBurstClockResponse>({
      url: `/insights/clock`,
      method: "GET",
      params: {
        // device_id: deviceId,
        metric: METRIC_BURST,
        from: startDate,
        to: endDate,
      },
    });
    return result?.data?.clock || [];
  } catch (error) {
    console.error("burst.getCoughClock:", error);
    return [];
  }
};

const BurstCard: React.FC<BurstCardProps> = ({
  startTime,
  rightTitle = "BURSTS TODAY",
  leftTitle = "PEAK",
  onClickAction,
}) => {
  const globalState = useRecoilValue(AppState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [coughBurstToday, setCoughBurstToday] = useState<number>(0);
  const [coughBurstTodayPeak, setCoughBurstTodayPeak] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const dailyHourlyEvents = await getCoughClock(
          DEVICE_ID,
          apiDateTimeFormat(startOfDay(startTime)),
          apiDateTimeFormat(endOfDay(startTime))
        );
        if (dailyHourlyEvents?.length > 0) {
          const peakBurst: CoughClockEvent | undefined = dailyHourlyEvents.reduce(
            (prev: CoughClockEvent | undefined, curr: CoughClockEvent | undefined): CoughClockEvent | undefined =>
              (curr?.time_tracked_s ?? 0) > 0 && (curr?.count ?? 0) > (prev?.count ?? 0) ? curr : prev,
            undefined
          );
          const totalBursts = dailyHourlyEvents.reduce(
            (prev: number, curr: CoughClockEvent | undefined): number =>
              (curr?.time_tracked_s ?? 0) > 0 ? prev + (curr?.count ?? 0) : prev,
            0
          );
          const peakBurstHour = peakBurst
            ? peakBurst?.hour === 0
              ? 12
              : peakBurst?.hour > 12
              ? peakBurst?.hour - 12
              : peakBurst?.hour
            : 0;
          const peakBurstHourEnd = peakBurstHour + 1 === 13 ? 1 : peakBurstHour + 1;
          const strPeak = peakBurst
            ? `${peakBurstHour}-${peakBurstHourEnd}${peakBurst?.hour >= 12 && peakBurst?.hour <= 23 ? " pm" : " am"}`
            : "";
          setCoughBurstTodayPeak(strPeak);
          setCoughBurstToday(totalBursts ?? 0);
        }
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };
    fetchData();
    return () => {};
  }, [setLoading, setError, setCoughBurstToday, setCoughBurstTodayPeak, startTime]);

  if (loading) {
    return (
      <div className="burst-card">
        <CardLoader rows={5} />
      </div>
    );
  }
  const OnClick = () => !loading && onClickAction && onClickAction();

  return (
    <div onClick={OnClick}>
      <CoughLabel
        right={{
          label: rightTitle,
          value: `${globalState.i18n.currentCurrencyFormat.format(coughBurstToday || 0)}`,
          unit: "",
        }}
        left={{
          label: leftTitle,
          value: coughBurstTodayPeak.split(" ")[0] || "-",
          unit: coughBurstTodayPeak.split(" ")[1],
        }}
        status={{
          error: !error ? false : true,
          msg: "",
        }}
        options={{
          flexSpaceBetween: coughBurstToday > 0,
        }}
      />
    </div>
  );
};

export default BurstCard;
