/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Device } from '../models/Device';
import type { GetDevice_200 } from '../models/GetDevice_200';
import type { GetDevices_200 } from '../models/GetDevices_200';
import type { PostDeviceBody } from '../models/PostDeviceBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DevicesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get devices
     * Get all devices associated to a user
     * @param customerId Id of the tenant
     * @param uid Id of the user
     * @returns GetDevices_200 Successful response
     * @throws ApiError
     */
    public getUsersDevices(
        customerId: string,
        uid: string,
    ): CancelablePromise<GetDevices_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/users/{uid}/devices',
            path: {
                'customer_id': customerId,
                'uid': uid,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Create a device
     * Create a device associated to a user
     * @param customerId Id of the tenant
     * @param uid Id of the user
     * @param requestBody
     * @returns Device
     * @throws ApiError
     */
    public postUsersDevices(
        customerId: string,
        uid: string,
        requestBody: PostDeviceBody,
    ): CancelablePromise<Device> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/{customer_id}/users/{uid}/devices',
            path: {
                'customer_id': customerId,
                'uid': uid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Get a device
     * Get a device associated to a user
     * @param customerId Id of the tenant
     * @param uid Id of the user
     * @param deviceId Id of the device
     * @returns GetDevice_200 Successful response
     * @throws ApiError
     */
    public getUsersDevices1(
        customerId: string,
        uid: string,
        deviceId: string,
    ): CancelablePromise<GetDevice_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/users/{uid}/devices/{device_id}',
            path: {
                'customer_id': customerId,
                'uid': uid,
                'device_id': deviceId,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Get devices (group)
     * Get all devices associated to a user that belongs to a group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param uid Id of the user
     * @returns GetDevices_200 Successful response
     * @throws ApiError
     */
    public getGroupsUsersDevices(
        customerId: string,
        groupId: string,
        uid: string,
    ): CancelablePromise<GetDevices_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/groups/{group_id}/users/{uid}/devices',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
                'uid': uid,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Create a device (group)
     * Create a device associated to a user that belongs to a group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param uid Id of the user
     * @param requestBody
     * @returns Device
     * @throws ApiError
     */
    public postGroupsUsersDevices(
        customerId: string,
        groupId: string,
        uid: string,
        requestBody: PostDeviceBody,
    ): CancelablePromise<Device> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/{customer_id}/groups/{group_id}/users/{uid}/devices',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
                'uid': uid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Get a device (group)
     * Get a device associated to a user that belongs to a group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param uid Id of the user
     * @param deviceId Id of the device
     * @returns GetDevice_200 Successful response
     * @throws ApiError
     */
    public getGroupsUsersDevices1(
        customerId: string,
        groupId: string,
        uid: string,
        deviceId: string,
    ): CancelablePromise<GetDevice_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/groups/{group_id}/users/{uid}/devices/{device_id}',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
                'uid': uid,
                'device_id': deviceId,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
}
