import { SVGProps } from "react";

type ComponentIconProps = SVGProps<any>;

export const ChatIcon = (props: ComponentIconProps) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66666 5.33342C2.66666 3.86066 3.86056 2.66675 5.33332 2.66675H26.6667C28.1394 2.66675 29.3333 3.86065 29.3333 5.33341V21.3334C29.3333 22.8062 28.1394 24.0001 26.6667 24.0001H14.824L12.4555 28.7371C11.7185 30.2112 9.61485 30.2112 8.8778 28.7371L6.50928 24.0001L7.70185 23.4038L6.50928 24.0001H5.33332C3.86056 24.0001 2.66666 22.8062 2.66666 21.3334V5.33342ZM26.6667 5.33341L5.33332 5.33342V21.3334H6.50928C7.51934 21.3334 8.44271 21.9041 8.89442 22.8075L10.6667 26.352L12.4389 22.8075C12.8906 21.9041 13.814 21.3334 14.824 21.3334H26.6667V5.33341ZM24 10.6667C24 11.4031 23.403 12.0001 22.6667 12.0001H9.33332C8.59694 12.0001 7.99999 11.4031 7.99999 10.6667C7.99999 9.93037 8.59694 9.33341 9.33332 9.33341H22.6667C23.403 9.33341 24 9.93037 24 10.6667ZM24 16.0001C24 16.7365 23.403 17.3334 22.6667 17.3334H9.33332C8.59694 17.3334 7.99999 16.7365 7.99999 16.0001C7.99999 15.2637 8.59694 14.6667 9.33332 14.6667H22.6667C23.403 14.6667 24 15.2637 24 16.0001Z"
      fill="currentColor"
    />
  </svg>
);

export const InsightsIcon = (props: ComponentIconProps) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M27.3331 4H23.3333C22.5969 4 22 4.59695 22 5.33333C22 6.06971 22.5969 6.66667 23.3333 6.66667H24.1142L18.6665 12.1144L15.6093 9.05719C15.0886 8.53649 14.2443 8.53649 13.7236 9.05719L3.05698 19.7239C2.53628 20.2446 2.53628 21.0888 3.05698 21.6095C3.57768 22.1302 4.4219 22.1302 4.9426 21.6095L14.6665 11.8856L17.7236 14.9428C18.2443 15.4635 19.0886 15.4635 19.6093 14.9428L26 8.55208V9.33333C26 10.0697 26.5969 10.6667 27.3333 10.6667C28.0697 10.6667 28.6667 10.0697 28.6667 9.33333V5.33333C28.6667 4.59695 28.0695 4 27.3331 4Z"
      fill="currentColor"
    />
    <path
      d="M9.33333 28C8.59695 28 7.99999 27.403 7.99999 26.6667V24C7.99999 23.2636 8.59695 22.6667 9.33333 22.6667C10.0697 22.6667 10.6667 23.2636 10.6667 24V26.6667C10.6667 27.403 10.0697 28 9.33333 28Z"
      fill="currentColor"
    />
    <path
      d="M13.3333 26.6667C13.3333 27.403 13.9303 28 14.6667 28C15.403 28 16 27.403 16 26.6667V17.3333C16 16.597 15.403 16 14.6667 16C13.9303 16 13.3333 16.597 13.3333 17.3333V26.6667Z"
      fill="currentColor"
    />
    <path
      d="M20 28C19.2636 28 18.6667 27.403 18.6667 26.6667V22.6667C18.6667 21.9303 19.2636 21.3333 20 21.3333C20.7364 21.3333 21.3333 21.9303 21.3333 22.6667V26.6667C21.3333 27.403 20.7364 28 20 28Z"
      fill="currentColor"
    />
    <path
      d="M24 26.6667C24 27.403 24.5969 28 25.3333 28C26.0697 28 26.6667 27.403 26.6667 26.6667V14.6667C26.6667 13.9303 26.0697 13.3333 25.3333 13.3333C24.5969 13.3333 24 13.9303 24 14.6667V26.6667Z"
      fill="currentColor"
    />
  </svg>
);

export const StarIcon = (props: ComponentIconProps) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.92235 2.61368C8.57964 1.79544 7.42033 1.79544 7.07762 2.61368L5.77032 5.73495L2.39784 6.01374C1.51374 6.08683 1.1555 7.1894 1.82778 7.76819L4.39231 9.97603L3.6153 13.2696C3.41161 14.133 4.34952 14.8144 5.10772 14.3539L7.99999 12.5972L10.8923 14.3539C11.6505 14.8144 12.5884 14.133 12.3847 13.2696L11.6077 9.97603L14.1722 7.76819C14.8445 7.1894 14.4862 6.08683 13.6021 6.01374L10.2297 5.73495L8.92235 2.61368Z"
      fill="currentColor"
    />
  </svg>
);

export const BurgerIcon = (props: ComponentIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="26" width="34" viewBox="0 0 448 512" fill="currentColor">
    <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
  </svg>
);

export const CreditCardIcon = (props: ComponentIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
    <path d="M64 32C28.7 32 0 60.7 0 96v32H576V96c0-35.3-28.7-64-64-64H64zM576 224H0V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V224zM112 352h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16z" />
  </svg>
);
