import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { DonutChart } from "../../charts";
import { colors } from "../utils/colors";

const TIME_WHEEL = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const CIRCLE_LABELS = [...TIME_WHEEL, ...TIME_WHEEL];

type DonutChartProps = {
  className?: string;
};
interface DonutChartRef {
  toBase64Image: () => void;
}

const ClockHourLabels = forwardRef<DonutChartRef, DonutChartProps>(
  ({ className = "clock-chart-wrapper-chart", ...props }, ref) => {
    const chartRef = useRef<any>(null);
    useImperativeHandle(ref, () => ({
      toBase64Image: () => {
        return chartRef.current?.toBase64Image();
      },
    }));
    return (
      <DonutChart
        {...props}
        ref={chartRef}
        id="donut-hourly-labels"
        className={className}
        options={{
          rotation: -8,
          responsive: true,
          mantainsAspectRatio: true,
          layout: {
            padding: 25,
          },
          animation: {
            animateScale: false,
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              color: colors.NEUTRAL_400,
            },
          },
        }}
        datasets={[
          {
            data: Array(24).fill(1),
            backgroundColor: colors.TRANSPARENT, // should be variable according to data
            borderWidth: 0,
            borderColor: colors.NEUTRAL_400,
            datalabels: {
              align: "end",
              anchor: "end",
              formatter: function (value: any, context: any) {
                return CIRCLE_LABELS[context.dataIndex];
              },
            },
          },
        ]}
      />
    );
  }
);

export default ClockHourLabels;
