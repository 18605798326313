import "./style.scss";

interface HyfeInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}

const HyfeInput = (props: HyfeInputProps) => {
  return <input {...props} className="hyfe_input" />;
};

export default HyfeInput;
