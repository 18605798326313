import { ReactNode } from "react";

export enum ECoughStatus {
  MUCH_HIGHER = "much_higher",
  SOMEWHAT_HIGHER = "somewhat_higher",
  ABOUT_SAME = "about_same",
  SOMEWHAT_LOWER = "somewhat_lower",
  GETTING_LOWER = "getting_lower",
}

export type CoughTrend = {
  angle: number;
  direction: string;
  pvalue: number;
};

export type TLevel = {
  color: string;
  backgroundColor: string;
  from: number | string;
  to: number | string;
  angle: number;
  message: () => ReactNode;
  value: ECoughStatus;
};
