import React from "react";
import "./style.scss";
import IconMicroPhone from "../../assets/icons/icon_microphone.svg";

type CoughEmptyProps = {
  title: string;
  label: string;
  description: string;
};

const CoughEmpty: React.FC<CoughEmptyProps> = ({
  title,
  label,
  description,
}) => {
  return (
    <div className="cough_empty">
      <div className="cough_empty-title">{title}</div>
      <div className="cough_empty_wrapper">
        <img src={IconMicroPhone} />
        <div className="cough_empty_wrapper_container">
          <div className="cough_empty_wrapper_container-label">{label}</div>
          <p className="cough_empty_wrapper_container-desc">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default CoughEmpty;
