import React from "react";
import { CoughLabel } from "@hyfe/react-widget-lib";
import { CoughEvent, EVENT_LIMIT } from "../../models/event";
import CardLoader from "../card-loader";
import "./style.scss";
import { getTime } from "../../utils/date-operation";

type RateCardProps = {
  coughList: CoughEvent[];
  loading?: boolean;
  onClickAction?: Function | undefined;
  rightTitle?: string;
  rightUnit?: string;
  leftTitle?: string;
  leftUnit?: string;
};

const RateCard: React.FC<RateCardProps> = ({
  coughList,
  loading = true,
  onClickAction,
  rightTitle = "Tracked in last 24hr",
  leftTitle = "COUGH RATE",
  rightUnit = "hr",
  leftUnit = "/hr",
}) => {
  if (loading) {
    return (
      <div className="rate-card">
        <CardLoader rows={5} />
      </div>
    );
  }

  const OnClick = () => !loading && onClickAction && onClickAction();

  const todayCough = {
    totalHour: "00:00",
    coughRate: "0",
  };
  const count = coughList.reduce((sum: number, item: CoughEvent) => sum + item.count, 0);
  const time = coughList.reduce((sum: number, item: CoughEvent) => sum + item.time_tracked_s, 0);
  if (time >= EVENT_LIMIT.DAILY) {
    const totalTime = getTime(time);
    todayCough.totalHour = `${totalTime.hours.toString().padStart(2, "0")}:${totalTime.minutes
      .toString()
      .padStart(2, "0")}`;
    todayCough.coughRate = ((count / time) * 3600).toFixed(1);
  }

  return (
    <div onClick={OnClick}>
      <CoughLabel
        right={{
          label: rightTitle,
          value: todayCough?.totalHour || "00:00",
          unit: rightUnit,
        }}
        left={{
          label: leftTitle,
          value: todayCough?.coughRate || "0",
          unit: leftUnit,
        }}
        status={{
          error: !todayCough,
          msg: "No time tracked, so avg cannot be computed.",
        }}
        options={{
          flexSpaceBetween: false,
        }}
      />
    </div>
  );
};

export default RateCard;
