import "./style.scss";
import React, { useState } from "react";

import { signInWithEmailAndPassword, signInWithProvider, startSignInWithEmailLink } from "../../lib/firebase/firebase";

import Button, { AppleButton, GoogleButton } from "../button";
import Input from "../input";
import IconLogin from "../../assets/icons/icon_login.svg";
import { isDesktopVersion } from "../../utils/misc";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [inProgress, setInProgress] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailSignInError, setEmailSignInError] = useState<{ code: any; message: any } | null>(null);

  const handleEmailSignIn = async () => {
    setInProgress(true);
    try {
      const qs = new URLSearchParams({ redirect: "/" }).toString();
      const emailLinkHandler = `${window.location.origin}/email_login_redirect?${qs}`;
      await startSignInWithEmailLink(email, emailLinkHandler);
      setEmailSignInError(null);
      setEmailSent(true);
    } catch (error) {
      setEmailSent(false);
      setEmailSignInError(error as any);
    }
    setInProgress(false);
  };

  const handleEmailAndPasswordSignIn = async () => {
    signInWithEmailAndPassword(email, password);
  };

  return (
    <div className="login_card">
      <h3 className="login_card__title">Welcome to CoughPro!</h3>
      <div className="login_card__contents">
        <GoogleButton onClick={() => signInWithProvider("google")} disabled={inProgress}>
          Sign in with Google
        </GoogleButton>
        <AppleButton onClick={() => signInWithProvider("apple")} disabled={inProgress}>
          Sign in with Apple
        </AppleButton>
        <span className="subheading-1 login_card__contents__or_sep">OR</span>
        <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
        {!isDesktopVersion() && (
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            type="password"
          />
        )}

        {emailSent && <span className="login_card__success_msg">Email with link sent, check your inbox !</span>}
        {emailSignInError && <span className="login_card__error_msg">Error: {emailSignInError.code}</span>}
        <Button onClick={isDesktopVersion() ? handleEmailSignIn : handleEmailAndPasswordSignIn} disabled={inProgress}>
          <React.Fragment>
            Sign in
            <img src={IconLogin} alt="login" />
          </React.Fragment>
        </Button>
      </div>
    </div>
  );
};

export default Login;
