import "./style.scss";
import { useState } from "react";
import { Modal, Input } from "antd";
import { useRecoilValue } from "recoil";
import { getAnalytics, logEvent } from "firebase/analytics";
import useLocalStorageState from "use-local-storage-state";
import dayjs from "dayjs";

import TrialGift from "../../assets/img/trial_gift.svg";
import CPScreenshot from "../../assets/img/cp_app_screenshot_paywall_screen.png";
import CPScreenshot2 from "../../assets/img/cp_app_screenshot.png";
import AppStoreBtn from "../../assets/img/app_store_button.svg";
import PlayStoreBtn from "../../assets/img/play_store_button.svg";
import CPTrialSteps from "../../assets/img/cp_trial_steps.svg";
import CheckIcon from "../../assets/icons/icon_check.svg";
import { StarIcon } from "../../assets/component_icons";
import CheckMarkCircleIcon from "../../assets/icons/icon_checkmark_circle.svg";

import Button from "../button";
import { startCheckoutSession, startTrialPeriod } from "../../lib/stripe";
import { AuthState } from "../../state/auth";
import Loading from "../loading";
import { useFbPixel } from "../../lib/analytics/fb_pixel";
import axios, { AxiosError } from "axios";

interface PaymentPlanButtonOptions
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  header: string | React.ReactNode;
  price: string | React.ReactNode;
  note?: string | React.ReactNode;
  selected?: boolean;
  decoration?: boolean;
}
const PaymentPlanButton = ({
  header,
  price,
  note,
  selected = false,
  decoration = false,
  ...rest
}: PaymentPlanButtonOptions) => {
  return (
    <button className={`payment_plan_button ${selected && "payment_plan_button--selected"}`} {...rest}>
      <div className="payment_plan_button__text">
        <div className="payment_plan_button__text__title">{header}</div>
        <div className="payment_plan_button__text__price">{price}</div>
        {note && <div className="payment_plan_button__text__note">{note}</div>}
      </div>
      <div className="payment_plan_button__selected">{selected && <img src={CheckIcon} alt="" />}</div>

      {decoration && (
        <div className="payment_plan_button__decoration">
          <StarIcon />
          <div className="">Most Popular</div>
        </div>
      )}
    </button>
  );
};

const RegularSubscriptionPlans: Record<string, PaymentPlanButtonOptions> = {
  "com.coughpro.monthly.regular.eur": {
    header: "pay monthly",
    price: "5.99€ per month",
    note: undefined,
    decoration: false,
  },
  "com.coughpro.yearly.regular.eur": {
    header: "pay yearly",
    price: "34.99€ per year",
    note: "Save 51%",
    decoration: true,
  },
};
const DiscountedSubscriptionPlans: Record<string, PaymentPlanButtonOptions> = {
  "com.coughpro.monthly.discounted.eur": {
    header: "pay monthly",
    price: (
      <div>
        <span style={{ textDecoration: "line-through" }}>5.99€</span>
        <span style={{ color: "var(--warning-warning-400)" }}>&nbsp;3.99€</span> per month
      </div>
    ),
    note: "Save 33%",
    decoration: false,
  },
  "com.coughpro.yearly.discounted.eur": {
    header: "pay yearly",
    price: (
      <div>
        <span style={{ textDecoration: "line-through" }}>34.99€</span>
        <span style={{ color: "var(--warning-warning-400)" }}>&nbsp;24.99€</span> per year
      </div>
    ),
    note: "Save 46.89€",
    decoration: true,
  },
};

const PayWall = () => {
  const {
    user,
    isInTrialPeriod: __isInTrialPeriod,
    isEligibleTrialPeriod,
    trialStartDate,
    refreshPremiumStatus,
    isPremium,
  } = useRecoilValue(AuthState);
  const isInTrialPeriod = __isInTrialPeriod && isPremium;

  const [_, setTrialEndingWarningWavedTs] = useLocalStorageState<string | undefined>("trialEndingWarningWavedTs");

  const fbPixel = useFbPixel();
  const [inProgress, setInProgress] = useState(false);

  const trialDaysRemaining = trialStartDate ? trialStartDate.add(3, "day").diff(dayjs(), "day") : -1;
  const trialHoursRemaining = trialStartDate ? trialStartDate.add(3, "day").diff(dayjs(), "hour") : -1;

  const bulletPoints = [
    {
      title: "New ways to measure cough",
      text: "Discover innovative ways to gain deeper insights",
    },
    {
      title: "Access to historical data",
      text: "Enjoy flexibility in exploring your past cough data",
    },
    {
      title: "Cough management tools",
      text: "Coming soon, based on the latest scientific evidence",
    },
  ];

  const [selectedPlan, setSelectedPlan] = useState<string | undefined>();
  const [offerSelectedPlan, setOfferSelectedPlan] = useState<string | undefined>();

  const [needMoreTimeToThink, setNeedMoreTimeToThink] = useState(false);
  const [trialStartedModalOpen, setTrialStartedModalOpen] = useState(false);
  const [voucherModalOpen, setVoucherModalOpen] = useState(false);
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherRedeemStatus, setVoucherRedeemStatus] = useState<"loading" | "error" | "done" | null>(null);
  const [voucherErrorMessage, setVoucherErrorMessage] = useState<string | null>(null);

  const handleSubscribe = async (planId: string, days: number) => {
    setInProgress(true);
    await startCheckoutSession(planId, days);
    // This should redirect to stripe, so no need to unset inProgress
  };

  const handleStartTrialPeriod = async () => {
    if (isEligibleTrialPeriod) {
      setInProgress(true);
      try {
        await startTrialPeriod();
        await refreshPremiumStatus();
      } catch (error) {
        console.error(error);
        setInProgress(false);
        return;
      }

      fbPixel?.track("StartTrial", { currency: "usd", value: 0 });
      logEvent(getAnalytics(), "rc_trial_start");
      setInProgress(false);
      setTrialStartedModalOpen(true);
    }
  };

  const handleRedeemVoucher = async () => {
    setVoucherErrorMessage(null);
    setVoucherRedeemStatus("loading");

    try {
      const response = await axios.post(process.env.REACT_APP_VOUCHER_REDEEM_URL as string, {
        voucher: voucherCode,
        action: "redeem",
      });

      if (response.data.status === "success") {
        // If the voucher is valid we should refetch the user token
        // to make the  paywall aware of the new subscription status
        refreshPremiumStatus();
        // sleep for 10 seconds, after that we should have the updated subscription status
        await new Promise((resolve) => setTimeout(resolve, 10000));
        throw new Error("Timeout while waiting for subscription status to update");
        // will never run ...
        // setVoucherRedeemStatus("done");
        // setVoucherModalOpen(false);
      }
    } catch (error) {
      const e = error as AxiosError<{ error: { message: string; code: number } }, any>;
      console.error(e);
      if (e.response?.data?.error?.message) {
        setVoucherErrorMessage(e.response.data.error.message);
      } else {
        setVoucherErrorMessage("Unknown error validating voucher");
      }
      setVoucherRedeemStatus("done");
    }
  };

  const handleWaveTrialExpiration = () => {
    setTrialEndingWarningWavedTs(dayjs().toISOString());
  };

  if (user === undefined || isInTrialPeriod === undefined || isEligibleTrialPeriod === undefined) {
    // Wait for state to settle
    return <Loading loading />;
  }

  return (
    <div className="paywall">
      <div className={`paywall__decorations__wrapper ${isInTrialPeriod ? "trial" : "non-trial"}`}>
        {!isInTrialPeriod && (
          <div className="paywall__whats_new">
            <h4 className="paywall__whats_new__title">What's new in CoughPro Premium ?</h4>
            <div className="paywall__whats_new__bullet_points">
              {bulletPoints.map((bp, idx) => (
                <div key={idx} className="paywall__whats_new__bullet_point_item">
                  <img src={CheckMarkCircleIcon} alt="check" />
                  <div>
                    <div className="paywall__whats_new__bullet_point_item__title">{bp.title}</div>
                    <div className="paywall__whats_new__bullet_point_item__text">{bp.text}</div>
                  </div>
                </div>
              ))}
            </div>
            <img className="paywall__whats_new__screenshot" src={CPScreenshot} alt="Cough Pro app screenshot" />
          </div>
        )}
        {isInTrialPeriod && (
          <div className="paywall__trial_ongoing">
            <div className="paywall__trial_ongoing__days_remaining">
              <img src={TrialGift} alt="" />

              <h3 className="paywall__trial_ongoing__days_remaining__days">
                {trialDaysRemaining > 0 ? `${trialDaysRemaining} days left` : `${trialHoursRemaining} hours left`}
              </h3>
              <div className="paywall__trial_ongoing__days_remaining__note">in your free trial</div>
            </div>

            <img className="paywall__whats_new__screenshot" src={CPScreenshot} alt="Cough Pro app screenshot" />
          </div>
        )}
      </div>
      <div className={`paywall__subscribe__wrapper ${isInTrialPeriod ? "trial" : "non-trial"}`}>
        <div className="paywall__subscribe">
          {!isInTrialPeriod && isEligibleTrialPeriod && (
            <>
              <h3 className="paywall__subscribe__title">How your free trial works</h3>
              <div className="paywall__subscribe__trial_steps_wrapper">
                <img src={CPTrialSteps} alt="" />
                <div className="paywall__subscribe__trial_steps_wrapper__text">
                  <div className="paywall__subscribe__trial_step">
                    <div className="paywall__subscribe__trial_step__title">Install the app</div>
                    <div className="paywall__subscribe__trial_step__note">
                      Create your profile or login to your account
                    </div>
                  </div>
                  <div className="paywall__subscribe__trial_step">
                    <div className="paywall__subscribe__trial_step__title">Today: Get instant access</div>
                    <div className="paywall__subscribe__trial_step__note">No payment at this stage</div>
                  </div>
                  <div className="paywall__subscribe__trial_step">
                    <div className="paywall__subscribe__trial_step__title">Day 2: Trial reminder</div>
                    <div className="paywall__subscribe__trial_step__note">We will send you a push notification</div>
                  </div>
                  <div className="paywall__subscribe__trial_step">
                    <div className="paywall__subscribe__trial_step__title">Day 3: Trial ends</div>
                    <div className="paywall__subscribe__trial_step__note">Start your subscription</div>
                  </div>
                </div>
              </div>
              <div className="paywall__subscribe__options_title">Select a plan to proceed:</div>

              <div
                className="paywall__subscribe__options_wrapper"
                style={{
                  flexDirection: "row",
                  marginTop: "1rem",
                }}
              >
                {Object.entries(DiscountedSubscriptionPlans).map(([plan_id, plan], idx) => (
                  <PaymentPlanButton
                    key={plan_id + idx}
                    {...plan}
                    selected={selectedPlan === plan_id}
                    onClick={() => !inProgress && setSelectedPlan(plan_id)}
                  />
                ))}
              </div>
            </>
          )}
          {!isInTrialPeriod && !isEligibleTrialPeriod && (
            <>
              <h3 className="paywall__subscribe__title">Choose your subscription plan</h3>
              <div className="paywall__subscribe__subtitle">
                Your trial has ended. Choose a payment plan that fits you and access valuable cough insights tailored
                just for you.
              </div>
            </>
          )}
          {isInTrialPeriod && (
            <>
              <h3 className="paywall__subscribe__title">Lock in your discount!</h3>
              <div className="paywall__subscribe__subtitle">
                Select a payment option before your trial ends for a discount on premium access. Don't miss out!
              </div>
            </>
          )}
          {!isEligibleTrialPeriod && (
            <div className="paywall__subscribe__options_wrapper">
              {Object.entries(isInTrialPeriod ? DiscountedSubscriptionPlans : RegularSubscriptionPlans).map(
                ([plan_id, plan], idx) => (
                  <PaymentPlanButton
                    key={plan_id + idx}
                    {...plan}
                    selected={selectedPlan === plan_id}
                    onClick={() => !inProgress && setSelectedPlan(plan_id)}
                  />
                )
              )}
            </div>
          )}
          {/* CTA */}
          <div className="paywall__subscribe__cta">
            {isInTrialPeriod && (
              <Button
                onClick={handleWaveTrialExpiration}
                outlined
                secondaryColor="transparent"
                mainColor="var(--neutral-neutral-0)"
                highlightColor="var(--primary-primary-700)"
              >
                Remind me later
              </Button>
            )}
            {!isEligibleTrialPeriod && (
              <Button
                onClick={() => handleSubscribe(selectedPlan as string, 0)}
                loading={inProgress}
                disabled={selectedPlan === undefined}
              >
                Subscribe now
              </Button>
            )}
            {isEligibleTrialPeriod && (
              <Button onClick={handleStartTrialPeriod} loading={inProgress} style={{ minWidth: "260px" }}>
                Start my free 3-day trial
              </Button>
            )}
          </div>
          <div className="paywall__subscribe__voucher" role="button" onClick={() => setVoucherModalOpen(true)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.5752 5.49388C3.5752 6.19919 3.79944 6.86519 4.19027 7.41188C3.03014 7.82643 2.19995 8.93513 2.19995 10.2377V12.0338C2.19995 13.4029 3.24135 14.5287 4.5752 14.6624V14.6757H4.6627V19.2887C4.6627 20.9456 6.00584 22.2887 7.66269 22.2887H16.7137C18.3705 22.2887 19.7137 20.9456 19.7137 19.2887V14.6757H19.8012V14.6624C21.1351 14.5287 22.1764 13.4028 22.1764 12.0338V10.2377C22.1764 8.93516 21.3463 7.82648 20.1862 7.4119C20.577 6.86521 20.8013 6.1992 20.8013 5.49388C20.8013 3.51197 18.9754 1.81634 16.8838 2.47267C16.0447 2.73597 15.0967 3.103 14.3525 3.59916C13.5577 4.12899 12.7856 5.11329 12.2635 5.84929C12.2513 5.86647 12.2392 5.88358 12.2272 5.90064C12.1838 5.84218 12.139 5.78265 12.0931 5.72233C11.567 5.03095 10.8007 4.11698 10.024 3.59916C9.27976 3.103 8.33176 2.73597 7.49265 2.47267C5.40108 1.81634 3.5752 3.51197 3.5752 5.49388ZM6.89385 4.38092C6.28775 4.19073 5.5752 4.64842 5.5752 5.49388C5.5752 6.05257 5.9327 6.54857 6.46271 6.72525L8.00017 7.23773H10.728C10.6568 7.13999 10.581 7.03792 10.5015 6.93345C9.98051 6.24875 9.38732 5.5784 8.91461 5.26326C8.40635 4.92442 7.67154 4.62495 6.89385 4.38092ZM13.7334 7.23773H16.3763L17.9138 6.72525C18.4438 6.54857 18.8013 6.05257 18.8013 5.49388C18.8013 4.64842 18.0887 4.19073 17.4826 4.38092C16.7049 4.62495 15.9701 4.92442 15.4619 5.26326C15.0072 5.5664 14.4198 6.2664 13.8948 7.00648C13.8391 7.08497 13.7852 7.1623 13.7334 7.23773ZM13.3999 12.6757H19.5262L19.5346 12.6756C19.8891 12.6756 20.1764 12.3883 20.1764 12.0338V10.2377C20.1764 9.68545 19.7287 9.23773 19.1764 9.23773H13.3999V12.6757ZM13.3999 14.6757V20.2887H16.7137C17.266 20.2887 17.7137 19.841 17.7137 19.2887V14.6757H13.3999ZM11.3999 20.2887V14.6757H6.6627V19.2887C6.6627 19.841 7.11041 20.2887 7.66269 20.2887H11.3999ZM11.3999 12.6757V9.23773H5.19995C4.64767 9.23773 4.19995 9.68545 4.19995 10.2377V12.0338C4.19995 12.3883 4.48731 12.6756 4.84178 12.6756L4.85021 12.6757H11.3999Z"
                fill="#2E3542"
              />
            </svg>
            <span>I have a voucher code</span>
          </div>
          {/* SUPPORT */}
          <div className="paywall__subscribe__support">
            <div className="">If you have any questions, contact us at:</div>
            <a className="paywall__subscribe__support__email" href="mailto:support@coughpro.com">
              support@coughpro.com
            </a>
          </div>
        </div>
      </div>

      <Modal
        open={trialStartedModalOpen}
        onCancel={() => setTrialStartedModalOpen(false)}
        onOk={() => setTrialStartedModalOpen(false)}
        footer={null}
        width={"100vw"}
        wrapClassName={"paywall__trial_started_modal"}
      >
        <div className="paywall__trial_started_modal__container">
          <div className="paywall__trial_started_modal__main">
            <div className="paywall__trial_started_modal__main__left">
              <h3 className="paywall__trial_started_modal__title">
                Take your trial experience.
                <br />
                Go mobile!
              </h3>
              <div className="paywall__trial_started_modal__text">
                Head to the CoughPro mobile application and start tracking your cough to discover valuable insights
                about your respiratory health.
              </div>
              <div className="paywall__trial_started_modal__store_buttons">
                <a href="https://apps.apple.com/in/app/coughpro/id1505735530" target="_blank" rel="noopener noreferrer">
                  <img src={AppStoreBtn} alt="Play Store link" role="button" className="store_button" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.hyfeapp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={PlayStoreBtn} alt="Play Store link" role="button" className="store_button" />
                </a>
              </div>
            </div>
            <div className="paywall__trial_started_modal__main__right">
              <img src={CPScreenshot2} alt="" />
            </div>
          </div>
          <div>
            <Button onClick={() => setTrialStartedModalOpen(false)}>Got it!</Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={needMoreTimeToThink}
        onCancel={() => setNeedMoreTimeToThink(false)}
        onOk={() => setNeedMoreTimeToThink(false)}
        footer={null}
        width={"100vw"}
        wrapClassName={"paywall__subscribe__offer__modal"}
      >
        <div className="paywall__subscribe__offer">
          <h3 className="paywall__subscribe__title">Subscribe now and get 1 month free!</h3>
          <div className="paywall__subscribe__subtitle">
            Undecided about your subscription?
            <br />
            Select a plan now and get a free month as a special offer!
          </div>
          <div className="paywall__subscribe__options_wrapper">
            {Object.entries(RegularSubscriptionPlans).map(([plan_id, plan], idx) => (
              <PaymentPlanButton
                key={plan_id + idx}
                {...plan}
                selected={offerSelectedPlan === plan_id}
                onClick={() => !inProgress && setOfferSelectedPlan(plan_id)}
              />
            ))}
          </div>
          <div>
            <Button
              onClick={() => handleSubscribe(offerSelectedPlan as string, 30)}
              loading={inProgress}
              disabled={offerSelectedPlan === undefined}
            >
              Subscribe now
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={voucherModalOpen}
        onCancel={() => setVoucherModalOpen(false)}
        onOk={() => setVoucherModalOpen(false)}
        footer={null}
        width={"100vw"}
        wrapClassName={"paywall__redeem_voucher_modal__wrapper"}
      >
        <div className="paywall__redeem_voucher_modal">
          <h3 className="paywall__redeem_voucher_modal__title">Enter your Voucher code</h3>
          <Input
            size="large"
            value={voucherCode}
            onChange={(e) => setVoucherCode(e.target.value)}
            placeholder="Enter your code here"
            style={{ width: "80%", margin: "1rem auto" }}
            status={voucherErrorMessage ? "error" : undefined}
            allowClear
            showCount
            count={{ max: 8 }}
          />
          {voucherErrorMessage && <span style={{ color: "red" }}>{voucherErrorMessage}</span>}
          <div>
            <Button
              onClick={handleRedeemVoucher}
              loading={voucherRedeemStatus === "loading"}
              disabled={voucherCode.length !== 8 || !["done", null].includes(voucherRedeemStatus)}
            >
              Redeem
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PayWall;
