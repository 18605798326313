import React, { useContext, useEffect } from "react";
import "./style.scss";

import { useNavigate } from "react-router-dom";

import CPLogoWText from "../../assets/img/cp_logo_with_text_horizontal.svg";
import CPScreenshot from "../../assets/img/cp_app_screenshot_login_screen.png";
import LoginWidget from "../../components/login";
import { useRecoilValue } from "recoil";
import { AuthState } from "../../state/auth";
import Loading from "../../components/loading";

interface LoginProps {
  automaticRedirect?: boolean;
}

export default function Login({ automaticRedirect = true }: LoginProps) {
  const { user } = useRecoilValue(AuthState);

  const navigate = useNavigate();

  useEffect(() => {
    if (automaticRedirect && user) {
      // console.debug({ user }, "navigating to /");
      navigate("/");
    }
  }, [user, navigate, automaticRedirect]);

  return (
    <div className="login_container">
      <Loading loading={user === undefined} />
      <div className="login">
        <div className="login__logo">
          <img src={CPLogoWText} alt="Cough Pro logo" />
        </div>
        <div className="login__main">
          <div className="login__main__screenshot">
            <img src={CPScreenshot} alt="Cough Pro app screenshot" />
          </div>

          <div className="login__login_widget">
            <LoginWidget />
          </div>
        </div>
        <div className="login__footer">
          <div className="login__footer__testimonial">
            <svg width="54" height="32" viewBox="0 0 54 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.815 32H0L13.3216 0H27L20.815 32ZM47.815 32H27.1189L40.4405 0H54L47.815 32Z" fill="#2B52B5" />
            </svg>
            <p>
              “This app is easy to use, aesthetically pleasing and has provided me with useful information about my
              cough and disease patterns that have improved my overall health!”
            </p>
          </div>
          <div className="login__footer__testimonial">
            <svg width="54" height="32" viewBox="0 0 54 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.815 32H0L13.3216 0H27L20.815 32ZM47.815 32H27.1189L40.4405 0H54L47.815 32Z" fill="#2B52B5" />
            </svg>
            <p>
              “For years doctors have dismissed my complaints of chronic cough. Now I have data to back them up. Awesome
              app!”
            </p>
          </div>
          <div className="login__footer__testimonial">
            <svg width="54" height="32" viewBox="0 0 54 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.815 32H0L13.3216 0H27L20.815 32ZM47.815 32H27.1189L40.4405 0H54L47.815 32Z" fill="#2B52B5" />
            </svg>
            <p>“It's right on, it has helped me to see how many times I'm coughing, it really helps.”</p>
          </div>
          <div className="login__footer__testimonial">
            <svg width="54" height="32" viewBox="0 0 54 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.815 32H0L13.3216 0H27L20.815 32ZM47.815 32H27.1189L40.4405 0H54L47.815 32Z" fill="#2B52B5" />
            </svg>
            <p>
              “This will help me see how effective my treatment is. I suspect not very but I have to convince my doctor
              of this!”
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
