// import { Button } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import IconDownload from "../../assets/icons/icon_download.svg";
import { StepBarChart } from "../../components/charts";
import { jsPDF } from "jspdf";
import "./style.scss";
import { endOfDay, format, startOfDay, startOfMonth, subDays, subMonths, subWeeks } from "date-fns";
import { api } from "../../utils/axios";
import {
  CoughClock,
  CoughClockResponse,
  CoughEventResponse,
  DataType,
  EVENT_AGGREGATION,
  EVENT_LIMIT,
  TODAY_VIEW_MODE,
} from "../../models/event";
import { endOfMonth, endOfWeek, startOfWeek } from "date-fns";
import "../../assets/fonts/Poppins-Bold-normal";
import "../../assets/fonts/Poppins-Regular-normal";
import "../../assets/fonts/Poppins-Light-normal";
import Loading from "../../components/loading";
import {
  apiDateTimeFormat,
  endOfWeekMonday,
  getLabelForDate,
  shortDateFormat,
  startOfWeekMonday,
} from "../../utils/date-operation";
import ClockChart from "../../components/clock-chart";
import ReferenceBar from "../../components/reference-bar";
import { colors } from "../../components/clock-chart/utils/colors";
import Tabs from "../../components/tabs";
import DatePicker from "../../components/date-picker";
import { CoughLabel } from "@hyfe/react-widget-lib";
import ClockIcon from "../../assets/img/clock.png";
import InsightsIcon from "../../assets/img/insights.png";

const RANGE_COUNT = 1;
const HEIGHT = "40vh";

// const DEVICE_ID = "",
const METRIC = "cough";

const PAGE_TITLE = "Your cough clock";
const PAGE_DESC = "The more consistently you track your cough, the more complete the data shown will be.";

const PAGE_CHART_DESC = "The cough clock shows the variation in your cough by hour of the day.";
const DAILY_CHART_TITLE = "Daily cough rate comparison";
const WEEKLY_CHART_TITLE = "Weekly cough rate comparison";
const MONTHLY_CHART_TITLE = "Monthly cough rate comparison";

const DAILY_STEP_DESC = "Your hourly cough rate by day over the past 7 days";
const WEEKLY_STEP_DESC = "Your hourly cough rate by week over the past 6 weeks";
const MONTHLY_STEP_DESC = "Your hourly cough rate by month over the past 6 months";

const PAGE_COUGH_CLOCK_TITLE = "Cough clock";
const PAGE_COUGH_CLOCK_DESC =
  "The cough clock shows your relative cough rate for each hour of the day. The darker the color, the higher the rate for that hour. The lighter the color, the lower the rate for that hour. Hours of the day with no time tracked will have a red line along the outside border.";

const PAGE_COUGH_RATE_TITLE = "Cough rate";

const CHART_DESC = ` = cough tracking was activated for less than `;

const NEUTRAL_50 = "#F7F7F9";
const NEUTRAL_100 = "#E7E8EB";
const NEUTRAL_200 = "#D5D7DD";
const NEUTRAL_500 = "#8A909D";
const NEUTRAL_700 = "#566072";
const NEUTRAL_800 = "#47505F";
const NEUTRAL_900 = "#2E3542";
const PRIMARY_900 = "#1D3779"; // primary-900

const eventToolTip = "coughs/h";

interface ReportComponentProps {
  creationTime?: Date;
}

const EmptyClockData = {
  value: [],
  label: [],
  color: [],
  peakEvent: { count: 0, hour: 0, time_tracked_s: 0 } as CoughClock,
};

export default function Report({ creationTime }: ReportComponentProps) {
  const [startTime, setStartTime] = useState(new Date());
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadingPDF, setDownloadingPDF] = useState(false);

  const [dailyData, setDailyData] = useState<any>(null);
  const [weeklyData, setWeeklyData] = useState<any>(null);
  const [monthlyData, setMonthlyData] = useState<any>(null);

  const [clockDataDaily, setClockDataDaily] = useState<any>(EmptyClockData);
  const [clockDataWeekly, setClockDataWeekly] = useState<any>(EmptyClockData);
  const [clockDataMonthly, setClockDataMonthly] = useState<any>(EmptyClockData);

  const [activeTab, setActiveTab] = useState<TODAY_VIEW_MODE | string>(TODAY_VIEW_MODE.VIEW_DAILY);
  const [referenceData, setReferenceData] = useState<any>(EmptyClockData);
  const [peakLabelDaily, setPeakLabelDaily] = useState<string>("");
  const [peakLabelWeekly, setPeakLabelWeekly] = useState<string>("");
  const [peakLabelMonthly, setPeakLabelMonthly] = useState<string>("");
  const [peakLabel, setPeakLabel] = useState<string>("");
  const [peakValue, setPeakValue] = useState<string>("");

  // Refs
  const clockDailyChartRef = useRef<any>(null);
  const clockWeeklyChartRef = useRef<any>(null);
  const clockMonthlyChartRef = useRef<any>(null);
  const hourlyChartRef = useRef<any>(null);
  const weeklyChartRef = useRef<any>(null);
  const monthlyChartRef = useRef<any>(null);

  useEffect(() => {
    handleLoadData(startTime);
    return () => {};
  }, [startTime]); //eslint-disable-line

  const downloadPDF = async () => {
    let page = 1;
    var doc = new jsPDF("p", "mm", "a4");

    let pageHeight = 297,
      pageWidth = 210;

    const xPadding = 20;
    const yPadding = 24;
    const xSize = (pageWidth - xPadding * 3) / 2;
    const ySize = xSize;

    let x = xPadding;
    let y = yPadding * 2.4;

    // Header
    doc.setFillColor(NEUTRAL_50);
    doc.rect(0, 0, pageWidth, 42, "F");
    await doc.addImage(ClockIcon, "png", 18, 8, 10, 10, undefined, "SLOW");
    doc.setFont("Poppins-Bold");
    doc.setFontSize(20);
    doc.setTextColor(PRIMARY_900);
    doc.text(PAGE_COUGH_CLOCK_TITLE, 30, 16);

    doc.setFont("Poppins-Light");
    doc.setFontSize(10);
    doc.setTextColor(NEUTRAL_900);
    doc.text(PAGE_COUGH_CLOCK_DESC, 30, 24, { maxWidth: pageWidth * 0.77 });

    doc.setDrawColor(NEUTRAL_100);

    let clockLabels = [
      {
        preTitle: `Daily:`,
        title: `${getLabelForDate(startTime, TODAY_VIEW_MODE.VIEW_DAILY)}`,
        titlePadding: 18,
        footer: peakLabelDaily,
        desc: "PEAK HOUR",
      },
      {
        preTitle: `Weekly:`,
        titlePadding: 25,
        title: `${getLabelForDate(startTime, TODAY_VIEW_MODE.VIEW_WEEKLY)}`,
        desc: "PEAK HOUR",
        footer: peakLabelWeekly,
      },
      {
        preTitle: `Monthly:`,
        titlePadding: 28,
        title: `${getLabelForDate(startTime, TODAY_VIEW_MODE.VIEW_MONTHLY)}`,
        desc: "PEAK HOUR",
        footer: peakLabelMonthly,
      },
    ];

    let rateLabels = [
      {
        title: DAILY_CHART_TITLE,
        desc: DAILY_STEP_DESC,
      },
      {
        title: WEEKLY_CHART_TITLE,
        desc: WEEKLY_STEP_DESC,
      },
      {
        title: MONTHLY_CHART_TITLE,
        desc: MONTHLY_STEP_DESC,
      },
    ];

    const clockCharts = [clockDailyChartRef, clockWeeklyChartRef, clockMonthlyChartRef];
    const rateCharts = [hourlyChartRef, weeklyChartRef, monthlyChartRef];

    for (let idx = 0; idx < clockCharts.length; idx++) {
      const chart = await clockCharts[idx]?.current?.toBase64Image();
      if (!chart) continue;
      if (idx === 1) x += xSize + xPadding;
      if (idx === 2) {
        x = xPadding * 1.5 + xSize / 2;
        y += ySize + yPadding * 1.5;
      }
      await doc.addImage(chart, "png", x, y + yPadding / 1.3, xSize, ySize, undefined, "SLOW");
      if (clockLabels[idx]) {
        if (clockLabels[idx].preTitle) {
          doc.setFont("Poppins-Bold");
          doc.setFontSize(16);
          doc.setTextColor(NEUTRAL_900);
          doc.text(clockLabels[idx].preTitle || "", x, y, { maxWidth: xSize });
        }
        if (clockLabels[idx].title) {
          doc.setFont("Poppins-Regular");
          doc.setFontSize(16);
          doc.setTextColor(NEUTRAL_900);
          doc.text(clockLabels[idx].title || "", x + clockLabels[idx].titlePadding, y, { maxWidth: xSize });
        }
        if (clockLabels[idx].desc) {
          doc.setFont("Poppins-Bold");
          doc.setFontSize(10);
          doc.setTextColor(NEUTRAL_500);
          doc.text(clockLabels[idx].desc || "", x, y + 8, { maxWidth: xSize });
        }
        if (clockLabels[idx].footer) {
          doc.setFont("Poppins-Bold");
          doc.setFontSize(16);
          doc.setTextColor(NEUTRAL_700);
          doc.text(clockLabels[idx].footer || "", x, y + 16, { maxWidth: xSize });
        }
      }
    }

    doc.addPage();
    doc.setPage(++page);
    x = xPadding;
    y = yPadding * 1.4;
    // Header
    doc.setFillColor(NEUTRAL_50);
    doc.rect(0, 0, pageWidth, 24, "F");
    await doc.addImage(InsightsIcon, "png", 18, 8, 10, 10, undefined, "SLOW");
    doc.setFont("Poppins-Bold");
    doc.setFontSize(20);
    doc.setTextColor(PRIMARY_900);
    doc.text(PAGE_COUGH_RATE_TITLE, 30, 16);

    doc.setDrawColor(NEUTRAL_100);

    for (let idx = 0; idx < rateCharts.length; idx++) {
      const chart = await rateCharts[idx]?.current?.toBase64Image();
      if (!chart) continue;
      if (idx === 1) x += xSize + xPadding;
      if (idx === 2) {
        x = xPadding * 1.5 + xSize / 2;
        y += ySize + yPadding * 1.5;
      }
      await doc.addImage(chart, "png", x, y + yPadding / 2, xSize, ySize, undefined, "SLOW");
      if (rateLabels[idx]) {
        if (rateLabels[idx].title) {
          doc.setFont("Poppins-Bold");
          doc.setFontSize(11);
          doc.setTextColor(NEUTRAL_800);
          doc.text(rateLabels[idx].title || "", x, y, { maxWidth: xSize });
        }
        if (rateLabels[idx].desc) {
          doc.setFont("Poppins-Light");
          doc.setFontSize(9);
          doc.setTextColor(NEUTRAL_800);
          doc.text(rateLabels[idx].desc || "", x, y + 6, { maxWidth: xSize });
        }
      }
    }

    doc.line(20, pageHeight - 15, pageWidth - 20, pageHeight - 15);
    // doc.setFont("Poppins-Light");
    // doc.setFontSize(10);
    // doc.setTextColor(NEUTRAL_800);
    // doc.text(CHART_DESC, 20, pageHeight - 12);

    doc.save(`report_${format(startTime, "MM_yyyy")}.pdf`);
    setDownloadingPDF(false);
  };

  const handleLoadData = async (reportTime: Date) => {
    setLoadingData(true);
    await handleLoadDaily(reportTime);
    await handleLoadWeekly(reportTime);
    await handleLoadMonthly(reportTime);
    setClockDataDaily(
      await getClockData(
        apiDateTimeFormat(startOfDay(reportTime)),
        apiDateTimeFormat(endOfDay(reportTime)),
        TODAY_VIEW_MODE.VIEW_DAILY
      )
    );
    setClockDataWeekly(
      await getClockData(
        apiDateTimeFormat(startOfWeekMonday(reportTime)),
        apiDateTimeFormat(endOfWeekMonday(reportTime)),
        TODAY_VIEW_MODE.VIEW_WEEKLY
      )
    );
    const clockData = await getClockData(
      apiDateTimeFormat(startOfMonth(reportTime)),
      apiDateTimeFormat(endOfMonth(reportTime)),
      TODAY_VIEW_MODE.VIEW_MONTHLY
    );
    setClockDataMonthly(clockData);
    setLoadingData(false);
  };

  const onSelectDate = (date: Date) => {
    setStartTime(date);
  };

  const getClockData = async (from: string, to: string, range: TODAY_VIEW_MODE) => {
    try {
      const result = await api<CoughClockResponse>({
        url: "/insights/clock",
        method: "GET",
        params: {
          // device_id: DEVICE_ID,
          from,
          to,
        },
      });

      const eventList = result?.data?.clock;
      const valueData = new Array(24).fill(0);
      let maxIndex = -1,
        maxValue = 0,
        minValue = Number.MAX_SAFE_INTEGER;
      const totalTrackedTime = eventList.reduce((sum: number, item: CoughClock) => sum + item?.time_tracked_s, 0);
      if (
        (range === TODAY_VIEW_MODE.VIEW_DAILY && totalTrackedTime >= EVENT_LIMIT.DAILY) ||
        (range === TODAY_VIEW_MODE.VIEW_WEEKLY && totalTrackedTime >= EVENT_LIMIT.WEEKLY) ||
        (range === TODAY_VIEW_MODE.VIEW_MONTHLY && totalTrackedTime >= EVENT_LIMIT.MONTHLY)
      ) {
        eventList.forEach((eventItem) => {
          if ((eventItem?.time_tracked_s ?? 0) > 0) {
            const rate = (eventItem.count / eventItem.time_tracked_s) * 3600;
            valueData[eventItem.hour] = parseFloat(rate.toFixed(1));

            if (rate > maxValue) {
              maxValue = rate;
              maxIndex = eventItem.hour;
            }

            if (rate < minValue) {
              minValue = rate;
            }
          }
          eventList[eventItem.hour].count = valueData[eventItem.hour];
        });
        return { value: eventList, label: [], peakEvent: eventList[maxIndex], totalTrackedTime };
      }
    } catch (error) {
      console.error(error);
    }
    return EmptyClockData;
  };

  const handleLoadDaily = async (reportTime: Date) => {
    const start_date = apiDateTimeFormat(startOfDay(subDays(reportTime, 6))),
      end_date = apiDateTimeFormat(endOfDay(reportTime));
    try {
      const result = await api<CoughEventResponse>({
        url: `/events/v2`,
        method: "GET",
        params: {
          // device_id: DEVICE_ID,
          aggregation: EVENT_AGGREGATION.DAILY,
          start_date,
          end_date,
          metric: METRIC,
        },
      });

      const eventList = result?.data?.events;

      const labelData = new Array(7).fill(""),
        valueData = new Array(7).fill(DataType.NOT_TRACK);

      for (let index = 0; index < 7; index++) {
        labelData[index] = format(subDays(reportTime, 6 - index), "EEEEEE");

        const eventItem = eventList.find(
          (item) => shortDateFormat(new Date(item.time)) === shortDateFormat(subDays(reportTime, 6 - index))
        );

        if (eventItem) {
          if (eventItem.time_tracked_s >= EVENT_LIMIT.DAILY) {
            valueData[index] = ((eventItem.count / eventItem.time_tracked_s) * 3600).toFixed(1);
          } else {
            valueData[index] = DataType.NOT_ENOUGH;
          }
        }
      }

      setDailyData({
        value: valueData,
        label: labelData,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoadWeekly = async (reportTime: Date) => {
    try {
      const start_date = apiDateTimeFormat(startOfDay(subWeeks(reportTime, 6))),
        end_date = apiDateTimeFormat(endOfDay(reportTime));
      const result = await api<CoughEventResponse>({
        url: `/events/v2`,
        method: "GET",
        params: {
          // device_id: DEVICE_ID,
          aggregation: EVENT_AGGREGATION.WEEKLY,
          start_date,
          end_date,
          metric: METRIC,
        },
      });

      const eventList = result?.data?.events;

      const labelData = new Array(6).fill(""),
        valueData = new Array(6).fill(DataType.NOT_TRACK);

      for (let index = 0; index < 6; index++) {
        labelData[index] = format(subWeeks(reportTime, 5 - index), "MMM dd");
        const startDayOfWeek = startOfWeek(subWeeks(reportTime, 5 - index));
        const endDayOfWeek = endOfWeek(subWeeks(reportTime, 5 - index));
        const eventItem = eventList.find((item) => {
          if (new Date(item.time) >= startDayOfWeek && new Date(item.time) < endDayOfWeek) {
            return true;
          } else {
            return false;
          }
        });

        if (eventItem) {
          if (eventItem.time_tracked_s >= EVENT_LIMIT.WEEKLY) {
            valueData[index] = ((eventItem.count / eventItem.time_tracked_s) * 3600).toFixed(1);
          } else {
            valueData[index] = DataType.NOT_ENOUGH;
          }
        }
      }

      setWeeklyData({
        value: valueData,
        label: labelData,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoadMonthly = async (reportTime: Date) => {
    try {
      const start_date = apiDateTimeFormat(subMonths(reportTime, 6)),
        end_date = apiDateTimeFormat(reportTime);
      const result = await api<CoughEventResponse>({
        url: `/events/v2`,
        method: "GET",
        params: {
          // device_id: DEVICE_ID,
          aggregation: EVENT_AGGREGATION.MONTHLY,
          start_date,
          end_date,
          metric: METRIC,
        },
      });

      const eventList = result?.data?.events;

      const labelData = new Array(6).fill(""),
        valueData = new Array(6).fill(DataType.NOT_TRACK);

      for (let index = 0; index < 6; index++) {
        labelData[index] = format(subMonths(reportTime, 5 - index), "MMM");
        const startDayOfMonth = startOfMonth(subMonths(reportTime, 5 - index));
        const endDayOfMonth = endOfMonth(subMonths(reportTime, 5 - index));
        const eventItem = eventList.find((item) => {
          if (new Date(item.time) >= startDayOfMonth && new Date(item.time) < endDayOfMonth) {
            return true;
          } else {
            return false;
          }
        });

        if (eventItem) {
          if (eventItem.time_tracked_s >= EVENT_LIMIT.MONTHLY) {
            valueData[index] = ((eventItem.count / eventItem.time_tracked_s) * 3600).toFixed(1);
          } else {
            valueData[index] = DataType.NOT_ENOUGH;
          }
        }
      }

      setMonthlyData({
        value: valueData,
        label: labelData,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const isDailyDataVisible = useMemo(() => {
    return dailyData?.value?.filter((item: number) => item === DataType.NOT_ENOUGH).length > 0;
  }, [dailyData]);

  const isWeeklyDataVisible = useMemo(() => {
    return weeklyData?.value?.filter((item: number) => item === DataType.NOT_ENOUGH).length > 0;
  }, [weeklyData]);

  const isMonthlyDataVisible = useMemo(() => {
    return monthlyData?.value?.filter((item: number) => item === DataType.NOT_ENOUGH).length > 0;
  }, [monthlyData]);

  useEffect(() => {
    let pLabel = "- -",
      pValue = "";
    switch (activeTab) {
      case TODAY_VIEW_MODE.VIEW_DAILY:
        if (clockDataDaily && clockDataDaily?.peakEvent?.time_tracked_s > 0) {
          pLabel =
            clockDataDaily?.peakEvent?.hour > 12
              ? clockDataDaily?.peakEvent?.hour - 12
              : clockDataDaily?.peakEvent?.hour;
          pValue = clockDataDaily?.peakEvent?.hour > 12 ? "pm" : "am";
        }
        setReferenceData(clockDataDaily);
        setPeakLabelDaily(`${pLabel} ${pValue}`);
        break;
      case TODAY_VIEW_MODE.VIEW_WEEKLY:
        if (clockDataWeekly && clockDataWeekly?.peakEvent?.time_tracked_s > 0) {
          pLabel =
            clockDataWeekly?.peakEvent?.hour > 12
              ? clockDataWeekly?.peakEvent?.hour - 12
              : clockDataWeekly?.peakEvent?.hour;
          pValue = clockDataWeekly?.peakEvent?.hour > 12 ? "pm" : "am";
        }
        setReferenceData(clockDataWeekly);
        setPeakLabelWeekly(`${pLabel} ${pValue}`);
        break;
      case TODAY_VIEW_MODE.VIEW_MONTHLY:
        if (clockDataMonthly && clockDataMonthly?.peakEvent?.time_tracked_s > 0) {
          pLabel =
            clockDataMonthly?.peakEvent?.hour > 12
              ? clockDataMonthly?.peakEvent?.hour - 12
              : clockDataMonthly?.peakEvent?.hour;
          pValue = clockDataMonthly?.peakEvent?.hour > 12 ? "pm" : "am";
        }
        setReferenceData(clockDataMonthly);
        setPeakLabelMonthly(`${pLabel} ${pValue}`);
        break;
    }
    setPeakLabel(pLabel);
    setPeakValue(pValue);
  }, [activeTab, clockDataDaily, clockDataWeekly, clockDataMonthly]);

  // render
  return (
    <div className="report">
      <div className="report-content">
        <div className="report-content-header">
          {/* <div className="report-content-header-title">{PAGE_TITLE}</div> */}
          <p className="report-content-header-desc">{PAGE_DESC}</p>
          <button
            className="report-content-header-download"
            onClick={() => {
              setDownloadingPDF(true);
              setLoading(true);
              setTimeout(() => {
                downloadPDF();
                setDownloadingPDF(false);
                setLoading(false);
              }, 2000);
            }}
          >
            <img src={IconDownload} alt="Download PDF" />
            Download PDF
          </button>
        </div>
        <div className="report-content-main">
          <div className="report-content-main-label">{PAGE_TITLE}</div>
          <div className="report-content-main-desc">{PAGE_CHART_DESC}</div>
          <div className="report-content-main-area-chart">
            <Tabs
              tabList={[
                {
                  value: TODAY_VIEW_MODE.VIEW_DAILY,
                  label: "Daily",
                },
                {
                  value: TODAY_VIEW_MODE.VIEW_WEEKLY,
                  label: "Weekly",
                },
                {
                  value: TODAY_VIEW_MODE.VIEW_MONTHLY,
                  label: "Monthly",
                },
              ]}
              value={activeTab}
              onChange={setActiveTab}
            />
            <div className="report-content-main-area-chart-title">
              <DatePicker date={startTime} onSelectDate={onSelectDate} viewMode={activeTab} titleFontSize={16} />
              <CoughLabel
                right={{
                  label: "",
                  value: "",
                  unit: "",
                }}
                left={{
                  label: "PEAK HOUR",
                  value: loadingData ? "- -" : peakLabel,
                  unit: loadingData ? "" : peakValue,
                }}
                options={{
                  addShadow: false,
                  flexSpaceBetween: true,
                }}
              />
            </div>
            <div className="report-content-main-area-chart-main">
              <ClockChart
                visible={activeTab === TODAY_VIEW_MODE.VIEW_DAILY || downloadingPDF}
                ref={clockDailyChartRef}
                refName="-daily"
                loading={loadingData}
                activeTab={activeTab}
                clockValues={{
                  list: clockDataDaily.value,
                  peakEvent: clockDataDaily.peakEvent,
                }}
                currentDate={startTime}
                eventTooltipLabelSingular={eventToolTip}
                eventTooltipLabelPlural={eventToolTip}
                disableDailyCheck={true}
                gradientLowestColor={colors.NEUTRAL_0}
                gradientHighestColor={colors.ERROR_700}
                eventCountDecimalPlaces={1}
              />
              <ClockChart
                visible={activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY || downloadingPDF}
                ref={clockWeeklyChartRef}
                refName="-weekly"
                loading={loadingData}
                activeTab={activeTab}
                clockValues={{
                  list: clockDataWeekly.value,
                  peakEvent: clockDataWeekly.peakEvent,
                }}
                currentDate={startTime}
                eventTooltipLabelSingular={eventToolTip}
                eventTooltipLabelPlural={eventToolTip}
                disableDailyCheck={true}
                gradientLowestColor={colors.NEUTRAL_0}
                gradientHighestColor={colors.ERROR_700}
                eventCountDecimalPlaces={1}
              />
              <ClockChart
                visible={activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY || downloadingPDF}
                ref={clockMonthlyChartRef}
                refName="-monthly"
                loading={loadingData}
                activeTab={activeTab}
                clockValues={{
                  list: clockDataMonthly.value,
                  peakEvent: clockDataMonthly.peakEvent,
                }}
                currentDate={startTime}
                eventTooltipLabelSingular={eventToolTip}
                eventTooltipLabelPlural={eventToolTip}
                disableDailyCheck={true}
                gradientLowestColor={colors.NEUTRAL_0}
                gradientHighestColor={colors.ERROR_700}
                eventCountDecimalPlaces={1}
              />
            </div>
            <div className="report-content-main-area-chart-reference">
              <ReferenceBar
                title={"COUGH RATE"}
                sectionBgColors={colors.generateGradient(
                  colors.NEUTRAL_0,
                  colors.ERROR_700,
                  referenceData?.peakEvent?.count ? referenceData?.peakEvent?.count + 1 : RANGE_COUNT,
                  true
                )}
                minValue={0}
                maxValue={referenceData?.peakEvent?.count ?? RANGE_COUNT}
                isEmpty={(referenceData?.peakEvent?.count ?? 0) === 0}
                emptyMessage="0"
              />
            </div>
          </div>
        </div>
        {dailyData && (
          <div className="report-content-main">
            <div className="report-content-main-label">{DAILY_STEP_DESC}</div>
            <div className="report-content-main-chart">
              <StepBarChart
                ref={hourlyChartRef}
                loading={loadingData}
                valueData={dailyData.value}
                labelData={dailyData.label}
                height={HEIGHT}
                activeColor={NEUTRAL_200}
              />
            </div>
            {isDailyDataVisible && <div className="report-content-main-desc">{`< 15m ${CHART_DESC} 15 minutes`}</div>}
          </div>
        )}
        {weeklyData && (
          <div className="report-content-main">
            <div className="report-content-main-label">{WEEKLY_STEP_DESC}</div>
            <div className="report-content-main-chart">
              <StepBarChart
                ref={weeklyChartRef}
                loading={loadingData}
                valueData={weeklyData.value}
                labelData={weeklyData.label}
                height={HEIGHT}
                activeColor={NEUTRAL_200}
              />
            </div>
            {isWeeklyDataVisible && <div className="report-content-main-desc">{`< 1h ${CHART_DESC} 1 hour`}</div>}
          </div>
        )}
        {monthlyData && (
          <div className="report-content-main">
            <div className="report-content-main-label">{MONTHLY_STEP_DESC}</div>
            <div className="report-content-main-chart">
              <StepBarChart
                ref={monthlyChartRef}
                loading={loadingData}
                valueData={monthlyData.value}
                labelData={monthlyData.label}
                height={HEIGHT}
                activeColor={NEUTRAL_200}
              />
            </div>
            {isMonthlyDataVisible && <div className="report-content-main-desc">{`< 6h ${CHART_DESC} 6 hours`}</div>}
          </div>
        )}
        <div className="report-content-footer">
          <button
            className="report-content-footer-download"
            onClick={() => {
              setDownloadingPDF(true);
              setLoading(true);
              setTimeout(() => {
                downloadPDF();
                setDownloadingPDF(false);
                setLoading(false);
              }, 2000);
            }}
          >
            <img src={IconDownload} alt="Download PDF" />
            Download PDF
          </button>
        </div>
      </div>
      <Loading loading={loading} />
    </div>
  );
}
