import React from "react";
// import {
//   ChartAboutSame,
//   ChartGettingLower,
//   ChartMuchHigher,
//   ChartSomewhatHigher,
//   ChartSomewhatLower,
// } from "../../../assets/charts";
import ChartMuchHigher from "../../../assets/charts/chart_much_higher.svg";
import ChartSomewhatHigher from "../../../assets/charts/chart_somewhat_higher.svg";
import ChartAboutSame from "../../../assets/charts/chart_about_same.svg";
import ChartSomewhatLower from "../../../assets/charts/chart_somewhat_lower.svg";
import ChartGettingLower from "../../../assets/charts/chart_getting_lower.svg";
import { ECoughStatus } from "../../../model";
import "./style.scss";

type CoughChartProps = {
  status: ECoughStatus;
  color?: string;
};

const CoughChart: React.FC<CoughChartProps> = ({ status }) => {
  return (
    <div className={`chart-container-${status}`}>
      {status === ECoughStatus.MUCH_HIGHER && <img src={ChartMuchHigher} />}
      {status === ECoughStatus.SOMEWHAT_HIGHER && (
        <img src={ChartSomewhatHigher} />
      )}
      {status === ECoughStatus.ABOUT_SAME && <img src={ChartAboutSame} />}
      {status === ECoughStatus.SOMEWHAT_LOWER && (
        <img src={ChartSomewhatLower} />
      )}
      {status === ECoughStatus.GETTING_LOWER && <img src={ChartGettingLower} />}
    </div>
  );
};

export default CoughChart;
