import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import { api } from "../../utils/axios";
import {
  subDays,
  format,
  subHours,
  differenceInHours,
  differenceInDays,
  addDays,
  addHours,
  subMonths,
  differenceInMonths,
  addMonths,
  isToday,
  subWeeks,
  startOfMonth,
  subYears,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfYear,
  getUnixTime,
  endOfWeek,
} from "date-fns";
import CoughToday from "../../components/cough-today";
import {
  CoughEvent,
  CoughEventResponse,
  CoughTrend,
  EVENT_AGGREGATION,
  TODAY_VIEW_MODE,
  TREND_GRANULARITY,
} from "../../models/event";
import Tabs from "../../components/tabs";
import { subMinutes } from "date-fns";
import IconLogin from "../../assets/icons/icon_login.svg";
import IconLock from "../../assets/icons/icon_lock.svg";
import GuestView from "../../components/guest-view";
import { apiDateTimeFormat, endOfWeekMonday, getQuarterFormat } from "../../utils/date-operation";
import Spinner from "../../components/spinner";
import BurstCard from "../../components/burst-card";
import RateCard from "../../components/rate-card";
import TrendCard from "../../components/trend-card";
import useSwipe from "../../components/useSwipe";
import usePull from "../../components/usePull";
import { useRecoilValue } from "recoil";
import { AuthState } from "../../state/auth";
import { isDesktopVersion } from "../../utils/misc";
import { useRecoilState } from "recoil";
import { CurrentDateState } from "../../state/currentDate";

/** Total loading steps, for async loading of widgets while keep track of what has been loaded */
let TOTAL_LOADING_STEPS = 2;

const METRIC = "cough",
  HOURLY_LIMIT = 12,
  DAILY_LIMIT = 24,
  WEEKLY_LIMIT = 7,
  MONTHLY_LIMIT = 30,
  YEARLY_LIMIT = 12;

function hidePullDownToRefresh() {
  const pullToRefresh = document.querySelector(".insight-pull-to-refresh");
  if (pullToRefresh) pullToRefresh.classList.remove("visible");
}
function Insight() {
  const navigate = useNavigate();
  const description: string = "Last 24h compared to the last 4 days";

  const [currentDate] = useRecoilState(CurrentDateState);

  const { user, isAnonymous } = useRecoilValue(AuthState);
  const [coughDailyList, setCoughDailyList] = useState<any>([]);
  const [coughWeeklyList, setCoughWeeklyList] = useState<any>([]);
  const [coughMonthlyList, setCoughMonthlyList] = useState<any>([]);
  const [coughYearlyList, setCoughYearlyList] = useState<any>([]);
  const [coughRateData, setCoughRateData] = useState<any>([]);
  const [coughTrend, setCoughTrend] = useState<CoughTrend | undefined>(undefined);
  const [historicalView, setHistoricalView] = useState<boolean>(false);
  const [reportTime, setReportTime] = useState<Date>(new Date() ?? currentDate);
  const [reportBurstTime] = useState<Date>(new Date() ?? currentDate);
  const [activeTab, setActiveTab] = useState<TODAY_VIEW_MODE | string>(TODAY_VIEW_MODE.VIEW_DAILY);
  const [loading, setLoading] = useState(true);
  const [loadingSteps, setLoadingSteps] = useState<number>(0);
  const [coughTodayConfig, setCoughTodayConfig] = useState<any>({ title: "-", labelData: [], date: "-" });
  const [allowRefresh, setAllowRefresh] = useState<boolean>(false);
  const [coughToday, setCoughToday] = useState<{ count: number; list: any[] } | null>({ count: 0, list: [] });

  const EMPTY_COUGH_LIST = (activeTab: TODAY_VIEW_MODE | string) => {
    switch (activeTab) {
      case TODAY_VIEW_MODE.VIEW_HOURLY:
        return new Array(HOURLY_LIMIT).fill(0);
      case TODAY_VIEW_MODE.VIEW_DAILY:
        return new Array(DAILY_LIMIT).fill(0);
      case TODAY_VIEW_MODE.VIEW_WEEKLY:
        return new Array(WEEKLY_LIMIT).fill(0);
      case TODAY_VIEW_MODE.VIEW_MONTHLY:
        return new Array(MONTHLY_LIMIT).fill(0);
      case TODAY_VIEW_MODE.VIEW_YEARLY:
        return new Array(YEARLY_LIMIT).fill(0);
      default:
        return new Array(DAILY_LIMIT).fill(0);
    }
  };

  const handleViewReport = () => {
    !loading && navigate("/report");
  };

  const handleViewRate = () => {
    !loading && navigate("/rate");
  };

  const handleViewTrend = () => {
    !loading && navigate("/trend");
  };

  const handleViewBurst = () => {
    !loading && navigate("/bursts");
  };

  async function handlePullToRefresh() {
    await (async () => {
      TOTAL_LOADING_STEPS = 1;
      setLoading(true);
      setLoadingSteps(0);
      setReportTime(reportTime);
      historicalView && setHistoricalView(false);
      // if (activeTab === TODAY_VIEW_MODE.VIEW_HOURLY) {
      //   await handleLoadHourlyCough();
      // }

      if (activeTab === TODAY_VIEW_MODE.VIEW_DAILY) {
        await handleLoadDailyCough();
        await handleLoadTrend();
      }

      if (activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY) {
        await handleLoadWeeklyCough();
      }

      if (activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY) {
        await handleLoadMonthlyCough();
      }

      if (activeTab === TODAY_VIEW_MODE.VIEW_YEARLY) {
        await handleLoadYearlyCough();
      }
    })();
  }

  const getCoughEvent = async (aggregation: any, startDate: string, endDate: string) => {
    try {
      const result = await api<CoughEventResponse>({
        url: `/events/v2`,
        method: "GET",
        params: {
          // device_id: deviceId,
          aggregation: aggregation,
          start_date: startDate,
          end_date: endDate,
          metric: METRIC,
        },
      });
      return result?.data?.events || [];
    } catch (error) {
      console.error("insight.getCoughEvent:", error);
      return [];
    }
  };

  // const handleLoadHourlyCough = useCallback(async () => {
  //   const hourlyEvents = await getCoughEvent(
  //     EVENT_AGGREGATION.QUARTER,
  //     apiDateTimeFormat(subHours(reportTime, 3)),
  //     apiDateTimeFormat(reportTime)
  //   );
  //   setCoughHourlyList(hourlyEvents);
  //   setLoadingSteps((prevStep) => prevStep + 1);
  // }, [reportTime]);

  const handleLoadDailyCough = useCallback(async () => {
    const dailyEvents = await getCoughEvent(
      EVENT_AGGREGATION.HOURLY,
      apiDateTimeFormat(historicalView ? subDays(reportTime, 1) : subHours(reportTime, DAILY_LIMIT - 1)),
      apiDateTimeFormat(reportTime)
    );
    const currentView = dailyEvents.filter((item) => new Date(item.time) >= subHours(reportTime, DAILY_LIMIT));
    setCoughDailyList(currentView);
    if (isToday(reportTime) && !historicalView) {
      setCoughRateData(currentView);
      setLoadingSteps((prevStep) => prevStep + 2);
    } else setLoadingSteps((prevStep) => prevStep + 1);
  }, [reportTime, historicalView]);

  const handleLoadWeeklyCough = useCallback(async () => {
    const weeklyEvents = await getCoughEvent(
      EVENT_AGGREGATION.DAILY,
      apiDateTimeFormat(historicalView ? subWeeks(reportTime, 1) : subDays(reportTime, WEEKLY_LIMIT - 1)),
      apiDateTimeFormat(reportTime)
    );
    setCoughWeeklyList(weeklyEvents);
    setLoadingSteps((prevStep) => prevStep + 1);
  }, [reportTime, historicalView]);

  const handleLoadMonthlyCough = useCallback(async () => {
    const monthlyEvents = await getCoughEvent(
      EVENT_AGGREGATION.DAILY,
      apiDateTimeFormat(historicalView ? startOfMonth(reportTime) : subDays(reportTime, MONTHLY_LIMIT - 1)),
      apiDateTimeFormat(reportTime)
    );
    setCoughMonthlyList(monthlyEvents);
    setLoadingSteps((prevStep) => prevStep + 1);
  }, [reportTime, historicalView]);

  const handleLoadYearlyCough = useCallback(async () => {
    const yearlyEvents = await getCoughEvent(
      EVENT_AGGREGATION.MONTHLY,
      apiDateTimeFormat(historicalView ? startOfYear(reportTime) : subMonths(reportTime, YEARLY_LIMIT - 1)),
      apiDateTimeFormat(reportTime)
    );
    setCoughYearlyList(yearlyEvents);
    setLoadingSteps((prevStep) => prevStep + 1);
  }, [reportTime, historicalView]);

  const handleLoadCough = () => {
    switch (activeTab) {
      // case TODAY_VIEW_MODE.VIEW_HOURLY:
      //   handleLoadHourlyCough();
      //   break;
      case TODAY_VIEW_MODE.VIEW_DAILY:
        handleLoadDailyCough();
        break;
      case TODAY_VIEW_MODE.VIEW_WEEKLY:
        handleLoadWeeklyCough();
        break;
      case TODAY_VIEW_MODE.VIEW_MONTHLY:
        handleLoadMonthlyCough();
        break;
      case TODAY_VIEW_MODE.VIEW_YEARLY:
        handleLoadYearlyCough();
        break;
    }
  };

  const handleLoadTrend = async () => {
    try {
      const result = await api<CoughTrend>({
        url: `/insights/trend`,
        method: "GET",
        params: {
          date: apiDateTimeFormat(reportTime),
          granularity: TREND_GRANULARITY.DAY,
        },
      });
      if (result?.status === 200) {
        setCoughTrend(result?.data ?? undefined);
      } else if (result?.status === 204) {
        setCoughTrend(undefined);
      }
    } catch (error) {
      console.error("insight.handleLoadTrend:", error);
    }
    setLoadingSteps((prevStep) => prevStep + 1);
  };

  useEffect(() => {
    if (user) {
      handleLoadCough();
      handleLoadTrend();
    }
    return () => {};
  }, [user, reportTime]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getCoughTodayData = () => {
      // if (activeTab === TODAY_VIEW_MODE.VIEW_HOURLY) {
      //   if (coughHourlyList.length === 0) return null;
      //   const list = new Array(HOURLY_LIMIT).fill(0);
      //   const count = coughHourlyList.reduce((sum: number, item: CoughEvent) => {
      //     const hour = Math.floor(
      //       differenceInMinutes(new Date(item.time), subMinutes(reportTime, HOURLY_LIMIT * 15)) / 15
      //     );
      //     list[hour] = list[hour] + item.count;
      //     return sum + item.count;
      //   }, 0);

      //   return {
      //     count,
      //     list,
      //   };
      // }

      if (activeTab === TODAY_VIEW_MODE.VIEW_DAILY) {
        if (coughDailyList.length === 0) return null;
        const list = new Array(DAILY_LIMIT).fill(0);
        const count = coughDailyList.reduce((sum: number, item: CoughEvent) => {
          const day = differenceInHours(new Date(item.time), subHours(reportTime, DAILY_LIMIT));
          list[day] = list[day] + item.count;
          return sum + item.count;
        }, 0);

        return {
          count,
          list,
        };
      }

      if (activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY) {
        if (coughWeeklyList.length === 0) return null;
        const list = new Array(WEEKLY_LIMIT).fill(0);
        const count = coughWeeklyList.reduce((sum: number, item: CoughEvent) => {
          const week = differenceInDays(new Date(item.time), subDays(reportTime, WEEKLY_LIMIT));
          list[week] = list[week] + item.count;
          return sum + item.count;
        }, 0);

        return {
          count,
          list,
        };
      }

      if (activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY) {
        if (coughMonthlyList.length === 0) return null;
        const list = new Array(MONTHLY_LIMIT).fill(0);
        const count = coughMonthlyList.reduce((sum: number, item: CoughEvent) => {
          const month = differenceInDays(new Date(item.time), subDays(reportTime, MONTHLY_LIMIT));
          list[month] = list[month] + item.count;
          return sum + item.count;
        }, 0);

        return {
          count,
          list,
        };
      }

      if (activeTab === TODAY_VIEW_MODE.VIEW_YEARLY) {
        if (coughYearlyList.length === 0) return null;
        const list = new Array(YEARLY_LIMIT).fill(0);
        const count = coughYearlyList.reduce((sum: number, item: CoughEvent) => {
          const month = differenceInMonths(new Date(item.time), subMonths(reportTime, YEARLY_LIMIT));
          list[month] = list[month] + item.count;
          return sum + item.count;
        }, 0);
        const resultado = {
          count,
          list,
        };
        return resultado;
      }

      return {
        count: 0,
        list: new Array(DAILY_LIMIT).fill(0),
      };
    };
    setCoughToday(getCoughTodayData());
  }, [reportTime, activeTab, /*coughHourlyList, */ coughDailyList, coughWeeklyList, coughMonthlyList, coughYearlyList]);

  const lockView =
    isAnonymous &&
    (activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY ||
      activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY ||
      activeTab === TODAY_VIEW_MODE.VIEW_YEARLY);

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => {
      // Swipe >>;
      setReportTime((prevTime) => {
        let nextTime = prevTime;
        switch (activeTab) {
          case TODAY_VIEW_MODE.VIEW_HOURLY:
            nextTime = addHours(prevTime, HOURLY_LIMIT);
            break;
          case TODAY_VIEW_MODE.VIEW_DAILY:
            nextTime = endOfDay(addDays(prevTime, 1));
            break;
          case TODAY_VIEW_MODE.VIEW_WEEKLY:
            nextTime = endOfWeekMonday(addWeeks(prevTime, 1));
            break;
          case TODAY_VIEW_MODE.VIEW_MONTHLY:
            nextTime = endOfMonth(addMonths(prevTime, 1));
            break;
          case TODAY_VIEW_MODE.VIEW_YEARLY:
            nextTime = endOfYear(addYears(prevTime, 1));
            break;
        }
        if (nextTime.getTime() > new Date().getTime()) {
          setHistoricalView(false);
          nextTime = new Date();
        }
        return nextTime;
      });
    },
    onSwipedRight: () => {
      // Swipe <<;
      setReportTime((currentTime) => {
        let prevTime = currentTime;
        switch (activeTab) {
          case TODAY_VIEW_MODE.VIEW_HOURLY:
            prevTime = subHours(currentTime, HOURLY_LIMIT);
            break;
          case TODAY_VIEW_MODE.VIEW_DAILY:
            prevTime = endOfDay(subDays(currentTime, 1));
            break;
          case TODAY_VIEW_MODE.VIEW_WEEKLY:
            prevTime = endOfWeekMonday(subWeeks(currentTime, 1));
            break;
          case TODAY_VIEW_MODE.VIEW_MONTHLY:
            prevTime = endOfMonth(subMonths(currentTime, 1));
            break;
          case TODAY_VIEW_MODE.VIEW_YEARLY:
            prevTime = endOfYear(subYears(currentTime, 1));
            break;
        }
        setHistoricalView(true);
        return prevTime;
      });
    },
    minSwipeDistanceX: window.innerWidth / 3,
  });

  const pullHandler = usePull({
    onPullDown: (distanceY) => {
      if (window.scrollY === 0 && allowRefresh) {
        hidePullDownToRefresh();
        handlePullToRefresh();
        setAllowRefresh(false);
      }
    },
    onMoveScreenDown: (distanceY: number, e: any) => {
      // console.debug(((e as TouchEvent).target as HTMLElement).scrollTop);
    },
    onMoveScreenUp: (distanceY: number, e: any) => {
      // console.debug(((e as TouchEvent).target as HTMLElement).scrollTop);
      // console.debug(Math.abs(distanceY), (window.visualViewport?.height ?? 0) / 2);
      if (window.scrollY === 0 && Math.abs(distanceY) > (window.visualViewport?.height ?? 0) / 2) {
        const pullToRefresh = document.querySelector(".insight-pull-to-refresh");
        if (pullToRefresh && !allowRefresh) {
          setTimeout(() => {
            hidePullDownToRefresh();
            setAllowRefresh(false);
          }, 2000);
        }
      }
    },
    minPullDistance: window.innerHeight / 2,
  });

  const datePicked = (date: Date) => {
    // need to set to the end of the given day for proper date limit
    if (getUnixTime(date) > getUnixTime(new Date())) setReportTime(new Date());
    else {
      switch (activeTab) {
        case TODAY_VIEW_MODE.VIEW_DAILY:
          setReportTime(endOfDay(date));
          break;
        case TODAY_VIEW_MODE.VIEW_WEEKLY:
          setReportTime(endOfWeek(date));
          break;
        case TODAY_VIEW_MODE.VIEW_MONTHLY:
          setReportTime(endOfMonth(date));
          break;
        case TODAY_VIEW_MODE.VIEW_YEARLY:
          setReportTime(endOfYear(date));
          break;
      }
    }
    if (isToday(date)) {
      setReportTime(new Date());
      setHistoricalView(false);
    } else setHistoricalView(true);
  };

  // Labels
  useEffect(() => {
    if (activeTab === TODAY_VIEW_MODE.VIEW_HOURLY) {
      let list = [];
      for (let index = 0; index < HOURLY_LIMIT; index++) {
        list.push(getQuarterFormat(subMinutes(reportTime, index * 15)));
      }
      const labels = list.reverse();
      setCoughTodayConfig({
        title: `${HOURLY_LIMIT / 4} hr Total`,
        labelData: labels,
        date: "Last 3 hours",
      });
    }

    if (activeTab === TODAY_VIEW_MODE.VIEW_DAILY) {
      let list = [];
      for (let index = 0; index < DAILY_LIMIT; index++) {
        list.push(`${format(subHours(reportTime, index), "H:00")}`);
      }
      const labels = list.reverse();
      setCoughTodayConfig({
        title: "Daily total",
        labelData: labels,
        date: format(reportTime, "EEEE, dd LLL y"),
      });
    }

    if (activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY) {
      let list = [];
      for (let index = 0; index < WEEKLY_LIMIT; index++) {
        list.push(`${format(subDays(reportTime, index), "EEE")}`);
      }
      const labels = list.reverse();
      setCoughTodayConfig({
        title: "Weekly Total",
        labelData: labels,
        date: `${format(subDays(reportTime, WEEKLY_LIMIT - 1), "dd LLL")} - ${format(reportTime, "dd LLL y")}`,
      });
    }

    if (activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY) {
      let list = [];
      for (let index = 0; index < MONTHLY_LIMIT; index++) {
        list.push(`${format(subDays(reportTime, index), "MMM dd")}`);
      }
      const labels = list.reverse();
      setCoughTodayConfig({
        title: "Monthly Total",
        labelData: labels,
        date: historicalView
          ? `${format(reportTime, "LLLL")}`
          : `${format(subDays(reportTime, MONTHLY_LIMIT - 1), "dd LLL")} - ${format(reportTime, "dd LLL y")}`,
      });
    }

    if (activeTab === TODAY_VIEW_MODE.VIEW_YEARLY) {
      let list = [];
      for (let index = 0; index < YEARLY_LIMIT; index++) {
        list.push(`${format(subMonths(reportTime, index), "MMM")}`);
      }
      const labels = list.reverse();
      setCoughTodayConfig({
        title: "Yearly Total",
        labelData: labels,
        date: historicalView
          ? `${format(subDays(startOfYear(reportTime), 1), "y")}`
          : `${format(subMonths(reportTime, YEARLY_LIMIT - 1), "LLL y")} - ${format(reportTime, "LLL y")}`,
      });
    }
  }, [activeTab, reportTime, historicalView]);

  // Solves the issue of the page not scrolling to the top when navigating to this page
  useEffect(() => {
    window.scrollY >= 0 && window.scrollTo(0, 0);
  }, []);

  if (loadingSteps >= TOTAL_LOADING_STEPS && loading) {
    setLoading(false);
    setLoadingSteps((prevStep) => 0);
  }

  // render
  return (
    <div className="insight">
      <div className="insight-pull-to-refresh">
        <div className="insight-pull-to-refresh-content">
          <span className="insight-pull-to-refresh-content-text" onClick={() => window.location.reload()}>
            Release to refresh
          </span>
        </div>
      </div>
      <div
        className="insight-content"
        style={{ backgroundColor: isAnonymous ? "#eff3fe" : "#F7F7F9" }}
        {...pullHandler}
      >
        <div className="insight-content-wrapper">
          <Tabs
            tabList={[
              // {
              //   value: TODAY_VIEW_MODE.VIEW_HOURLY,
              //   label: "Hourly",
              // },
              {
                value: TODAY_VIEW_MODE.VIEW_DAILY,
                label: "Daily",
              },
              {
                value: TODAY_VIEW_MODE.VIEW_WEEKLY,
                label: "Weekly",
              },
              {
                value: TODAY_VIEW_MODE.VIEW_MONTHLY,
                label: "Monthly",
              },
              {
                value: TODAY_VIEW_MODE.VIEW_YEARLY,
                label: "Yearly",
              },
            ]}
            value={activeTab}
            onChange={async (tab) => {
              TOTAL_LOADING_STEPS = 2;
              setLoadingSteps(0);
              setLoading(true);
              setReportTime(new Date());
              historicalView && setHistoricalView(false);
              setActiveTab((currentTab) => {
                if (currentTab === tab) return currentTab;
                setCoughTodayConfig({
                  title: ``,
                  labelData: [],
                  date: ``,
                });
                return tab;
              });
            }}
          />
          {lockView ? (
            <div className="insight-content-wrapper-guest-view-lock">
              <img alt="learn more!" src={IconLock} />
            </div>
          ) : (
            <div {...swipeHandlers}>
              <CoughToday
                loading={loading}
                title={coughTodayConfig?.title || ""}
                labelData={coughTodayConfig?.labelData || []}
                count={coughToday?.count || 0}
                labelDate={coughTodayConfig?.date || ""}
                date={reportTime}
                coughData={coughToday?.list || EMPTY_COUGH_LIST(activeTab)}
                viewMode={activeTab}
                datePicker={true}
                datePickerCallback={datePicked}
              />
            </div>
          )}
        </div>
        <div className="insight-content-container">
          {!user ? (
            <div className="loadingScreen"></div>
          ) : isAnonymous ? (
            <GuestView />
          ) : (
            <div>
              <div className="insight-content-widget">
                <div className="insight-content-widget-chevron">&gt;</div>
                <RateCard
                  loading={loading && coughRateData.length === 0}
                  coughList={coughRateData}
                  onClickAction={handleViewRate}
                />
              </div>
              <div className="insight-content-widget">
                <div className="insight-content-widget-chevron">&gt;</div>
                <TrendCard
                  loading={loading && coughTrend === null}
                  description={description}
                  coughTrend={coughTrend ?? null}
                  onClickAction={handleViewTrend}
                />
              </div>
              <div className="insight-content-widget">
                <div className="insight-content-widget-chevron">&gt;</div>
                <BurstCard startTime={reportBurstTime} onClickAction={handleViewBurst} />
              </div>
            </div>
          )}
          <div className={`insight-content-footer ${isAnonymous ? "fixed" : ""}`}>
            {!user ? (
              <button className="insight-content-footer-btn">
                <Spinner size={20} />
              </button>
            ) : isAnonymous ? (
              <a href="#redirect-page-login" className="insight-content-footer-btn">
                Sign In
                <img alt="close" src={IconLogin} />
              </a>
            ) : !isDesktopVersion() ? (
              <button className="insight-content-footer-btn" onClick={handleViewReport}>
                {!user ? <Spinner size={20} /> : "View Cough Report"}
              </button>
            ) : (
              <div className=""></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Insight;
