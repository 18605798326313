import React, { useEffect, useState } from "react";
import { Collapse, CollapseProps } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LineElement,
  PointElement,
  LinearScale,
  Filler,
  LogarithmicScale,
  TimeScale,
  TimeSeriesScale,
} from "chart.js/auto";
import type { ChartOptions } from "chart.js";
import { Chart } from "react-chartjs-2";
import { CoughRawEventResponse, EVENT_AGGREGATION, MetricType } from "../../models/event";
import { apiDateTimeFormat, formatShortTime, formatShortTimeMs } from "../../utils/date-operation";
import "./style.scss";
import { api } from "../../utils/axios";
import { addSeconds, differenceInMilliseconds, differenceInSeconds } from "date-fns";

const MAX_DIFF_SECS: number = 15;

ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  LineElement,
  PointElement,
  Filler,
  TimeScale,
  TimeSeriesScale
);

type LabelItem = {
  label: string | React.ReactNode;
  value: string;
  unit: string;
};

type BurstDetailCardProps = {
  timeStart: Date;
  timeEnd: Date;
  metric: LabelItem;
};

const getEventDetails = async (startDate: string, endDate: string, direction: string = "asc") => {
  try {
    const result = await api<CoughRawEventResponse>({
      url: `/events/v2`,
      method: "GET",
      params: {
        metric: MetricType.COUGH,
        aggregation: EVENT_AGGREGATION.RAW,
        start_date: startDate,
        end_date: endDate,
        direction,
        // fields: "uid,device_id,time,metric,utc_time,sensor,value1,value2",
        fields: "time,value1,value2",
      },
    });
    return result?.data?.events || [];
  } catch (error) {
    console.error("burstDetailCard.getEventDetails:", error);
    return [];
  }
};

const BurstDetailCard: React.FC<BurstDetailCardProps> = ({ timeStart, timeEnd, metric }) => {
  const [coughEvents, setCoughEvents] = useState<any[]>([]);
  const [longBoi, setLongBoi] = useState<boolean>(false);
  // const [loading, setLoading] = useState<boolean>(true);
  const [padding, setPadding] = useState<any[]>([]);

  useEffect(() => {
    const handleCoughData = async () => {
      const events = await getEventDetails(apiDateTimeFormat(timeStart), apiDateTimeFormat(addSeconds(timeEnd, 1)));
      if (events.length === 0) return;
      const start = new Date(events[0].time).getTime();
      const finish = new Date(events[events.length - 1].time).getTime();
      const padding: any[] = [];
      const diffInSecs = differenceInSeconds(finish, start, { roundingMethod: "floor" });

      let startPad: Date = new Date(start);
      const endPad = addSeconds(
        new Date(finish),
        diffInSecs <= MAX_DIFF_SECS ? MAX_DIFF_SECS - diffInSecs : diffInSecs % 5 === 0 ? 0 : 5 - (diffInSecs % 5)
      );
      padding.push({ x: startPad, y: 0 });
      while (startPad.getTime() < endPad.getTime()) {
        startPad = addSeconds(startPad, 5);
        if (startPad.getTime() > endPad.getTime()) break;
        padding.push({
          x: startPad,
          y: 0,
        });
      }

      if (diffInSecs > MAX_DIFF_SECS) {
        // padding.push({ x: endPad, y: 0 });
        setLongBoi(true);
      }

      const eventData = [...events.map((event) => ({ x: new Date(event.time), y: 1 }))];
      const cEvents = eventData.sort((a: any, b: any) => a.x - b.x).map((event) => ({ x: event.x, y: event.y }));

      setPadding(padding);
      setCoughEvents(cEvents);
    };
    handleCoughData();
    return () => {};
  }, [timeStart, timeEnd]);

  const composedData = [...coughEvents, ...padding];

  const durationValue =
    coughEvents.length > 0
      ? (differenceInMilliseconds(coughEvents[coughEvents.length - 1].x, coughEvents[0].x) / 1000).toFixed(1)
      : "";

  const data = {
    labels: padding.map((event) => event.x),
    type: "bar",
    datasets: [
      {
        // actual bars
        type: "bar" as const,
        radius: 10,
        grouped: false,
        barThickness: 2,
        data: composedData,
        backgroundColor: "#396df1",
        borderRadius: Number.MAX_VALUE,
        order: 0,
      },
      {
        // bar tips, its a line of points without line
        type: "line" as const,
        radius: 1.3,
        borderWidth: 0,
        grouped: false,
        barThickness: 2,
        data: composedData.map((ev) => ({ x: ev.x, y: ev.y })),
        backgroundColor: "#396df1",
        borderRadius: Number.MAX_VALUE,
        pointHitRadius: 0,
        hoverRadius: 1,
        order: 1,
      },
      {
        // grey line filled
        type: "line" as const,
        fill: true,
        pointRadius: 0,
        borderWidth: 0,
        borderColor: "#d5d7dd",
        backgroundColor: "#d5d7dd",
        borderRadius: Number.MAX_VALUE,
        data: coughEvents.map((event, index) => ({ x: event.x, y: 0.5 })),
        pointHitRadius: 0,
        order: 0,
      },
    ],
  };

  const chartOptions: ChartOptions<"bar" | "line"> = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
        padding: 4,
        yAlign: "center",
        xAlign: "center",
        callbacks: {
          title: function (context: any) {
            try {
              return formatShortTimeMs(new Date(context[0].parsed.x));
            } catch (e) {
              return "-";
            }
          },
          label: function () {
            return "";
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        // type: "logarithmic",
        type: "time",
        offsetAfterAutoskip: false,
        time: {
          unit: "second",
        },
        border: {
          display: true,
        },
        grid: {
          display: true,
          drawBorder: true,
          lineWidth: 0,
        },
        // suggestedMin: composedData && composedData.length > 0 && composedData[0]?.x,
        // suggestedMax:
        //   composedData && composedData.length > 0 && addMilliseconds(composedData[composedData.length - 1]?.x, 100),
        // min: composedData && composedData.length > 0 && composedData[0]?.x,
        // max: composedData && composedData.length > 0 && addMilliseconds(composedData[composedData.length - 1]?.x, 100),
        ticks: {
          source: "labels",
          padding: 0,
          maxRotation: 0,
          // autoSkip: true,
          beginAtZero: true,
          // stepSize: 5,
          callback: (value: string, index: number) => {
            // if (composedData && composedData.length > 0) {
            const secDiff = differenceInSeconds(new Date(value), composedData[0]?.x);
            // const secDiff = differenceInSeconds(new Date(composedData[index].x), composedData[0].x);
            // console.log(index);
            // if (index === 0 || index === composedData.length || (secDiff > 0 && secDiff % 5 === 0))
            //   return `${secDiff < 0 ? 0 : secDiff}s`;
            return `${secDiff < 0 ? 0 : secDiff}s`;
            // }
            // }
            // return "";
          },
        },
      },
      y: {
        display: false,
        min: 0.1,
      },
    } as any,
  };

  const items: CollapseProps["items"] = [
    {
      key: "burst!",
      label: (
        <div className="burst_detail_card-wrapper">
          <div className="burst_detail_card-wrapper-item">
            <h3 className="burst_detail_card-wrapper-item-label fs-18 text-align-left">{metric.label}</h3>
          </div>
          <div className="burst_detail_card-wrapper-item">
            <h4 className="burst_detail_card-wrapper-item-label">{formatShortTime(timeStart)}</h4>
          </div>
        </div>
      ),
      children: (
        <>
          <div className="burst_detail_card-chart-wrapper">
            <div className={`burst_detail_card-chart-wrapper-scroll${longBoi ? "-long" : ""}`}>
              <Chart type="bar" options={chartOptions} data={data} height={"40px"} />
            </div>
          </div>
          <div className="burst_detail_card-wrapper">
            <div className="burst_detail_card-wrapper-item">
              <div className="burst_detail_card-wrapper-item-container">
                <p className="burst_detail_card-wrapper-item-container-value left">{metric.value}</p>
                <p className="burst_detail_card-wrapper-item-container-unit left">{metric.unit}</p>
              </div>
            </div>
            <div className="burst_detail_card-wrapper-item">
              <div className="burst_detail_card-wrapper-item-container fs-18 right mr">
                <p className="burst_detail_card-wrapper-item-container-value fs-18 lh no-mr fw-600">{durationValue}</p>
                <p className="burst_detail_card-wrapper-item-container-unit fs-18 fw-400">sec</p>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="burst_detail_card burst_detail_card-shadow">
      <Collapse items={items} expandIconPosition="end" ghost={true} destroyInactivePanel={true} />
    </div>
  );
};

export default BurstDetailCard;
