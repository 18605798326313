import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./style.scss";

type LoadingProps = {
  loading: boolean;
  size?: number;
  contained?: boolean;
  background?: boolean;
};

const Loading: React.FC<LoadingProps> = ({ loading, size = 100, contained = false, background = true }) => {
  if (!loading) {
    return null;
  }
  const antIcon = <LoadingOutlined style={{ fontSize: size }} spin />;

  return (
    <div className={`loading_container ${contained ? "loading_container-contained" : ""} `}>
      <div
        className={`loading_container_bg ${contained ? "loading_container_bg-contained" : ""}${
          !background && "loading_container_no_bg"
        }`}
      ></div>
      <Spin indicator={antIcon} />
    </div>
  );
};

export default Loading;
