/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MetricsEnum } from './MetricsEnum';
export type GetRawEventsQS = {
    /**
     * Pagination. Max number of entries returned.
     */
    limit?: number;
    /**
     * Pagination. Offset.
     */
    offset?: number;
    /**
     * Id of the device to use, if omitted calculated across all devices.
     */
    device_id?: string | null;
    /**
     * Metric fetched
     */
    metric?: MetricsEnum;
    /**
     * Start time, timestamp with tz
     */
    start_date?: string | null;
    /**
     * End time, timestamp with tz
     */
    end_date?: string | null;
    /**
     * Ascending or descending in time
     */
    direction?: GetRawEventsQS.direction;
    /**
     * List fields to return
     */
    fields?: Array<'uid' | 'device_id' | 'metric' | 'time' | 'utc_time' | 'sensor' | 'value1' | 'value2'>;
};
export namespace GetRawEventsQS {
    /**
     * Ascending or descending in time
     */
    export enum direction {
        ASC = 'asc',
        DESC = 'desc',
    }
}

