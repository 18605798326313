import { useRecoilValue } from "recoil";
import Loading from "../../components/loading";
import { useEffect } from "react";
import { AuthState } from "../../state/auth";
import { discourseLogin } from "../../lib/discourse";

const DiscourseHandler = () => {
  const { user } = useRecoilValue(AuthState);

  useEffect(() => {
    // Not logged in
    if (!user) return;

    // This component serves two purposes
    // 1. If navigating here from discourse and `sso` and `sig` are in search
    //    start the discourse sso flow.
    // 2. If navigating here without, redirect to the discourse website.
    // This is convenient since we can keep this route paywalled easier.

    const params = new URLSearchParams(document.location.search);
    const sso = params.get("sso");
    const sig = params.get("sig");

    if (sso === null || sig === null) {
      window.location.replace(process.env.REACT_APP_DISCOURSE_SITE_URL as string);
    } else {
      const controller = new AbortController();
      discourseLogin(sso, sig, controller.signal);
      return () => controller.abort();
    }
  }, [user]);

  return (
    <div style={{ position: "relative", width: "100%", flexGrow: "1" }}>
      <Loading loading />
    </div>
  );
};

export default DiscourseHandler;
