import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { getHours, startOfMonth } from "date-fns";
import { DonutChart } from "../charts";
import DayCycleColors from "./day-cycle-colors";
import DidNotTrackDonut from "./did-not-track";
import ClockHourLabels from "./hour-labels";
import { colors } from "./utils/colors";
import "./style.scss";
import { TODAY_VIEW_MODE } from "../../models/event";
import { shortDateFormat, startOfWeekMonday } from "../../utils/date-operation";
import Loading from "../loading";

export interface ClockChartProps {
  /** The reference to the chart */
  ref: any;
  /** If true, the chart will be in a loading state */
  loading: boolean;
  /** The active tab to be used for the chart */
  activeTab: string;
  /** The clock values to be used for the chart */
  clockValues: ClockValues;
  /** The date to be used for the daily check */
  currentDate: Date;
  /** defaults to "event" */
  eventTooltipLabelSingular?: string;
  /** defaults to "events" */
  eventTooltipLabelPlural?: string;
  /** If true, the daily check will be disabled, for Cough Report */
  disableDailyCheck?: boolean;
  /** The color to be used for the lowest value in the gradient */
  gradientLowestColor?: string;
  /**The color to be used for the highest value in the gradient */
  gradientHighestColor?: string;
  /**Number of decimal places to display in the tooltip */
  eventCountDecimalPlaces?: number;
  /** If the chart is visible or not */
  visible?: boolean;
  /** Reference name to be used in the canvas class to uniquely identify (eg. Cough Report hide/unhide) */
  refName?: string;
}

export interface ClockValues {
  list: ClockValue[];
  peakEvent: ClockValue | null;
}

export interface ClockValue {
  hour: number;
  count: number;
  time_tracked_s: number;
}

interface ClockChartRef {
  toBase64Image: () => void;
}

const ClockChart = forwardRef<ClockChartRef, ClockChartProps>(
  (
    {
      loading,
      activeTab,
      clockValues,
      currentDate,
      eventTooltipLabelSingular = "event",
      eventTooltipLabelPlural = "events",
      disableDailyCheck = false,
      gradientLowestColor = colors.PRIMARY_50,
      gradientHighestColor = colors.PRIMARY_900,
      eventCountDecimalPlaces = 0,
      visible = true,
      refName = "",
    },
    ref
  ) => {
    const isDaily = activeTab === TODAY_VIEW_MODE.VIEW_DAILY && !disableDailyCheck;
    const isWeekly = activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY;
    const isMonthly = activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY;
    const today = shortDateFormat(new Date());
    const isToday = shortDateFormat(currentDate) === today;
    const chartRef = useRef<any>(null);
    const dayCycleRef = useRef<any>(null);
    const didNotTrackRef = useRef<any>(null);
    const clockHoursRef = useRef<any>(null);
    useImperativeHandle(ref, () => ({
      toBase64Image: async () => {
        return exportCanvases();
      },
    }));

    const className = `clock-chart-wrapper-chart${refName}`;

    const exportCanvases = (): string | undefined => {
      // Get all canvas elements with the common class
      const canvases = document.getElementsByClassName(className);

      // Ensure at least one canvas is available
      if (canvases.length === 0) {
        console.error("No canvases found.");
        return undefined;
      }

      // Cast the first canvas to HTMLCanvasElement
      const firstCanvas = canvases[0] as HTMLCanvasElement;

      // Find the center point
      const centerX = firstCanvas.width / 2;
      const centerY = firstCanvas.height / 2;

      // Create a new canvas to combine the images
      const combinedCanvas = document.createElement("canvas");

      // We assume all canvases have the same dimensions
      combinedCanvas.width = centerX * 2;
      combinedCanvas.height = centerY * 2;

      const ctx = combinedCanvas.getContext("2d");
      // Draw each canvas onto the combined canvas
      for (let i = 0; i < canvases.length; i++) ctx?.drawImage(canvases[i] as HTMLCanvasElement, 0, 0);

      // Convert the combined canvas to a data URL (base64-encoded PNG)
      return combinedCanvas.toDataURL("image/png");
    };

    if (!visible) return null;

    return (
      <div className="clock-chart-wrapper">
        {loading && <Loading loading={loading} contained={true} />}
        <DayCycleColors ref={dayCycleRef} className={className} />
        <DidNotTrackDonut ref={didNotTrackRef} className={className} />
        <ClockHourLabels ref={clockHoursRef} className={className} />
        <DonutChart
          ref={chartRef}
          id={`id-donut-data-${refName}`}
          className={className}
          options={{
            cutout: "65%",
            responsive: true,
            mantainsAspectRatio: true,
            layout: {
              padding: 25,
            },
            animation: {
              animateScale: true,
            },
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: (context: any) => {
                    if (clockValues?.list.length === 0) return "!no tracked data!";
                    const eventCount = clockValues?.list[context?.dataIndex].count;
                    const notTrackedData =
                      clockValues?.list[context.dataIndex]?.hour > getHours(new Date())
                        ? (isDaily && shortDateFormat(currentDate) === today) ||
                          (isMonthly && shortDateFormat(startOfMonth(currentDate)) === today) ||
                          (isWeekly && shortDateFormat(startOfWeekMonday(currentDate)) === today)
                          ? "not yet tracked today"
                          : "not yet tracked"
                        : "no tracked data";
                    return (clockValues?.list[context?.dataIndex].time_tracked_s ?? 0) > 0
                      ? `${eventCount.toFixed(eventCountDecimalPlaces)} ${
                          eventCount >= 1 && eventCount < 2 ? eventTooltipLabelSingular : eventTooltipLabelPlural
                        }`
                      : notTrackedData;
                  },
                  // title: function (context: any) {
                  //   return displayLabelData[context[0].dataIndex];
                  // },
                },
              },
            },
          }}
          datasets={[
            {
              data: Array(24).fill(1),
              borderWidth: function (context: any): number {
                return isDaily && isToday && clockValues?.list[context.dataIndex]?.hour > getHours(new Date()) ? 1 : 0;
              },
              borderDash: [3, 3],
              borderDashOffset: 3,
              borderColor: "#a1A5B1", // neutral-400
              backgroundColor: function (context: any): string {
                const currentValue = clockValues?.list[context.dataIndex] ?? undefined;

                if (!currentValue) return colors.TRANSPARENT;

                const timeTracked = (clockValues && clockValues.list[context.dataIndex].time_tracked_s) ?? 0;
                const count = (clockValues && clockValues.list[context.dataIndex].count) ?? 0;

                if (timeTracked && count === 0) return colors.PRIMARY_50;

                if (
                  (isDaily && timeTracked === 0 && currentValue.hour <= getHours(new Date())) ||
                  (isDaily && !isToday && timeTracked === 0) ||
                  ((isWeekly || isMonthly) && count === 0)
                )
                  return colors.TRANSPARENT;

                return colors.getClockReferenceColors(
                  clockValues?.list[context.dataIndex]?.count ?? 0,
                  clockValues?.peakEvent?.count ?? 0,
                  (clockValues?.peakEvent?.count ?? 0) + 1,
                  gradientLowestColor,
                  gradientHighestColor
                );
              },
            },
          ]}
        />
      </div>
    );
  }
);
export default ClockChart;
