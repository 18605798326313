import { format, parse } from "date-fns";
import IconChevron from "../../assets/icons/icon_chevron.svg";
import { ChangeEvent } from "react";
import { TODAY_VIEW_MODE } from "../../models/event";
import { ViewModeFormatters, getLabelForDate } from "../../utils/date-operation";
import "./style.scss";

type DatePickerProps = {
  date: Date;
  onSelectDate: Function | undefined;
  titleFontSize?: number;
  prependText?: string;
  viewMode?: string | TODAY_VIEW_MODE;
  viewModeFormatters?: ViewModeFormatters;
};

const displayCalendar = () => {
  try {
    const inputEl: HTMLInputElement | null = document.getElementById("date-picker-hidden-input") as HTMLInputElement;
    if (inputEl !== null && inputEl.getAttribute("type") === "date") {
      inputEl.showPicker();
    }
  } catch (e) {
    console.error(e);
  }
};

// component
const DatePicker = ({
  date,
  onSelectDate,
  titleFontSize = 14,
  prependText = "",
  viewMode = TODAY_VIEW_MODE.VIEW_DAILY,
  viewModeFormatters,
}: DatePickerProps) => {
  const value = format(date, "yyyy-MM-dd");
  const display = getLabelForDate(date, viewMode, viewModeFormatters);

  const onChangeDate = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && onSelectDate) {
      onSelectDate(parse(e.target.value, "yyyy-MM-dd", new Date()));
    }
    e.preventDefault();
  };

  //render
  return (
    <div className="date-picker" style={{ fontSize: titleFontSize }}>
      <div className="date-picker-display" onClick={displayCalendar} onTouchStart={displayCalendar}>
        <span className="date-picker-display-text">{`${prependText}${display}`}</span>
        <img className="date-picker-display-chevron" alt="" src={IconChevron} />
        <input
          id="date-picker-hidden-input"
          className="date-picker-display-input-hidden"
          type="date"
          value={value}
          // onBlur={(e) => onChangeDate(e)}
          onChange={(e) => onChangeDate(e)}
          style={{
            display: navigator.userAgent.toLowerCase().indexOf("iphone") >= 0 ? "inherit" : "none",
          }}
        />
      </div>
    </div>
  );
};

export default DatePicker;
