import { useCallback, useEffect } from "react";
import { IdTokenResult, getAuth, onAuthStateChanged } from "firebase/auth";

import amplitude from "../lib/analytics/amplitude";
import { backOff } from "exponential-backoff";
import { useRecoilState } from "recoil";

import dayjs, { Dayjs } from "dayjs";
import { User } from "firebase/auth";
import { atom } from "recoil";
import { onSnapshot, doc, Timestamp } from "firebase/firestore";
import { firestore } from "../lib/firebase/firebase";

type HyfeInsightsClaims = IdTokenResult["claims"] & {
  hyfe: {
    insights: {
      customer_id: string;
      uid: string;
    };
  };
};

export interface AuthStateInterface {
  user: Partial<User & { claims: HyfeInsightsClaims }> | null | undefined;
  isAnonymous: boolean | undefined;
  isFbAuthInit: boolean | undefined;
  isPremium: boolean | undefined;
  isInTrialPeriod: boolean | undefined;
  trialStartDate: Dayjs | undefined;
  isEligibleTrialPeriod: boolean | undefined;
  isHyfeStaff: boolean | undefined;
  refreshPremiumStatus: () => Promise<void>;
}

const defaultState: AuthStateInterface = {
  user: undefined,
  isAnonymous: undefined,
  isFbAuthInit: undefined,
  isPremium: undefined,
  isInTrialPeriod: undefined,
  trialStartDate: undefined,
  isEligibleTrialPeriod: undefined,
  isHyfeStaff: undefined,
  refreshPremiumStatus: async () => {},
};

export const AuthState = atom({
  key: "Globals.AuthState",
  default: defaultState,
  dangerouslyAllowMutability: true,
});

const strFacebookProvider = "facebook.com";
export const useFirebaseAuthConnector = () => {
  const [authState, setAuthState] = useRecoilState(AuthState);
  const { user, isPremium } = authState;

  useEffect(() => {
    const auth = getAuth();
    const unsub = onAuthStateChanged(auth, async (user) => {
      // After firebase auth initializes it will init the user object
      // if credentials were in local storage user will be filled otherwise it will be null
      setAuthState((prevState) => ({ ...prevState, isFbAuthInit: true }));
      if (user) {
        // Important to set isAnonymous before user.
        // This way checks like:
        // !user ? (loading state) : isAnonymous ? (anonymous content) : (non-anonymous content)
        // work as expected and don't show non-anonymous content for brief moment.
        const isFacebookLogin =
          user.providerData.findIndex((profile) => profile.providerId === strFacebookProvider) >= 0;
        const idTokenResult = await user.getIdTokenResult();
        setAuthState((prevState) => ({
          ...prevState,
          isAnonymous:
            user.isAnonymous ||
            (user.email === null && user.phoneNumber === null && !isFacebookLogin) ||
            user.providerData.length === 0,
          user: {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            metadata: { creationTime: user.metadata.creationTime },
            photoURL: user.photoURL,
            claims: idTokenResult.claims as HyfeInsightsClaims,
          },
          isHyfeStaff: (user.email?.endsWith("@hyfe.ai") || user.email?.endsWith("@hyfeapp.com")) && user.emailVerified,
        }));

        // Notify amplitude of user login
        amplitude.setUserId(user.uid);
      } else {
        setAuthState((prevState) => ({
          ...prevState,
          user: null,
          isAnonymous: undefined,
          isPremium: undefined,
          isHyfeStaff: undefined,
          isInTrialPeriod: undefined,
          trialStartDate: undefined,
          isEligibleTrialPeriod: undefined,
        }));
        amplitude.setUserId(undefined);
      }
    });
    return unsub;
  }, [setAuthState]);

  const checkPremiumStatus = useCallback(async () => {
    const check = async (forceRefresh: boolean) => {
      let results;
      const _user = getAuth().currentUser;
      results = await _user?.getIdTokenResult(forceRefresh);
      if (!results) throw new Error();
      const claims: any = results?.claims;
      if (!claims?.revenueCatEntitlements?.includes("Premium")) throw new Error();
      return true;
    };
    if (user) {
      // First check once without forcing a refresh
      check(true)
        .then(() => {
          setAuthState((prevState) => ({ ...prevState, isPremium: true }));
        })
        // If not premium, try again with a forced refresh
        .catch(() => {
          setAuthState((prevState) => ({ ...prevState, isPremium: false }));
          backOff(() => check(true))
            .then(() => setAuthState((prevState) => ({ ...prevState, isPremium: true })))
            .catch(() => setAuthState((prevState) => ({ ...prevState, isPremium: false })));
        });
    }
  }, [user, setAuthState]);

  useEffect(() => {
    if (isPremium === undefined) {
      checkPremiumStatus();
    }
    setAuthState((prevState) => ({ ...prevState, refreshPremiumStatus: checkPremiumStatus }));
  }, [isPremium, checkPremiumStatus, setAuthState]);

  useEffect(() => {
    if (user) {
      return onSnapshot(doc(firestore, `/user_sounds/${user.uid}`), (doc) => {
        if (doc.exists() && "trial_start_date" in doc.data()) {
          const is_trial_promo = doc.data()["rc_promotion_reason"] === "subscriber_trial";
          const trial_start_date = dayjs((doc.data()["trial_start_date"] as Timestamp).toDate());
          const in_trial = is_trial_promo && trial_start_date ? trial_start_date.add(3, "day") > dayjs() : false;
          setAuthState((prev) => ({
            ...prev,
            isInTrialPeriod: in_trial,
            isEligibleTrialPeriod: false,
            trialStartDate: trial_start_date,
          }));
        } else {
          setAuthState((prev) => ({
            ...prev,
            isInTrialPeriod: false,
            isEligibleTrialPeriod: true,
            trialStartDate: undefined,
          }));
        }
      });
    }
  }, [user, setAuthState]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.group("Auth State");
      Object.keys(authState).forEach((key) => console.log(key, (authState as any)[key]));
      console.groupEnd();
    }
  }, [authState]);
};
