import React from "react";
import { CoughEmpty, CoughWidget } from "@hyfe/react-widget-lib";
import { CoughTrend } from "../../models/event";
import CardLoader from "../card-loader";
import "./style.scss";

type TrendCardProps = {
  coughTrend: CoughTrend | null;
  loading?: boolean;
  description?: string;
  onClickAction?: Function | undefined;
};

const EMPTY_COUGH_TREND = {
  pvalue: 0,
  angle: 0,
  direction: "up",
};

const TrendCard: React.FC<TrendCardProps> = ({ loading = false, description = "", coughTrend, onClickAction }) => {
  if (loading) {
    return (
      <div className="trend-card">
        <CardLoader rows={10} />
      </div>
    );
  }

  const OnClick = () => !loading && onClickAction && onClickAction();

  return (
    <div onClick={OnClick}>
      {coughTrend ? (
        <CoughWidget title="COUGH TREND" data={coughTrend || EMPTY_COUGH_TREND} description={description} />
      ) : (
        <CoughEmpty
          title="COUGH TREND"
          label="Keep tracking!"
          description="Insufficient data: you haven’t tracked for long enough to reveal your cough trend"
        />
      )}
    </div>
  );
};

export default TrendCard;
