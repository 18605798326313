export enum MetricType {
  COUGH = "cough",
  COUGH_BURST = "cough_burst",
  HEARTBEAT = "heartbeat",
  COUGH_OASIS = "cough_oasis",
  BATTERY_LEVEL = "battery_level",
}

export enum EVENT_AGGREGATION {
  MONTHLY = "month",
  WEEKLY = "weekly",
  DAILY = "daily",
  HOURLY = "hour",
  QUARTER = "15min",
  RAW = "raw",
}

export enum EVENT_LIMIT {
  DAILY = 5 * 60,
  WEEKLY = 30 * 60,
  MONTHLY = 3 * 3600,
  YEARLY = MONTHLY * 4,
}

export enum TODAY_VIEW_MODE {
  VIEW_HOURLY = "VIEW_HOURLY",
  VIEW_DAILY = "VIEW_DAILY",
  VIEW_WEEKLY = "VIEW_WEEKLY",
  VIEW_MONTHLY = "VIEW_MONTHLY",
  VIEW_YEARLY = "VIEW_YEARLY",
}

export enum DataType {
  ZERO = 0,
  NOT_ENOUGH = -1,
  NOT_TRACK = -2,
}

export type CoughEvent = {
  time: string;
  uid: string;
  device_id: string[] | string;
  count: number;
  time_tracked_s: number;
  metric: MetricType.COUGH;
};

export type CoughRawEvent = {
  time: string;
  utc_time: string;
  sensor: string;
  value1: string;
  value2: string;
};

export type CoughBurstEvent = {
  uid: string;
  time: string;
  metric: MetricType.COUGH_BURST;
  device_id: string[] | string;
  time_tracked_s: number;
  count: number;
  avg1: number;
  avg2: number;
  min1: number;
  min2: number;
  max1: number;
  max2: number;
  stddev1: number;
  stddev2: number;
  sum1: number;
  sum2: number;
};

export type CoughClockEvent = {
  hour: number;
  count: number;
  time_tracked_s: number;
};

export type CoughTrend = {
  angle: number;
  direction: string;
  pvalue: number;
};

export type CoughEventResponse = {
  events: CoughEvent[];
};

export type CoughRawEventResponse = {
  events: CoughRawEvent[];
};

export type CoughBurstEventResponse = {
  events: CoughBurstEvent[];
};

export type CoughBurstClockResponse = {
  clock: CoughClockEvent[];
};

export type CoughClock = {
  count: number;
  hour: number;
  time_tracked_s: number;
};

export type CoughClockResponse = {
  clock: CoughClock[];
};

export enum TREND_GRANULARITY {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}
