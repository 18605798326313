import { TouchEvent, useState } from "react";

interface SwipeInput {
  onPullDown?: (distanceTraveled: number, e: TouchEvent) => void;
  onPullUp?: (distanceTraveled: number, e: TouchEvent) => void;
  onMoveScreenUp?: (distanceTraveled: number, e: TouchEvent) => void;
  onMoveScreenDown?: (distanceTraveled: number, e: TouchEvent) => void;
  minPullDistance?: number;
}

interface SwipeOutput {
  onTouchStart: (e: TouchEvent) => void;
  onTouchMove: (e: TouchEvent) => void;
  onTouchEnd: (e: TouchEvent) => void;
}

const usePull = (input: SwipeInput): SwipeOutput => {
  const [touchStartY, setTouchStartY] = useState(0);
  const [touchEndY, setTouchEndY] = useState(0);

  const minDefaultSwipeDistance = 50;

  const onTouchStart = (e: TouchEvent) => {
    setTouchEndY(0);
    setTouchStartY(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e: TouchEvent) => {
    const distanceY = touchStartY - touchEndY;
    if (input.onMoveScreenUp && e.targetTouches[0].clientY > touchStartY) input.onMoveScreenUp(distanceY, e);
    if (input.onMoveScreenDown && e.targetTouches[0].clientY < touchStartY) input.onMoveScreenDown(distanceY, e);
    setTouchEndY(e.targetTouches[0].clientY);
  };

  const onTouchEnd = (e: TouchEvent) => {
    if (!touchStartY || !touchEndY) return;
    const distanceY = touchStartY - touchEndY;
    const isPullUp = distanceY > (input?.minPullDistance ?? minDefaultSwipeDistance);
    const isPullDown = distanceY < -(input?.minPullDistance ?? minDefaultSwipeDistance);
    if (isPullDown && input.onPullDown) input.onPullDown(distanceY, e);
    if (isPullUp && input.onPullUp) input.onPullUp(distanceY, e);
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};

export default usePull;
