/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetAllUsersInGroupStatsEventQS = {
    /**
     * Pagination. Max number of entries returned.
     */
    limit?: number | null;
    /**
     * Pagination. Offset.
     */
    offset?: number | null;
    /**
     * Order by field. Default is uid.
     */
    order_by?: GetAllUsersInGroupStatsEventQS.order_by;
    /**
     * Order direction. Default is asc.
     */
    direction?: GetAllUsersInGroupStatsEventQS.direction;
};
export namespace GetAllUsersInGroupStatsEventQS {
    /**
     * Order by field. Default is uid.
     */
    export enum order_by {
        UID = 'uid',
        ALIAS = 'alias',
        EXTERNAL_ID = 'external_id',
        FIRST_ACTIVE_LOCAL_TIME = 'first_active_local_time',
        FIRST_ACTIVE_UTC = 'first_active_utc',
        LAST_ACTIVE_LOCAL_TIME = 'last_active_local_time',
        LAST_ACTIVE_UTC = 'last_active_utc',
    }
    /**
     * Order direction. Default is asc.
     */
    export enum direction {
        ASC = 'asc',
        DESC = 'desc',
    }
}

