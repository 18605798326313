/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MetricsEnum {
    COUGH = 'cough',
    HEARTBEAT = 'heartbeat',
    BATTERY_LEVEL = 'battery_level',
    COUGH_BURST = 'cough_burst',
    COUGH_OASIS = 'cough_oasis',
}
