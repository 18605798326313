import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { DonutChart } from "../../charts";
import { colors } from "../utils/colors";
import "../style.scss";

const DONUT_TOTAL_COLOR_SECTIONS = 100;

type DayCycleColorProps = {
  donutTotalColorSections?: number;
  className?: string;
};
interface DayCycleColorRef {
  toBase64Image: () => void;
}

const DayCycleColors = forwardRef<DayCycleColorRef, DayCycleColorProps>(
  ({ donutTotalColorSections, className = "clock-chart-wrapper-chart" }, ref) => {
    const chartRef = useRef<any>(null);
    useImperativeHandle(ref, () => ({
      toBase64Image: () => {
        return chartRef.current?.toBase64Image();
      },
    }));
    return (
      <>
        <DonutChart
          ref={chartRef}
          id="donut-day-cycle-color-labels"
          className={className}
          options={{
            radius: "50%",
            cutout: "0%",
            responsive: true,
            mantainsAspectRatio: true,
            layout: {
              padding: 5,
            },
            animation: {
              animateScale: true,
              animateRotate: false,
            },
          }}
          datasets={[
            {
              borderWidth: 1,
              borderColor: "#e7e8eb", // neutral-100
              backgroundColor: colors.TRANSPARENT,
              data: Array(2).fill(1),
              datalabels: {
                display: true,
                anchor: "center",
                font: {
                  size: 16,
                  weight: "bold",
                },
                color: (context: any) => (context.dataIndex === 0 ? colors.NIGHT : colors.DAY),
                align: (context: any) => (context.dataIndex === 0 ? "left" : "right"),
                formatter: (value: any, context: any) => (context.dataIndex === 0 ? "AM" : "PM"),
                offset: -10,
              },
            },
          ]}
        />
        <DonutChart
          id="donut-day-cycle-colors"
          className={className}
          options={{
            radius: "60%",
            cutout: "90%",
            responsive: true,
            mantainsAspectRatio: true,
            layout: {
              padding: 25,
            },
            animation: {
              animateScale: true,
            },
          }}
          datasets={[
            {
              borderWidth: 0,
              borderColor: colors.TRANSPARENT,
              backgroundColor: colors.dayCycleGradientColors(DONUT_TOTAL_COLOR_SECTIONS),
              data: Array(donutTotalColorSections).fill(1),
            },
          ]}
        />
      </>
    );
  }
);

DayCycleColors.defaultProps = {
  donutTotalColorSections: DONUT_TOTAL_COLOR_SECTIONS,
};

export default DayCycleColors;
