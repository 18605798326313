import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LinearScale,
  ChartOptions,
} from "chart.js";
import Annotation from "chartjs-plugin-annotation";
import Loading from "../loading";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels, Annotation);

const PRIMARY_800 = "#244598";

type StepBarChartProps = {
  valueData: number[];
  labelData: (string | string[])[];
  activeColor?: string;
  height?: string;
  loading?: boolean;
  paintActiveColor?: boolean;
};

interface StepBarChartRef {
  toBase64Image: () => void;
}

const StepBarChart = forwardRef<StepBarChartRef, StepBarChartProps>(
  ({ valueData, labelData, height, loading, activeColor = "#CCCCCC", paintActiveColor = false }, ref) => {
    const chartRef = useRef<any>(null);
    if (loading) activeColor = "#CCCCCC";
    const annotation = {
      annotation: {
        annotations: {
          box1: {
            // Indicates the type of annotation
            type: "box",
            xMin: valueData.length === 0 ? 1000 : valueData.length - 1.5,
            xMax: valueData.length === 0 ? 1000 : valueData.length - 0.5,
            yMin: 0,
            yMax: height,
            borderWidth: 0,
            backgroundColor: activeColor + "33",
          },
        },
      },
    };
    const options: ChartOptions<"bar"> = {
      backgroundColor: "yellow",
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        ...annotation,
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: "",
        },
        datalabels: {
          display: false,
        },
        tooltip: {
          enabled: true,
          titleFont: {
            size: 16,
          },
          padding: 3,
          xAlign: "center",
          yAlign: "bottom",
          titleColor: "#244598",
          titleSpacing: 0,
          titleMarginBottom: 0,
          backgroundColor: "#fff",
          borderColor: "rgb(24 45 98 /10%)",
          borderWidth: 1,
          cornerRadius: 12,
          caretSize: 0,
          caretPadding: 6,
          callbacks: {
            title: function (context: any) {
              try {
                return " " + parseFloat(context[0].formattedValue).toFixed(1) + " ";
              } catch (e) {
                return context[0].formattedValue;
              }
            },
            label: function () {
              return "";
            },
          },
        },
      },
      scales: {
        y: {
          position: "right",
          ticks: {
            callback: function (value: any, index: any, ticks: any) {
              return value + "/hr";
            },
          },
        },
      },
    } as any;

    const data = {
      labels: labelData,
      datasets: [
        {
          label: "",
          data: valueData.map((item) => (item > 0 ? item : 0)),
          backgroundColor: "rgba(0, 0, 0, 0)",
          borderColor: valueData.map((_, idx) =>
            idx === valueData.length - 1 ? (paintActiveColor ? activeColor : PRIMARY_800) : PRIMARY_800
          ),
          borderWidth: {
            top: 5,
          },
          borderRadius: 0,
        },
      ],
    };

    const [tooltip, setTooltip] = useState<any>(null);
    useEffect(() => {
      return () => {
        if (tooltip !== null) tooltip.style.opacity = 0;
      };
    }, [tooltip]);

    useImperativeHandle(ref, () => ({
      toBase64Image: () => {
        return chartRef.current?.toBase64Image();
      },
    }));

    const getTooltip = (context: any) => {
      const foundTooltip = document.getElementById("chartjs-tooltip");
      if (foundTooltip) setTooltip(document.getElementById("chartjs-tooltip"));
    };

    return (
      <div
        style={{
          position: "relative",
          height,
          // "Hack" to make the plot fully responsive (when shrinking too)
          // The chart takes the size from the div and resizes when the div does
          // However when shrinking the div will not shrink beyond the size of it's content (the chart)
          // thus no triggering the re-render of the chart to make it smaller.
          // Setting a grid display to minmax(0,1fr) will prevent it.
          display: "grid",
          gridTemplateColumns: "minmax(0,1fr)",
          gridTemplateRows: "minmax(0,1fr)",
        }}
        // onMouseLeave={hideTooltip}
        // onPointerLeave={hideTooltip}
      >
        <Loading loading={!!loading} />
        <Bar
          ref={chartRef}
          options={options}
          data={data}
          onClick={getTooltip}
          onBlur={() => {
            if (tooltip !== null) tooltip.style.opacity = 0;
          }}
        />
      </div>
    );
  }
);

export default StepBarChart;
