/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExportSurveyAnswers_200 } from '../models/ExportSurveyAnswers_200';
import type { GetAllUserStats_200 } from '../models/GetAllUserStats_200';
import type { GetUsersInGroup_200 } from '../models/GetUsersInGroup_200';
import type { ListGroups_200 } from '../models/ListGroups_200';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class GroupsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List groups
     * Get the list of groups which you are allowed to access
     * @param customerId Id of the tenant
     * @returns ListGroups_200
     * @throws ApiError
     */
    public getGroups(
        customerId: string,
    ): CancelablePromise<ListGroups_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/groups',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Get all users in a group
     * Fetch all users belonging to a group.
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param limit Pagination. Max number of entries returned.
     * @param offset Pagination. Offset.
     * @returns GetUsersInGroup_200 Successful response
     * @throws ApiError
     */
    public getGroupsUsers(
        customerId: string,
        groupId: string,
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<GetUsersInGroup_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/groups/{group_id}/users',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Get all user stats
     * Get stats for all users in a group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param limit Pagination. Max number of entries returned.
     * @param offset Pagination. Offset.
     * @param orderBy Order by field. Default is uid.
     * @param direction Order direction. Default is asc.
     * @returns GetAllUserStats_200 Successful response
     * @throws ApiError
     */
    public getGroupsUsersStats(
        customerId: string,
        groupId: string,
        limit: number | null = 10,
        offset?: number | null,
        orderBy: 'uid' | 'alias' | 'external_id' | 'first_active_local_time' | 'first_active_utc' | 'last_active_local_time' | 'last_active_utc' = 'uid',
        direction: 'asc' | 'desc' = 'asc',
    ): CancelablePromise<GetAllUserStats_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/groups/{group_id}/users/stats',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
            },
            query: {
                'limit': limit,
                'offset': offset,
                'order_by': orderBy,
                'direction': direction,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Export surveys
     * Export all survey answers for all users in a group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param limit Pagination. Max number of entries returned.
     * @param offset Pagination. Offset.
     * @returns ExportSurveyAnswers_200
     * @throws ApiError
     */
    public getGroupsSurveysAnswers(
        customerId: string,
        groupId: string,
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<ExportSurveyAnswers_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/groups/{group_id}/surveys/answers',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
}
