import { CoughEmpty, CoughWidget } from "@hyfe/react-widget-lib";
import React, { useEffect, useMemo, useState } from "react";
import Tabs from "../../components/tabs";
import "./style.scss";
import dayjs, { Dayjs } from "dayjs";
import { CoughTrend, TODAY_VIEW_MODE, TREND_GRANULARITY } from "../../models/event";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { api } from "../../utils/axios";
import { Chart as ChartJS, CategoryScale, BarElement, Title, Tooltip, Legend, LinearScale } from "chart.js";
import { StepBarChart } from "../../components/charts";
import { subDays } from "date-fns";
import { getStatus } from "../../utils/trend";
import { apiDateTimeFormat } from "../../utils/date-operation";
import { useRecoilValue } from "recoil";
import { AuthState } from "../../state/auth";
import { AppState } from "../../state/global";
import { InsightsDev } from "../../apis/insightsv2/InsightsDev";
import { Insights } from "../../apis/insightsv2/Insights";
import { AggregationsEnum, MetricsEnum } from "../../apis/insightsv2";
import { useQuery } from "@tanstack/react-query";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const EMPTY_COUGH_TREND = {
  pvalue: 0,
  angle: 0,
  direction: "up",
};

const DEVICE_ID = "";

const Trend = () => {
  const [activeTab, setActiveTab] = useState<TODAY_VIEW_MODE | string>(TODAY_VIEW_MODE.VIEW_DAILY);

  const { user } = useRecoilValue(AuthState);
  const { isDevMode } = useRecoilValue(AppState);

  const [coughTodayTrend, setCoughTodayTrend] = useState<any>({
    success: false,
    data: null,
  });
  const [coughWeeklyTrend, setCoughWeeklyTrend] = useState<any>({
    success: false,
    data: null,
  });
  const [coughMonthlyTrend, setCoughMonthlyTrend] = useState<any>({
    success: false,
    data: null,
  });

  const [startTime] = useState<Date>(new Date());

  useEffect(() => {
    handleLoad();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { data, isFetching, isLoading } = useQuery({
    queryKey: [user?.uid, isDevMode, activeTab],
    queryFn: async () => {
      try {
        let startDate;
        switch (activeTab) {
          default:
          case TODAY_VIEW_MODE.VIEW_DAILY:
            startDate = dayjs(startTime).subtract(3, "day").toISOString();
            break;
          case TODAY_VIEW_MODE.VIEW_WEEKLY:
            startDate = dayjs(startTime).subtract(3, "week").toISOString();
            break;
          case TODAY_VIEW_MODE.VIEW_MONTHLY:
            startDate = dayjs(startTime).subtract(3, "month").toISOString();
            break;
        }
        let aggregation;
        switch (activeTab) {
          default:
          case TODAY_VIEW_MODE.VIEW_DAILY:
            aggregation = AggregationsEnum.DAILY;
            break;
          case TODAY_VIEW_MODE.VIEW_WEEKLY:
            aggregation = AggregationsEnum.WEEKLY;
            break;
          case TODAY_VIEW_MODE.VIEW_MONTHLY:
            aggregation = AggregationsEnum.MONTHLY;
            break;
        }

        const client = isDevMode ? new InsightsDev() : new Insights();
        const resp = await client.events.getUsersEventsAggregated(
          isDevMode ? "coughpro_dev" : "coughpro",
          user?.claims?.hyfe?.insights?.uid || "",
          100,
          0,
          null,
          aggregation,
          startDate,
          dayjs(startTime).toISOString(),
          "asc",
          false,
          ["count"],
          [MetricsEnum.COUGH]
        );
        return resp.events;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    retry: 1,
    staleTime: 1000 * 60 * 5,
  });

  const handleLoad = () => {
    handleLoadDailyTrend();
    handleLoadWeeklyTrend();
    handleLoadMonthlyTrend();
  };

  const getCoughTrend = async (deviceId: any, dateTime: string, granularity: TREND_GRANULARITY) => {
    try {
      const result = await api<CoughTrend>({
        url: `/insights/trend`,
        method: "GET",
        params: {
          // device_id: DEVICE_ID,
          date: dateTime,
          granularity: granularity,
        },
      });
      if (result?.status === 200) {
        return {
          success: true,
          data: result?.data,
        };
      } else if (result?.status === 204) {
        return {
          success: false,
          data: null,
        };
      }
    } catch (error) {
      console.error("trend.getCoughTrend:", error);
      return {
        success: false,
        data: null,
      };
    }

    return {
      success: false,
      data: null,
    };
  };

  const handleLoadDailyTrend = async () => {
    const dailyTrend = await getCoughTrend(DEVICE_ID, apiDateTimeFormat(startTime), TREND_GRANULARITY.DAY);
    setCoughTodayTrend(dailyTrend);
  };

  const handleLoadWeeklyTrend = async () => {
    const dailyTrend = await getCoughTrend(DEVICE_ID, apiDateTimeFormat(subDays(startTime, 1)), TREND_GRANULARITY.WEEK);
    setCoughWeeklyTrend(dailyTrend);
  };

  const handleLoadMonthlyTrend = async () => {
    const dailyTrend = await getCoughTrend(
      DEVICE_ID,
      apiDateTimeFormat(subDays(startTime, 2)),
      TREND_GRANULARITY.MONTH
    );
    setCoughMonthlyTrend(dailyTrend);
  };

  const trendConfig = useMemo(() => {
    if (activeTab === TODAY_VIEW_MODE.VIEW_DAILY) {
      return {
        label: "Last 24h compared to the last 4 days.",
      };
    }

    if (activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY) {
      return {
        label: "Your cough trend this week compared to the previous 3 weeks",
      };
    }

    if (activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY) {
      return {
        label: "Your cough trend this month compared to the previous 3 months",
      };
    }

    return {
      label: "Last 24h compared to the last 4 days",
    };
  }, [activeTab]);

  const trendValue = useMemo(() => {
    if (activeTab === TODAY_VIEW_MODE.VIEW_DAILY) {
      return coughTodayTrend;
    }

    if (activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY) {
      return coughWeeklyTrend;
    }

    if (activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY) {
      return coughMonthlyTrend;
    }

    return coughTodayTrend;
  }, [activeTab, coughTodayTrend, coughWeeklyTrend, coughMonthlyTrend]);

  const activeColor = useMemo(() => {
    if (activeTab === TODAY_VIEW_MODE.VIEW_DAILY && coughTodayTrend?.success) {
      return getStatus(coughTodayTrend.data);
    }

    if (activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY && coughWeeklyTrend.success) {
      return getStatus(coughWeeklyTrend.data);
    }

    if (activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY && coughMonthlyTrend.success) {
      return getStatus(coughMonthlyTrend.data);
    }

    return {
      status: {
        color: "#71B912",
      },
    };
  }, [activeTab, coughTodayTrend, coughWeeklyTrend, coughMonthlyTrend]);

  const formatTimeLabels = (times: Dayjs[], view: TODAY_VIEW_MODE) => {
    return times.map((t, idx) => {
      switch (view) {
        default:
        case TODAY_VIEW_MODE.VIEW_DAILY:
          if (idx === times.length - 1) return "Today";
          return dayjs(t).format("DD MMM");
        case TODAY_VIEW_MODE.VIEW_WEEKLY:
          if (idx === times.length - 1) return "This Week";
          return [`${dayjs(t).format("DD MMM")}-`, `${dayjs(t).add(7, "day").format("DD MMM YYYY")}`];
        case TODAY_VIEW_MODE.VIEW_MONTHLY:
          if (idx === times.length - 1) return "This Month";
          return dayjs(t).format("MMM YYYY");
      }
    });
  };

  const coughRateData = useMemo(() => {
    if (data) {
      return {
        valueList: data.map((d) => (d.data[MetricsEnum.COUGH]?.count || 0) / (d.time_tracked_s / (60 * 60))),
        labelList: formatTimeLabels(
          data.map((d) => dayjs(d.time)),
          activeTab as TODAY_VIEW_MODE
        ),
      };
    } else {
      return {
        valueList: [0, 0, 0, 0],
        labelList: ["", "", "", ""],
      };
    }
  }, [data, activeTab]);

  const height = "40vh";

  // Solves the issue of the page not scrolling to the top when navigating to this page
  useEffect(() => {
    window.scrollY >= 0 && window.scrollTo(0, 0);
  }, []);

  // render
  return (
    <div className="trend">
      <div className="trend-content">
        <Tabs
          tabList={[
            {
              value: TODAY_VIEW_MODE.VIEW_DAILY,
              label: "Daily",
            },
            {
              value: TODAY_VIEW_MODE.VIEW_WEEKLY,
              label: "Weekly",
            },
            {
              value: TODAY_VIEW_MODE.VIEW_MONTHLY,
              label: "Monthly",
            },
          ]}
          value={activeTab}
          onChange={setActiveTab}
        />
        <div className="trend-content-container">
          <div className="trend-content-section">
            {trendValue.success && (
              <CoughWidget title="COUGH TREND" data={trendValue.data || EMPTY_COUGH_TREND} description={""} />
            )}
            {!trendValue.success && (
              <CoughEmpty
                title={`${trendConfig?.label}`}
                label="Keep tracking!"
                description="Insufficient data: you haven’t tracked for long enough to reveal your cough trend"
              />
            )}
          </div>

          <StepBarChart
            loading={isLoading || isFetching}
            valueData={coughRateData.valueList}
            labelData={coughRateData.labelList}
            height={height}
            activeColor={activeColor?.status?.color ?? "#EFEFEF"}
            paintActiveColor={true}
          />
        </div>
      </div>
      <div className="trend-footer">
        {/* <NoteCard
          title="Here is a tip for you!"
          desc="This is a tip that may help you to learn more about the cough..."
          icon={IconRate}
        /> */}
      </div>
    </div>
  );
};

export default Trend;
