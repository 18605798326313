import axios, { AxiosResponse } from "axios";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";

type SubscriptionPrices = {
  lookup_key: string;
  interval: string;
  interval_count: number;
  price: number;
  currency: string;
};

export interface SubscriptionOptionsResponse {
  prices: SubscriptionPrices[];
  trial_periods: number[];
}

export const getSubscriptionOptions = async () => {
  const resp = await axios.get<any, AxiosResponse<SubscriptionOptionsResponse>>(
    `${process.env.REACT_APP_PAYMENTS_API_URL as string}/subscription-options`
  );
  return resp.data;
};

export const startCheckoutSession = async (lookup_key: string, trial_days: number) => {
  const resp = await axios.post<any, AxiosResponse<{ stripe_checkout_url: string }>>(
    `${process.env.REACT_APP_PAYMENTS_API_URL as string}/create-checkout-session`,
    {
      lookup_key: lookup_key,
      trial_period: trial_days,
    }
  );
  window.location.href = resp.data.stripe_checkout_url;
};

type StartCustomerPortalSessionResp = {
  stripe_customer_portal_url: string;
  status: "error" | "OK";
  error_code?: "no_such_customer";
};

export const startCustomerPortalSession = async () => {
  try {
    const resp = await axios.post<any, AxiosResponse<StartCustomerPortalSessionResp>>(
      `${process.env.REACT_APP_PAYMENTS_API_URL as string}/create-customer-portal-session`
    );
    if (resp.data.status === "OK") window.location.href = resp.data.stripe_customer_portal_url;
  } catch (error: any) {
    const resp = error.response as AxiosResponse<StartCustomerPortalSessionResp>;
    if (resp.data.error_code === "no_such_customer") {
      window.alert(
        "This user does not have an active Stripe subscription.\nPlease manage your subscription directly from the app."
      );
    }
  }
};

interface StripeCheckoutDetails {
  amount_total: number | null;
  currency: string | null;
  id: string;
  object: string;
}
export const useStripeCheckoutDetails = (session_id: string | null) => {
  const [stripeCheckoutDetails, setStripeCheckoutDetails] = useState<StripeCheckoutDetails | null>(null);

  useEffect(() => {
    const auth = getAuth();
    if (session_id && auth.currentUser?.uid) {
      return onSnapshot(doc(firestore, `/user_sounds/${auth.currentUser.uid}/stripe_checkout/${session_id}`), (doc) => {
        if (doc.exists()) {
          setStripeCheckoutDetails(doc.data() as StripeCheckoutDetails);
        } else {
          setStripeCheckoutDetails(null);
        }
      });
    } else {
      setStripeCheckoutDetails(null);
    }
  }, [session_id]);

  return { stripeCheckoutDetails };
};

export const startTrialPeriod = async () => {
  await axios.post((process.env.REACT_APP_PAYMENTS_API_URL as string) + "/start-subscriber-trial");
  const auth = getAuth();
  await setDoc(
    doc(firestore, `/user_sounds/${auth.currentUser?.uid}`),
    { trial_start_date: new Date() },
    { merge: true }
  );
};
