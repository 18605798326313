import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { PolarArea } from "react-chartjs-2";

import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

type PolarAreaChartProps = {
  valueData: number[];
  colorData: string[];
  displayLabelData: string[];
  circleLabelData: string[];
  skip: number;
  yAxiPosition: string;
  height: string;
  ref: any;
};

interface PolarAreaChartRef {
  toBase64Image: () => void;
}

const PolarAreaChart = forwardRef<PolarAreaChartRef, PolarAreaChartProps>(
  (
    {
      valueData,
      colorData,
      circleLabelData,
      displayLabelData,
      skip,
      yAxiPosition,
      height,
    },
    ref
  ) => {
    const chartRef = useRef<any>(null);
    const options = {
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context: any) {
              return context.parsed.r.toFixed(1) + " coughs/h";
            },
            title: function (context: any) {
              return displayLabelData[context[0].dataIndex];
            },
          },
        },
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        r: {
          pointLabels: {
            display: true,
            ticks: {
              callback: (value: string, index: number) => {
                return index % skip === 0 ? circleLabelData[index] : "";
              },
            },
            // font: responsiveFont("medium", {
            //   family: "Poppins",
            // }),
            // color: 'ColorScheme[mode].text',
          },
          ticks: {
            display: false,
            // backdropColor: "rgba(0,0,0,0)",
            // color: "rgba(0,0,0,0)",
          },
          grid: {
            // tickColor: ColorScheme[mode].text,
            // color: ColorScheme[mode].text,
            // lineWidth: 0.5,
            // display: false,
          },
        },
      },
      // scales: {
      //   x: {
      //     ticks: {
      //       callback: (value: string, index: number) => {
      //         return index % skip === 0 ? labelData[index] : "";
      //       },
      //     },
      //   },
      //   y: {
      //     position: yAxiPosition,
      //     gridLines: {
      //       borderDash: [8, 4],
      //       color: "rgba(0, 0, 0, 0.1)",
      //     },
      //   },
      // } as any,
    };

    const data = {
      labels: circleLabelData, // displayLabelData,
      datasets: [
        {
          label: "",
          data: valueData,
          backgroundColor: colorData,
        },
      ] as any,
    };

    useImperativeHandle(ref, () => ({
      toBase64Image: () => {
        return chartRef.current?.toBase64Image();
      },
    }));

    return (
      <div>
        <PolarArea ref={chartRef} options={options} data={data} />
      </div>
    );
  }
);
export default PolarAreaChart;
