import React from "react";
import "./style.scss";

type NoteCardProps = {
  title: string;
  desc: string;
  icon: any;
  alt?: string;
};

const NoteCard: React.FC<NoteCardProps> = ({ title, desc, icon, alt = "" }) => {
  return (
    <div className="note">
      <div className="note-left">
        <img src={icon} alt={alt} />
      </div>
      <div className="note-right">
        <div className="note-right-title">{title}</div>
        <div className="note-right-desc">{desc}</div>
      </div>
    </div>
  );
};

export default NoteCard;
