/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SliderExtras = {
    subtype: SliderExtras.subtype;
    min: number;
    max: number;
    step: number;
};
export namespace SliderExtras {
    export enum subtype {
        SLIDER = 'slider',
    }
}

