import "./App.scss";
import "antd/dist/reset.css";
import Layout from "./layout";
import { useEffect, useState } from "react";
import { Console, Hook, Unhook } from "console-feed";
import { useRecoilState, useRecoilValue } from "recoil";
import { AppState } from "./state/global";
import { LogState } from "./state/logs";

import { useFirebaseAuthConnector } from "./state/auth";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient = new QueryClient();
function App() {
  const globalState = useRecoilValue(AppState);
  const [logs, setLogs] = useRecoilState(LogState);

  useFirebaseAuthConnector();

  useEffect(() => {
    if (!globalState.isDevMode) return;
    const hookedConsole = Hook(window.console, (log) => setLogs((logs) => [...logs, log]), false);
    return () => {
      Unhook(hookedConsole);
    };
  }, [globalState.isDevMode, logs, setLogs]);

  const [debugVisible, setDebugVisible] = useState(false);
  const displayDebug = !debugVisible ? "none" : "";

  return (
    <div className="app-main">
      <QueryClientProvider client={queryClient}>
        {globalState.isDevMode && (
          <div className="app-watermark">
            <a className="app-watermark-text" style={{ paddingRight: "4px" }} href="https://discourse.dev.api.hyfe.ai/">
              forum
            </a>
            <span
              className="app-watermark-text"
              onClick={() => setDebugVisible(!debugVisible)}
            >{`DEV-${globalState.currentVersion}`}</span>
          </div>
        )}
        <div className="app-debug" style={{ display: displayDebug }}>
          <Console logs={logs} variant="dark" />
        </div>
        <Layout />
        <span className="app-version">{`${globalState.buildEnvironment} ${globalState.currentVersion}`}</span>
      </QueryClientProvider>
    </div>
  );
}

export default App;
