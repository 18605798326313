/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { AdminService } from './services/AdminService';
import { DevicesService } from './services/DevicesService';
import { EventsService } from './services/EventsService';
import { GroupsService } from './services/GroupsService';
import { InsightsService } from './services/InsightsService';
import { SurveysService } from './services/SurveysService';
import { UserService } from './services/UserService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class Insights {
    public readonly admin: AdminService;
    public readonly devices: DevicesService;
    public readonly events: EventsService;
    public readonly groups: GroupsService;
    public readonly insights: InsightsService;
    public readonly surveys: SurveysService;
    public readonly user: UserService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'https://v2.api.hyfe.ai/jwt',
            VERSION: config?.VERSION ?? '2.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.admin = new AdminService(this.request);
        this.devices = new DevicesService(this.request);
        this.events = new EventsService(this.request);
        this.groups = new GroupsService(this.request);
        this.insights = new InsightsService(this.request);
        this.surveys = new SurveysService(this.request);
        this.user = new UserService(this.request);
    }
}

