import { generateDonutGradient, generateGradient } from "../../../utils/misc";

const TRANSPARENT = "transparent";
const DAY = "#DB9B1D"; // custom, sunny-day ;)
const NIGHT = "#244598"; // primary-800
const ERROR_300 = "#EDA2AB"; // error-300
const ERROR_700 = "#DB4456"; // error-700
const ERROR_900 = "#952E3A"; // error-900
const NEUTRAL_0 = "#FFFFFF"; // neutral-0
const NEUTRAL_400 = "#A1A5B1"; // neutral-400
const PRIMARY_50 = "#EFF3FE"; // primary-50
const PRIMARY_900 = "#1D3779"; // primary-900

const dayCycleGradientColors = (totalColorSections: number) => [
  ...generateDonutGradient(
    PRIMARY_900,
    "#244598", // primary-800
    totalColorSections,
    36
  ),
  ...generateDonutGradient(
    "#244598", // primary-800
    "#F2D7AB",
    totalColorSections,
    27
  ),
  ...generateDonutGradient("#F2D7AB", "#FFE9BD", totalColorSections, 37),
  ...generateDonutGradient("#FFE9BD", "#F2D7AB", totalColorSections, 37),
  ...generateDonutGradient(
    "#F2D7AB",
    "#244598", // primary-800
    totalColorSections,
    27
  ),
  ...generateDonutGradient(
    "#244598", // primary-800
    PRIMARY_900,
    totalColorSections,
    36
  ),
];

const getClockReferenceColors = (
  currentEventLevel: number,
  maxEventLevel: number,
  rangeCount: number,
  gradientLowestColor: string = PRIMARY_50,
  gradientHighestColor: string = PRIMARY_900
): string => {
  if (currentEventLevel === 0) return NEUTRAL_0;
  const rangeSize = Math.ceil(maxEventLevel / rangeCount);
  const index = Math.min(Math.floor(currentEventLevel / rangeSize), rangeCount - 1);
  return generateGradient(gradientLowestColor, gradientHighestColor, rangeCount, true)[index];
};

export const colors = {
  generateGradient,
  dayCycleGradientColors,
  getClockReferenceColors,
  TRANSPARENT,
  DAY,
  NIGHT,
  ERROR_300,
  ERROR_700,
  ERROR_900,
  NEUTRAL_400,
  NEUTRAL_0,
  PRIMARY_50,
  PRIMARY_900,
};
