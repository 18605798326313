import { differenceInDays, subDays, subWeeks, subMonths, getDaysInMonth, startOfYear, addMonths } from "date-fns";
import { getMonthLabel, getShortMonthLabel, getWeeksLabel } from "./date-operation";
import { DataType } from "../models/event";

type DateListData = {
  totalTime: number;
  totalCount: number;
  valueData: any[];
  labelData: any[];
};

const getWeeklyData = (coughData: any, weekNum: number, startTime: Date) => {
  const labelData = Array(weekNum).fill("");

  const valueData: any = Array(weekNum)
    .fill(0)
    .map((item) => ({
      count: 0,
      time_tracked_s: 0,
      progress: 0,
      rate: 0,
      future: false,
    }));
  let totalTime = 0,
    totalCount = 0;

  for (let index = 0; index < coughData.length; index++) {
    const dayDiff = differenceInDays(new Date(coughData[index].time), subWeeks(startTime, weekNum));

    const weekDiff = Math.floor(dayDiff < 0 ? dayDiff * -1 : dayDiff / 7);

    if (weekDiff >= 0 && weekDiff < weekNum) {
      valueData[weekDiff].count = valueData[weekDiff].count + coughData[index].count;
      valueData[weekDiff].time_tracked_s = valueData[weekDiff].time_tracked_s + coughData[index].time_tracked_s;

      totalTime = totalTime + coughData[index].time_tracked_s;
      totalCount = totalCount + coughData[index].count;
    }
  }

  for (let index = 0; index < weekNum; index++) {
    labelData[index] = getWeeksLabel(startTime, weekNum - index - 1);
    valueData[index].progress = (valueData[index].time_tracked_s * 100) / (24 * 7 * 3600);
    valueData[index].rate =
      valueData[index].time_tracked_s > 0
        ? ((valueData[index].count / valueData[index].time_tracked_s) * 3600).toFixed(1)
        : 0;
  }

  return {
    totalTime,
    totalCount,
    valueData,
    labelData,
  } as DateListData;
};

const getMonthlyData = (coughData: any, monthNum: number, startTime: Date) => {
  const labelData = Array(monthNum).fill("");

  const valueData: any = Array(monthNum)
    .fill(0)
    .map((item) => ({
      count: 0,
      time_tracked_s: 0,
      progress: 0,
      rate: 0,
    }));

  let totalTime = 0,
    totalCount = 0;

  for (let index = 0; index < coughData.length; index++) {
    const dayDiff = differenceInDays(
      new Date(coughData[index].time),
      subDays(startTime, getDaysInMonth(subMonths(startTime, 1)))
    );

    const monthDiff = Math.floor((dayDiff * (dayDiff < 0 ? dayDiff * -1 : dayDiff)) / 30);

    if (monthDiff >= 0 && monthDiff < monthNum) {
      valueData[monthDiff].count = valueData[monthDiff].count + coughData[index].count;
      valueData[monthDiff].time_tracked_s = valueData[monthDiff].time_tracked_s + coughData[index].time_tracked_s;

      totalTime = totalTime + coughData[index].time_tracked_s;
      totalCount = totalCount + coughData[index].count;
    }
  }

  for (let index = 0; index < monthNum; index++) {
    labelData[index] = getMonthLabel(startTime, monthNum - index - 1);
    valueData[index].progress = (valueData[index].time_tracked_s * 100) / (24 * 30 * 3600);
    valueData[index].rate =
      valueData[index].time_tracked_s > 0
        ? ((valueData[index].count / valueData[index].time_tracked_s) * 3600).toFixed(1)
        : DataType.NOT_TRACK;
    valueData[index].future = startTime > new Date();
  }

  return {
    totalTime,
    totalCount,
    valueData,
    labelData,
  } as DateListData;
};

const getYearlyData = (coughData: any) => {
  let totalTime = 0,
    totalCount = 0;
  const labelData = Array(12)
    .fill("")
    .map((item, index) => getShortMonthLabel(addMonths(startOfYear(new Date()), index)));
  const valueData: any = Array(12)
    .fill(0)
    .map((item, index) => ({
      count: 0,
      time_tracked_s: 0,
      progress: 0,
      rate: 0,
      future: index > new Date().getMonth(),
    }));
  for (let i = 0; i < coughData.length; i++) {
    const currentDate = new Date(coughData[i].time);
    const currentMonth = currentDate.getMonth();
    valueData[currentMonth].count = coughData[i].count;
    valueData[currentMonth].time_tracked_s = coughData[i].time_tracked_s;
    valueData[currentMonth].progress =
      (valueData[currentMonth].time_tracked_s * 100) / (24 * getDaysInMonth(currentDate) * 3600);
    valueData[currentMonth].rate =
      valueData[currentMonth].time_tracked_s > 0
        ? ((valueData[currentMonth].count / valueData[currentMonth].time_tracked_s) * 3600).toFixed(1)
        : DataType.NOT_TRACK;
    totalTime = totalTime + coughData[i].time_tracked_s;
    totalCount = totalCount + coughData[i].count;
  }
  return {
    totalTime,
    totalCount,
    valueData,
    labelData,
  } as DateListData;
};

export { getWeeklyData, getMonthlyData, getYearlyData };
