import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { isDesktopVersion } from "../../utils/misc";

export const useFbPixel = () => {
  useEffect(() => {
    if (isDesktopVersion() && process.env.REACT_APP_FB_PIXEL_ID) {
      ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID as string);
    }
  }, []);

  return isDesktopVersion() && process.env.REACT_APP_FB_PIXEL_ID ? ReactPixel : null;
};
