import React from "react";
import "./style.scss";

type LabelItem = {
  label: string;
  value: string;
  unit: string;
};

type CoughLabelProps = {
  right: LabelItem;
  left: LabelItem;
  status?: {
    error: boolean;
    msg: string;
  };
  options?: {
    addShadow?: boolean;
    flexSpaceBetween?: boolean;
  };
};

const CoughLabel: React.FC<CoughLabelProps> = ({
  right,
  left,
  status = { error: false, msg: "" },
  options = {
    addShadow: true,
    flexSpaceBetween: false,
  },
}) => {
  return (
    <div
      className={`cough_label ${
        options?.addShadow ? "cough_label-shadow" : ""
      }`}
    >
      <div
        className={`${
          status?.error
            ? "cough_label-wrapper error-wrapper"
            : "cough_label-wrapper"
        } ${options?.flexSpaceBetween ? "flex-space-between" : ""}`}
      >
        <div className="cough_label-wrapper-item">
          <h4 className="cough_label-wrapper-item-label">{right.label}</h4>
          <div className="cough_label-wrapper-item-container">
            <p className="cough_label-wrapper-item-container-value">
              {right.value}
            </p>
            <p className="cough_label-wrapper-item-container-unit">
              {right.unit}
            </p>
          </div>
        </div>
        <div className="cough_label-wrapper-item">
          <h4 className="cough_label-wrapper-item-label">{left.label}</h4>
          <div className="cough_label-wrapper-item-container">
            <p className="cough_label-wrapper-item-container-value">
              {left.value}
            </p>
            <p className="cough_label-wrapper-item-container-unit">
              {left.unit}
            </p>
          </div>
        </div>
      </div>
      {status?.error && <div className="cough_label-error">{status.msg}</div>}
    </div>
  );
};

export default CoughLabel;
