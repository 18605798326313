import React from "react";
import NoteCard from "../note-card";
import "./style.scss";
import IconClock from "../../assets/icons/icon_clock.svg";
import IconNote from "../../assets/icons/icon_note.svg";
import IconRate from "../../assets/icons/icon_rate.svg";

type GuestViewProps = {};

const GuestView: React.FC<GuestViewProps> = ({ ...props }) => {
  return (
    <div className="guest_view">
      <div className="guest_view-title">Want to learn more?</div>
      <div className="guest_view-desc">
        Enjoy the full experience and access the insights after sign in!
      </div>
      <NoteCard
        title="How often do I cough?"
        desc="How many times per hour do you think you cough? Get your cough rate and find out!"
        icon={IconClock}
      />
      <NoteCard
        title="Is my cough getting better or worse?"
        desc="See how your cough trend changes compared to previous days, weeks, and months"
        icon={IconRate}
      />
      <NoteCard
        title="My cough report"
        desc="One document that will help you understand your cough patterns over time"
        icon={IconNote}
      />
      <div className="guest_view-last"></div>
    </div>
  );
};

export default GuestView;
