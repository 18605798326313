import React from "react";
import { Bar } from "react-chartjs-2";
// import { ScaleOptionsByType } from "chart.js";

import { Chart as ChartJS, CategoryScale, BarElement, Title, Tooltip, Legend, LinearScale } from "chart.js";
import type { ChartOptions } from "chart.js";
import "./style.scss";
import { TODAY_VIEW_MODE } from "../../models/event";
import Loading from "../loading";
import { useRecoilValue } from "recoil";
import { AppState } from "../../state/global";
import DatePicker from "../date-picker";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type CoughTodayProps = {
  count: number;
  title: string;
  labelDate: string;
  date: Date;
  coughData: number[];
  labelData: string[];
  viewMode?: string | TODAY_VIEW_MODE;
  loading?: boolean;
  datePicker?: boolean;
  datePickerPrependText?: string;
  datePickerCallback?: Function;
  height?: string;
};

const CoughToday: React.FC<CoughTodayProps> = ({
  title,
  count,
  labelDate,
  date,
  coughData,
  labelData,
  viewMode,
  loading = false,
  datePicker = false,
  datePickerPrependText = "",
  datePickerCallback = undefined,
  height = undefined,
}) => {
  const globalState = useRecoilValue(AppState);
  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "",
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          drawBorder: true,
          lineWidth: 1,
          // color: "rgba(0, 0, 0, 0.1)",
        },
        border: {
          color: "rgba(0, 0, 0, 0.1)",
          dash: [2, 4],
        },
        ticks: {
          autoSkip: false,
          maxTicksLimit: 10,
          maxRotation: 0,
          minRotation: 0,
          callback: (value: string, index: number) => {
            if (viewMode === TODAY_VIEW_MODE.VIEW_HOURLY) {
              return labelData[index].split(":")[1] === "00" ? labelData[index] : "";
            }
            if (viewMode === TODAY_VIEW_MODE.VIEW_DAILY) {
              return Number(labelData[index].split(":")[0]) % 3 === 0 ? labelData[index] : "";
            }
            if (viewMode === TODAY_VIEW_MODE.VIEW_WEEKLY) {
              // nothing to do?
            }
            if (viewMode === TODAY_VIEW_MODE.VIEW_MONTHLY) {
              return Number(labelData[index].split(" ")[1]) % 5 === 0 ? labelData[index] : "";
            }
            return labelData[index];
          },
        },
      },
      y: {
        // display: false,
        position: "right",
        border: {
          display: true,
        },
        suggestedMax: 10,
        ticks: {
          // suggestedMin: 2,
          precision: 0,
          callback: function (value: number) {
            return value.toFixed(0);
          },
        },
      },
    } as any,
  };

  const data = {
    labels: labelData,
    datasets: [
      {
        label: "",
        data: coughData,
        backgroundColor: "rgba(57, 109, 241, 1)",
        borderRadius: {
          topLeft: 3,
          topRight: 3,
        },
      },
    ],
  };

  // render
  return (
    <div className="cough_today">
      <div className="cough_today-header">
        <div className="cough_today-header-title">{loading ? "Loading..." : title}</div>
        <div className="cough_today-header-main">
          <div className={count === 0 ? "cough_today-header-main-count-disabled" : "cough_today-header-main-count"}>
            {loading ? "-" : globalState.i18n.currentCurrencyFormat.format(count).toString()}
          </div>
          <div className="cough_today-header-main-date">
            {loading ? (
              "-"
            ) : datePicker ? (
              <DatePicker
                onSelectDate={datePickerCallback}
                date={date}
                viewMode={viewMode}
                prependText={`${datePickerPrependText}`}
                titleFontSize={16}
              />
            ) : (
              labelDate
            )}
          </div>
        </div>
      </div>
      <div
        className="cough_today-container"
        style={{
          position: "relative",
          height,
          // "Hack" to make the plot fully responsive (when shrinking too)
          // The chart takes the size from the div and resizes when the div does
          // However when shrinking the div will not shrink beyond the size of it's content (the chart)
          // thus no triggering the re-render of the chart to make it smaller.
          // Setting a grid display to minmax(0,1fr) will prevent it.
          display: "grid",
          gridTemplateColumns: "minmax(0,1fr)",
          gridTemplateRows: "minmax(0,1fr)",
        }}
      >
        {loading && <Loading loading={loading} contained />}
        <Bar options={options} data={data} height={"300px"} />
      </div>
    </div>
  );
};

export default CoughToday;
