/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AggregationsEnum } from '../models/AggregationsEnum';
import type { GetAggregatedEvents_200 } from '../models/GetAggregatedEvents_200';
import type { GetRawEvents_200 } from '../models/GetRawEvents_200';
import type { MetricsEnum } from '../models/MetricsEnum';
import type { PostEvents_200 } from '../models/PostEvents_200';
import type { PostEventsEventBody } from '../models/PostEventsEventBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EventsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get raw events
     * Get events for a user
     * @param customerId Id of the tenant
     * @param uid Id of the user
     * @param limit Pagination. Max number of entries returned.
     * @param offset Pagination. Offset.
     * @param deviceId Id of the device to use, if omitted calculated across all devices.
     * @param metric Metric fetched
     * @param startDate Start time, timestamp with tz
     * @param endDate End time, timestamp with tz
     * @param direction Ascending or descending in time
     * @param fields List fields to return
     * @returns GetRawEvents_200 Successful response
     * @throws ApiError
     */
    public getUsersEvents(
        customerId: string,
        uid: string,
        limit: number = 100,
        offset?: number,
        deviceId?: string | null,
        metric?: MetricsEnum,
        startDate?: string | null,
        endDate?: string | null,
        direction: 'asc' | 'desc' = 'desc',
        fields?: Array<'uid' | 'device_id' | 'metric' | 'time' | 'utc_time' | 'sensor' | 'value1' | 'value2'>,
    ): CancelablePromise<GetRawEvents_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/users/{uid}/events',
            path: {
                'customer_id': customerId,
                'uid': uid,
            },
            query: {
                'limit': limit,
                'offset': offset,
                'device_id': deviceId,
                'metric': metric,
                'start_date': startDate,
                'end_date': endDate,
                'direction': direction,
                'fields': fields,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Add events
     * Add new events for a user
     * @param customerId Id of the tenant
     * @param uid Id of the user
     * @param requestBody
     * @returns PostEvents_200
     * @throws ApiError
     */
    public postUsersEvents(
        customerId: string,
        uid: string,
        requestBody: PostEventsEventBody,
    ): CancelablePromise<PostEvents_200> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/{customer_id}/users/{uid}/events',
            path: {
                'customer_id': customerId,
                'uid': uid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get aggregated events
     * Get aggregated events for a user
     * @param customerId Id of the tenant
     * @param uid Id of the user
     * @param limit Pagination. Max number of entries returned.
     * @param offset Pagination. Offset.
     * @param deviceId Id of the device to use, if omitted calculated across all devices.
     * @param aggregation Aggregation bucket size
     * @param startDate Start time, timestamp with tz
     * @param endDate End time, timestamp with tz
     * @param direction Ascending or descending in time
     * @param omitEmptyBuckets Omit empty buckets
     * @param fields List fields to return
     * @param metrics Metric(s) fetched
     * @returns GetAggregatedEvents_200 Successful response
     * @throws ApiError
     */
    public getUsersEventsAggregated(
        customerId: string,
        uid: string,
        limit: number = 100,
        offset?: number,
        deviceId?: string | null,
        aggregation?: AggregationsEnum,
        startDate?: string | null,
        endDate?: string | null,
        direction: 'asc' | 'desc' = 'desc',
        omitEmptyBuckets: boolean | null = false,
        fields?: Array<'device_id' | 'metric' | 'count' | 'min1' | 'max1' | 'avg1' | 'sum1' | 'stddev1' | 'min2' | 'max2' | 'avg2' | 'sum2' | 'stddev2'>,
        metrics?: Array<MetricsEnum>,
    ): CancelablePromise<GetAggregatedEvents_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/users/{uid}/events/aggregated',
            path: {
                'customer_id': customerId,
                'uid': uid,
            },
            query: {
                'limit': limit,
                'offset': offset,
                'device_id': deviceId,
                'aggregation': aggregation,
                'start_date': startDate,
                'end_date': endDate,
                'direction': direction,
                'omit_empty_buckets': omitEmptyBuckets,
                'fields': fields,
                'metrics': metrics,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Get raw events (group)
     * Get events for a user in a group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param uid Id of the user
     * @param limit Pagination. Max number of entries returned.
     * @param offset Pagination. Offset.
     * @param deviceId Id of the device to use, if omitted calculated across all devices.
     * @param metric Metric fetched
     * @param startDate Start time, timestamp with tz
     * @param endDate End time, timestamp with tz
     * @param direction Ascending or descending in time
     * @param fields List fields to return
     * @returns GetRawEvents_200 Successful response
     * @throws ApiError
     */
    public getGroupsUsersEvents(
        customerId: string,
        groupId: string,
        uid: string,
        limit: number = 100,
        offset?: number,
        deviceId?: string | null,
        metric?: MetricsEnum,
        startDate?: string | null,
        endDate?: string | null,
        direction: 'asc' | 'desc' = 'desc',
        fields?: Array<'uid' | 'device_id' | 'metric' | 'time' | 'utc_time' | 'sensor' | 'value1' | 'value2'>,
    ): CancelablePromise<GetRawEvents_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/groups/{group_id}/users/{uid}/events',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
                'uid': uid,
            },
            query: {
                'limit': limit,
                'offset': offset,
                'device_id': deviceId,
                'metric': metric,
                'start_date': startDate,
                'end_date': endDate,
                'direction': direction,
                'fields': fields,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Add events (group)
     * Add new events for a user in a group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param uid Id of the user
     * @param requestBody
     * @returns PostEvents_200
     * @throws ApiError
     */
    public postGroupsUsersEvents(
        customerId: string,
        groupId: string,
        uid: string,
        requestBody: PostEventsEventBody,
    ): CancelablePromise<PostEvents_200> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/{customer_id}/groups/{group_id}/users/{uid}/events',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
                'uid': uid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get aggregated events (group)
     * Get aggregated events for a user in a group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param uid Id of the user
     * @param limit Pagination. Max number of entries returned.
     * @param offset Pagination. Offset.
     * @param deviceId Id of the device to use, if omitted calculated across all devices.
     * @param aggregation Aggregation bucket size
     * @param startDate Start time, timestamp with tz
     * @param endDate End time, timestamp with tz
     * @param direction Ascending or descending in time
     * @param omitEmptyBuckets Omit empty buckets
     * @param fields List fields to return
     * @param metrics Metric(s) fetched
     * @returns GetAggregatedEvents_200 Successful response
     * @throws ApiError
     */
    public getGroupsUsersEventsAggregated(
        customerId: string,
        groupId: string,
        uid: string,
        limit: number = 100,
        offset?: number,
        deviceId?: string | null,
        aggregation?: AggregationsEnum,
        startDate?: string | null,
        endDate?: string | null,
        direction: 'asc' | 'desc' = 'desc',
        omitEmptyBuckets: boolean | null = false,
        fields?: Array<'device_id' | 'metric' | 'count' | 'min1' | 'max1' | 'avg1' | 'sum1' | 'stddev1' | 'min2' | 'max2' | 'avg2' | 'sum2' | 'stddev2'>,
        metrics?: Array<MetricsEnum>,
    ): CancelablePromise<GetAggregatedEvents_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/groups/{group_id}/users/{uid}/events/aggregated',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
                'uid': uid,
            },
            query: {
                'limit': limit,
                'offset': offset,
                'device_id': deviceId,
                'aggregation': aggregation,
                'start_date': startDate,
                'end_date': endDate,
                'direction': direction,
                'omit_empty_buckets': omitEmptyBuckets,
                'fields': fields,
                'metrics': metrics,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
}
