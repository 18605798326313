import { atom } from "recoil";

interface I18n {
  currentLocale: string;
  currentCurrency: string;
  currentCurrencyFormat: Intl.NumberFormat;
}

interface IGlobalState {
  currentDate: Date;
  currentVersion: string | undefined;
  buildEnvironment: string | undefined;
  isDevMode: boolean | undefined;
  i18n: I18n;
}

export const AppState = atom({
  key: "Globals.AppState",
  default: {
    currentVersion: process.env.REACT_APP_VERSION,
    buildEnvironment: process.env.REACT_APP_BUILD_ENV,
    isDevMode: process.env.REACT_APP_BUILD_ENV === "development",
    i18n: {
      currentLocale: navigator.language || "en-US",
      currentCurrency: "USD",
      currentCurrencyFormat: new Intl.NumberFormat(navigator.language || "en-US"),
    },
  } as IGlobalState,
});
