/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetUser_200 } from '../models/GetUser_200';
import type { GetUserStats_200 } from '../models/GetUserStats_200';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get user
     * Get user
     * @param customerId Id of the tenant
     * @param uid Id of the user
     * @returns GetUser_200 Successful response
     * @throws ApiError
     */
    public getUsers(
        customerId: string,
        uid: string,
    ): CancelablePromise<GetUser_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/users/{uid}',
            path: {
                'customer_id': customerId,
                'uid': uid,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Get user stats
     * Get stats for a user
     * @param customerId Id of the tenant
     * @param uid Id of the user
     * @returns GetUserStats_200 Successful response
     * @throws ApiError
     */
    public getUsersStats(
        customerId: string,
        uid: string,
    ): CancelablePromise<GetUserStats_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/users/{uid}/stats',
            path: {
                'customer_id': customerId,
                'uid': uid,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Get user (group)
     * Get user belonging to a group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param uid Id of the user
     * @returns GetUser_200 Successful response
     * @throws ApiError
     */
    public getGroupsUsers(
        customerId: string,
        groupId: string,
        uid: string,
    ): CancelablePromise<GetUser_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/groups/{group_id}/users/{uid}',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
                'uid': uid,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Get user stats (group)
     * Get stats for a user in a group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param uid Id of the user
     * @returns GetUserStats_200 Successful response
     * @throws ApiError
     */
    public getGroupsUsersStats(
        customerId: string,
        groupId: string,
        uid: string,
    ): CancelablePromise<GetUserStats_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/groups/{group_id}/users/{uid}/stats',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
                'uid': uid,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
}
