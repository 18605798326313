import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LinearScale,
  TimeSeriesScale,
  TimeScale,
  // ScriptableContext,
} from "chart.js";
import type { ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";
import { TODAY_VIEW_MODE } from "../../models/event";
import Loading from "../loading";
import "./style.scss";
import "chartjs-adapter-date-fns";
import { endOfMonth, startOfMonth } from "date-fns";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeSeriesScale, TimeScale);

type BurstHistoryGraphProps = {
  date: Date;
  coughData: number[];
  labelData: Date[];
  viewMode?: string | TODAY_VIEW_MODE;
  loading?: boolean;
  height?: string;
};

const BurstHistoryGraph: React.FC<BurstHistoryGraphProps> = ({
  date,
  coughData,
  labelData,
  viewMode,
  loading = false,
}) => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    indexAxis: "x",
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        yAlign: "bottom",
        xAlign: "center",
        callbacks: {
          title: function (context: any) {
            if (context.length && context[0].dataset.data[context[0].dataIndex] === pgCough) return null;
            return context[0].label;
          },
          label: function (context: any) {
            if (context.dataset.data[context.dataIndex] === pgCough) return "did not track";
            return `Bursts: ${context.dataset.tooltip[context.dataIndex]}`;
          },
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        min: viewMode === TODAY_VIEW_MODE.VIEW_WEEKLY ? undefined : startOfMonth(date),
        max: viewMode === TODAY_VIEW_MODE.VIEW_WEEKLY ? undefined : endOfMonth(date),
        type: "timeseries",
        time: {
          unit: `${viewMode === TODAY_VIEW_MODE.VIEW_WEEKLY ? "day" : "week"}`,
          round: "day", // `${viewMode === TODAY_VIEW_MODE.VIEW_WEEKLY ? "day" : "week"}`,
          displayFormats: {
            day: "EEE",
            week: "d MMM",
          },
          tooltipFormat: "dd LLL",
        },
        grid: {
          display: true,
          drawBorder: true,
          lineWidth: 1,
          stepSize: 7,
        },
        border: {
          color: "rgba(0, 0, 0, 0.1)",
          dash: [2, 4],
        },
        ticks: {
          // autoSkip: false,
          maxRotation: 0,
        },
      },
      y: {
        position: "right",
        ticks: {
          suggestedMin: 0,
          callback: function (value: number, index: number, arr: any[]) {
            if (arr.length - 1 === index || index === 0) return value.toFixed(0);
          },
        },
      },
    } as any,
  };

  const maxCough = Math.max(...coughData);
  const pgCough = maxCough * 0.15;
  const data = {
    labels: labelData,
    datasets: [
      {
        label: "",
        data: coughData.map((item, idx) => {
          if (item === 0) return maxCough;
          if (labelData[idx].getTime() > new Date().getTime()) return 0;
          if (
            (labelData[idx].getTime() < new Date().getTime() && item <= 0) ||
            (viewMode === TODAY_VIEW_MODE.VIEW_MONTHLY && (item === 0 || item === undefined))
          )
            return pgCough;
          return item;
        }),
        hoverBackgroundColor: coughData.map((item, idx) => (item === 0 ? "#f7f7f9" : item > 0 ? "#396df1" : "#eda2ab")),
        backgroundColor: coughData.map((item, idx) => (item === 0 ? "#f7f7f9" : item > 0 ? "#396df1" : "#eda2ab")),
        // backgroundColor: (context: ScriptableContext<"bar">) => {
        //   if (context.dataIndex === 0) return "#fff";
        //   if (context.raw === 0) {
        //     const ctx = context.chart.ctx;
        //     const gradient = ctx.createLinearGradient(0, 0, 0, 0);
        //     gradient.addColorStop(0, "rgba(237, 162, 171, 1)");
        //     gradient.addColorStop(0.5, "#fff");
        //     gradient.addColorStop(1, "rgba(237, 162, 171, 1)");
        //     // return gradient;
        //     return "#eda2ab";
        //   }
        //   return "#396df1";
        // },
        borderWidth: 0,
        tooltip: coughData.map((item) => (item >= 0 ? item : 0)),
        borderRadius: coughData.map((item) => (item > 0 ? { topLeft: 3, topRight: 3 } : 0)),
      },
    ],
  };

  // render
  return (
    <div className="burst_history_graph">
      <div className="burst_history_graph-container">
        {loading && <Loading loading={loading} contained />}
        <Bar options={options} data={data} height={"400px"} />
      </div>
    </div>
  );
};

export default BurstHistoryGraph;
