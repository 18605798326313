/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SliderExtras } from './SliderExtras';
import type { SurveyMCQuestionOptions } from './SurveyMCQuestionOptions';
export type SurveyQuestion = {
    id: string;
    /**
     * Title of the question
     */
    title: string;
    /**
     * Complementary text to the question
     */
    text: string;
    answer_type: SurveyQuestion.answer_type;
    options: Array<SurveyMCQuestionOptions> | null;
    /**
     * Optional extra details about the question
     */
    extras?: (SliderExtras | Record<string, any>) | null;
};
export namespace SurveyQuestion {
    export enum answer_type {
        NUMERIC = 'numeric',
        TEXT = 'text',
        MULTIPLE_CHOICE = 'multiple_choice',
        MULTIPLE_CHOICE_WITH_OTHER = 'multiple_choice_with_other',
    }
}

