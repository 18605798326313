/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateGroupEventBody } from '../models/CreateGroupEventBody';
import type { Default200Resp } from '../models/Default200Resp';
import type { PatchUser_200 } from '../models/PatchUser_200';
import type { PatchUserEventBody } from '../models/PatchUserEventBody';
import type { PostUser_200 } from '../models/PostUser_200';
import type { PostUserEventBody } from '../models/PostUserEventBody';
import type { UpdateGroupEventBody } from '../models/UpdateGroupEventBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AdminService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create a user
     * Create a user
     * @param customerId Id of the tenant
     * @param requestBody
     * @returns PostUser_200 Successful response
     * @throws ApiError
     */
    public postAdminUsers(
        customerId: string,
        requestBody: PostUserEventBody,
    ): CancelablePromise<PostUser_200> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/{customer_id}/admin/users',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Update a user
     * Update user details
     * @param customerId Id of the tenant
     * @param uid Id of the user
     * @param requestBody
     * @returns PatchUser_200 Successful response
     * @throws ApiError
     */
    public patchAdminUsers(
        customerId: string,
        uid: string,
        requestBody: PatchUserEventBody,
    ): CancelablePromise<PatchUser_200> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/{customer_id}/admin/users/{uid}',
            path: {
                'customer_id': customerId,
                'uid': uid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Add user to group
     * Add a user to a group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param uid Id of the user
     * @returns Default200Resp Successful response
     * @throws ApiError
     */
    public putAdminGroupsUsers(
        customerId: string,
        groupId: string,
        uid: string,
    ): CancelablePromise<Default200Resp> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/{customer_id}/admin/groups/{group_id}/users/{uid}',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
                'uid': uid,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Remove a user from a group
     * Remove a user from a group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param uid Id of the user
     * @returns Default200Resp Successful response
     * @throws ApiError
     */
    public deleteAdminGroupsUsers(
        customerId: string,
        groupId: string,
        uid: string,
    ): CancelablePromise<Default200Resp> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/{customer_id}/admin/groups/{group_id}/users/{uid}',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
                'uid': uid,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Create a group
     * Create a new user group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param requestBody
     * @returns Default200Resp Successful response
     * @throws ApiError
     */
    public postAdminGroups(
        customerId: string,
        groupId: string,
        requestBody: CreateGroupEventBody,
    ): CancelablePromise<Default200Resp> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/{customer_id}/admin/groups/{group_id}',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Update a group
     * Update a group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param requestBody
     * @returns Default200Resp Successful response
     * @throws ApiError
     */
    public patchAdminGroups(
        customerId: string,
        groupId: string,
        requestBody: UpdateGroupEventBody,
    ): CancelablePromise<Default200Resp> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/{customer_id}/admin/groups/{group_id}',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation error`,
            },
        });
    }
}
