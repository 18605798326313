/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Successful response
 */
export type GetTrend_200 = {
    /**
     * Calculated p-value from a Kruskal-Wallis test
     */
    pvalue: number;
    /**
     * Trend inclination
     */
    angle: number;
    /**
     * Trend going 'up' or 'down'
     */
    direction: GetTrend_200.direction;
};
export namespace GetTrend_200 {
    /**
     * Trend going 'up' or 'down'
     */
    export enum direction {
        UP = 'up',
        DOWN = 'down',
    }
}

