import axios from "axios";
import { getAuth } from "firebase/auth";

export type TMethod = "GET" | "POST" | "PUT" | "DELETE";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL as string,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async function (config) {
    const token = await getAuth().currentUser?.getIdToken();
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

type TApi = {
  url: string;
  method: TMethod;
  params?: any;
  data?: any;
};

const api = async <T extends Object>({ url, method, params, data }: TApi) => {
  return instance.request<T>({
    url,
    method,
    params,
    data,
  });
};

export { instance as axios, api };
