import React from "react";
import { TODAY_VIEW_MODE } from "../../models/event";
import "./style.scss";

type TabListItem = {
  value: TODAY_VIEW_MODE | string;
  label: string;
};

type TabsProps = {
  tabList: TabListItem[];
  value: string;
  onChange: (param: TODAY_VIEW_MODE | string) => void;
};

const Tabs: React.FC<TabsProps> = ({ tabList = [], value, onChange }) => {
  return (
    <div className="tab">
      {tabList.map((tabItem, idx) => {
        return (
          <div
            id={`tab-item-id-${idx}`}
            key={`tab-item-key-${idx}`}
            className={
              tabItem.value === value ? "tab-item tab-item-active" : "tab-item"
            }
            onClick={() => onChange(tabItem.value)}
          >
            {tabItem.label}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
