import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LinearScale,
} from "chart.js";
import { DataType } from "../../models/event";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

type BarChartProps = {
  valueData: number[];
  labelData: string[];
  skip: number;
  yAxiPosition: string;
  height: string;
  title: string;
  formaterStr?: string;
};

interface BarChartRef {
  toBase64Image: () => void;
}

const BarChart = forwardRef<BarChartRef, BarChartProps>(
  (
    { valueData, labelData, skip, yAxiPosition, height, title, formaterStr },
    ref
  ) => {
    const chartRef = useRef<any>(null);
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: title,
          position: "left",
        },
        datalabels: {
          color: function (context: any) {
            return context.dataset.backgroundColor;
          },
          display: function (context: any) {
            // var dataset = context.dataset;
            var value = valueData[context.dataIndex];
            if (value === DataType.NOT_ENOUGH) {
              return true;
            }
            return null;
          },
          font: {
            weight: "bold",
          },
          align: "top",
          formatter: () => formaterStr || "",
        },
      } as any,
      scales: {
        x: {
          ticks: {
            callback: (value: string, index: number) => {
              return index % skip === 0 ? labelData[index] : "";
            },
          },
        },
        y: {
          position: yAxiPosition,
          suggestedMin: 10,
          suggestedMax: 10,
          gridLines: {
            borderDash: [8, 4],
            color: "rgba(0, 0, 0, 0.1)",
          },
        },
      } as any,
    };

    const plugins = [
      {
        beforeDraw: (chartInstance: any, easing: any) => {
          const {
            ctx,
            chartArea: { top /*bottom, left, right, width, height*/ },
            scales: { x /* y */ },
          } = chartInstance;
          const unlogedData: number[] = [];
          valueData.forEach((item, index) => {
            if (item === DataType.NOT_TRACK) {
              unlogedData.push(index);
            }
          });
          ctx.fillStyle = "rgba(231, 232, 235, 1)";
          unlogedData.forEach((index) => {
            const leftX = x._gridLineItems ? x._gridLineItems[index].x1 : 0;
            const leftY = x._gridLineItems
              ? x._gridLineItems[index + 1].x1 - x._gridLineItems[index].x1
              : 0;
            ctx.fillRect(leftX, top, leftY, height);
          });
        },
      },
    ] as any;

    const data = {
      labels: labelData,
      datasets: [
        {
          label: "",
          data: valueData.map((item) => (item > 0 ? item : 0)),
          backgroundColor: "rgba(57, 109, 241, 1)",
          borderRadius: {
            topLeft: 3,
            topRight: 3,
          },
        },
      ],
    };

    useImperativeHandle(ref, () => ({
      toBase64Image: () => {
        return chartRef.current?.toBase64Image();
      },
    }));
    return (
      <div>
        <Bar
          ref={chartRef}
          options={options}
          data={data}
          height={height}
          plugins={plugins}
        />
      </div>
    );
  }
);
export default BarChart;
