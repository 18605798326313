const DEFAULT_LEVELS: any[] = [
  {
    color: "#db4456",
    backgroundColor: "#fbe9eb",
    from: 0.5109133,
    to: "-",
    angle: 30,
  },
  {
    color: "#ee8449",
    backgroundColor: "#ffeade",
    from: 0.2744849,
    to: 0.5109133,
    angle: 15,
  },
  {
    color: "#f3ac20",
    backgroundColor: "#fef5e4",
    from: -0.2744849,
    to: 0.2744849,
    angle: 0,
  },
  {
    color: "#71b912",
    backgroundColor: "#efffdb",
    from: -0.5109133,
    to: -0.2744849,
    angle: -15,
  },
  {
    color: "#328b6b",
    backgroundColor: "#e8f6f1",
    from: "-",
    to: -0.5109133,
    angle: -30,
  },
];

export { DEFAULT_LEVELS };
