import Progress from "../../components/progress";
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  startOfYear,
  subDays,
  subHours,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Loading from "../../components/loading";
import Tabs from "../../components/tabs";
import {
  TODAY_VIEW_MODE,
  CoughEventResponse,
  EVENT_AGGREGATION,
  CoughEvent,
  EVENT_LIMIT,
  DataType,
} from "../../models/event";
import { api } from "../../utils/axios";
import "./style.scss";
import {
  apiDateTimeFormat,
  endOfWeekMonday,
  getCurrentMonthLabel,
  getTime,
  getWeeksLabel,
  getWholeYearLabel,
  shortDateFormat,
  startOfWeekMonday,
} from "../../utils/date-operation";
import { getWeeklyData, getYearlyData } from "../../utils/data-operation";
import useSwipe from "../../components/useSwipe";
import DatePicker from "../../components/date-picker";
import { useRecoilValue } from "recoil";
import { CurrentDateState } from "../../state/currentDate";

const METRIC = "cough",
  DEVICE_ID = "",
  DAILY_LIMIT = 24,
  WEEKLY_LIMIT = 7,
  MONTHLY_LIMIT = 28,
  YEARLY_LIMIT = 12,
  HISTORY_DAILY_LIMIT = 7,
  HISTORY_WEEKLY_LIMIT = 7,
  HISTORY_MONTHLY_LIMIT = 7,
  HISTORY_YEARLY_LIMIT = 3;

const Rate = () => {
  const currentDate = useRecoilValue(CurrentDateState);
  const [activeTab, setActiveTab] = useState<string | TODAY_VIEW_MODE>(TODAY_VIEW_MODE.VIEW_DAILY);
  const [coughDailyList, setCoughDailyList] = useState<any>([]);
  const [coughWeeklyList, setCoughWeeklyList] = useState<any>([]);
  const [coughMonthlyList, setCoughMonthlyList] = useState<any>([]);
  const [coughYearlyList, setCoughYearlyList] = useState<any>([]);
  const [historyDailyList, setHistoryDailyList] = useState<any>([]);
  const [historyWeeklyList, setHistoryWeeklyList] = useState<any>([]);
  const [historyMonthlyList, setHistoryMonthlyList] = useState<any>([]);
  const [historyYearlyList, setHistoryYearlyList] = useState<any>([]);
  const [historicalView, setHistoricalView] = useState<boolean>(false);

  const [reportTime, setReportTime] = useState<Date>(new Date() ?? currentDate);
  const [loading, setLoading] = useState(true);

  const getCoughEvent = async (
    deviceId: any,
    aggregation: any,
    startDate: string,
    endDate: string
  ): Promise<CoughEvent[]> => {
    try {
      const result = await api<CoughEventResponse>({
        url: `/events/v2`,
        method: "GET",
        params: {
          // device_id: deviceId,
          aggregation: aggregation,
          start_date: startDate,
          end_date: endDate,
          metric: METRIC,
        },
      });
      // if(result?.data?.events)
      //   return result?.data?.events.map()
      return result?.data?.events || [];
    } catch (error) {
      console.error("rate.getCoughEvent:", error);
      return [];
    }
  };

  // Daily Data
  const handleLoadDailyData = useCallback(async () => {
    const dailyEvents = await getCoughEvent(
      DEVICE_ID,
      historicalView ? EVENT_AGGREGATION.DAILY : EVENT_AGGREGATION.HOURLY,
      apiDateTimeFormat(historicalView ? reportTime : subHours(reportTime, DAILY_LIMIT - 1)),
      apiDateTimeFormat(reportTime)
    );
    setCoughDailyList(dailyEvents);
  }, [reportTime, historicalView]);

  const handleLoadDailyHistory = useCallback(async () => {
    const dailyEvents = await getCoughEvent(
      DEVICE_ID,
      EVENT_AGGREGATION.DAILY,
      apiDateTimeFormat(historicalView ? subWeeks(reportTime, 1) : subDays(reportTime, WEEKLY_LIMIT - 1)),
      apiDateTimeFormat(subDays(reportTime, 1))
    );
    setHistoryDailyList(dailyEvents);
  }, [reportTime, historicalView]);

  // Weekly Data
  const handleLoadWeeklyData = useCallback(async () => {
    const weeklyEvents = await getCoughEvent(
      DEVICE_ID,
      EVENT_AGGREGATION.DAILY,
      apiDateTimeFormat(historicalView ? startOfWeekMonday(reportTime) : subDays(reportTime, WEEKLY_LIMIT - 1)),
      apiDateTimeFormat(reportTime)
    );
    setCoughWeeklyList(weeklyEvents);
  }, [reportTime, historicalView]);

  const handleLoadWeeklyHistory = useCallback(async () => {
    const weeklyEvents = await getCoughEvent(
      DEVICE_ID,
      EVENT_AGGREGATION.WEEKLY,
      apiDateTimeFormat(startOfWeekMonday(subWeeks(reportTime, HISTORY_WEEKLY_LIMIT - 1))),
      apiDateTimeFormat(subWeeks(reportTime, 1))
    );
    setHistoryWeeklyList(weeklyEvents);
  }, [reportTime]);

  // Monthly Data
  const handleLoadMonthlyData = useCallback(async () => {
    const monthlyEvents = await getCoughEvent(
      DEVICE_ID,
      EVENT_AGGREGATION.DAILY,
      apiDateTimeFormat(historicalView ? startOfMonth(reportTime) : subDays(reportTime, MONTHLY_LIMIT - 1)),
      apiDateTimeFormat(reportTime)
    );
    setCoughMonthlyList(monthlyEvents);
  }, [reportTime, historicalView]);

  const handleLoadMonthlyHistory = useCallback(async () => {
    const monthlyEvents = await getCoughEvent(
      DEVICE_ID,
      EVENT_AGGREGATION.MONTHLY,
      apiDateTimeFormat(startOfMonth(subMonths(reportTime, HISTORY_MONTHLY_LIMIT - 1))),
      apiDateTimeFormat(subMonths(reportTime, 0))
    );
    setHistoryMonthlyList(monthlyEvents);
  }, [reportTime]);

  // Yearly Data
  const handleLoadYearlyData = useCallback(async () => {
    const yearlyEvents = await getCoughEvent(
      DEVICE_ID,
      EVENT_AGGREGATION.MONTHLY,
      apiDateTimeFormat(
        historicalView ? startOfYear(reportTime) : subDays(reportTime, YEARLY_LIMIT * MONTHLY_LIMIT - 1)
      ),
      apiDateTimeFormat(endOfYear(reportTime))
    );
    setCoughYearlyList(yearlyEvents);
  }, [reportTime, historicalView]);

  const handleLoadYearlyHistory = useCallback(async () => {
    const yearlyEvents = await getCoughEvent(
      DEVICE_ID,
      EVENT_AGGREGATION.MONTHLY,
      apiDateTimeFormat(startOfYear(subYears(reportTime, HISTORY_YEARLY_LIMIT - 1))),
      apiDateTimeFormat(endOfYear(subYears(reportTime, 1)))
    );
    setHistoryYearlyList(yearlyEvents);
  }, [reportTime]);

  const widgetConfig = useMemo(() => {
    if (activeTab === TODAY_VIEW_MODE.VIEW_DAILY) {
      return {
        timeTitle: "time tracked",
        rateTitle: "cough rate",
        totalHours: DAILY_LIMIT,
        historyTitle: "daily cough rate history",
      };
    }

    if (activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY) {
      return {
        timeTitle: "time tracked",
        rateTitle: "cough rate",
        totalHours: 24 * WEEKLY_LIMIT,
        historyTitle: "Cough rate history",
      };
    }
    if (activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY) {
      return {
        timeTitle: "time tracked",
        rateTitle: "cough rate",
        totalHours: 24 * MONTHLY_LIMIT,
        historyTitle: "Cough rate history",
      };
    }
    if (activeTab === TODAY_VIEW_MODE.VIEW_YEARLY) {
      return {
        timeTitle: "time tracked",
        rateTitle: "cough rate",
        totalHours: 24 * MONTHLY_LIMIT * YEARLY_LIMIT,
        historyTitle: "Cough rate history",
      };
    }

    return {
      timeTitle: "time tracked in the last 24hrs",
      rateTitle: "cough rate today",
      totalHours: DAILY_LIMIT,
      historyTitle: "daily cough rate history",
    };
  }, [activeTab]);

  const todayCough = useMemo(() => {
    if (coughDailyList.length === 0) {
      return {
        totalHour: {
          hours: 0,
          minutes: 0,
        },
        coughRate: 0,
        progress: 0,
        future: false,
      };
    }

    const count = coughDailyList.reduce((sum: number, item: CoughEvent) => sum + item.count, 0);
    const time = coughDailyList.reduce((sum: number, item: CoughEvent) => sum + item.time_tracked_s, 0);

    if (time < EVENT_LIMIT.DAILY) {
      return null;
    }

    const timeData = getTime(time);
    const percent = (time * 100) / (DAILY_LIMIT * 3600);

    return {
      totalHour: {
        hours: timeData.hours,
        minutes: timeData.minutes,
      },
      coughRate: ((count / time) * 3600).toFixed(1),
      progress: percent,
    };
  }, [coughDailyList]);

  const monthCough = useMemo(() => {
    if (activeTab !== TODAY_VIEW_MODE.VIEW_MONTHLY) return {};
    const monthlyData = getWeeklyData(coughMonthlyList, 4, reportTime);
    const timeData = getTime(monthlyData.totalTime);

    return {
      totalHour: {
        hours: timeData.hours,
        minutes: timeData.hours > 0 ? -1 : timeData.minutes > 0 ? timeData.minutes : -1,
      },
      coughRate: monthlyData.totalTime > 0 ? ((monthlyData.totalCount / monthlyData.totalTime) * 3600).toFixed(1) : 0,
      progressList: monthlyData.labelData.map((labelItem, index) => ({
        label: labelItem,
        progress: monthlyData.valueData[index].progress,
        future: monthlyData.valueData[index].future,
      })),
    };
  }, [coughMonthlyList, reportTime, activeTab]);

  const yearCough = useMemo(() => {
    if (activeTab !== TODAY_VIEW_MODE.VIEW_YEARLY) return null;

    const yearlyData = getYearlyData(coughYearlyList);
    const timeData = getTime(yearlyData.totalTime);

    const y = {
      totalHour: {
        hours: timeData.hours,
        minutes: timeData.hours > 0 ? -1 : timeData.minutes > 0 ? timeData.minutes : -1,
      },
      coughRate: yearlyData.totalTime > 0 ? ((yearlyData.totalCount / yearlyData.totalTime) * 3600).toFixed(1) : 0,
      progressList: yearlyData.labelData.map((labelItem, index) => ({
        label: labelItem,
        progress: yearlyData.valueData[index].progress,
        future: yearlyData.valueData[index].future,
      })),
    };
    return y;
  }, [coughYearlyList, activeTab]);

  const weekCough = useMemo(() => {
    const progressList = Array(7)
      .fill(0)
      .map((item) => ({
        label: "",
        progress: 0,
        future: false,
      }));

    let totalTime = 0,
      totalCount = 0;
    for (let index = 0; index < WEEKLY_LIMIT; index++) {
      progressList[index].label = format(subDays(reportTime, WEEKLY_LIMIT - 1 - index), "EEE");

      const eventItem = coughWeeklyList.find(
        (item: any) => shortDateFormat(new Date(item.time)) === shortDateFormat(subDays(reportTime, 6 - index))
      );

      if (eventItem) {
        if (eventItem.time_tracked_s >= EVENT_LIMIT.DAILY) {
          totalTime = totalTime + eventItem.time_tracked_s;
          totalCount = totalCount + eventItem.count;
          progressList[index].progress = (eventItem.time_tracked_s * 100) / (24 * 3600);
          progressList[index].future = eventItem.future;
        }
      }
    }

    const timeData = getTime(totalTime);

    return {
      totalHour: {
        hours: timeData.hours,
        minutes: timeData.hours > 0 ? -1 : timeData.minutes > 0 ? timeData.minutes : -1,
      },
      coughRate: totalTime > 0 ? ((totalCount / totalTime) * 3600).toFixed(1) : 0,
      progressList: progressList,
    };
  }, [coughWeeklyList, reportTime]);

  const widgetValue = useMemo(() => {
    if (activeTab === TODAY_VIEW_MODE.VIEW_DAILY) {
      return todayCough;
    }
    if (activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY) {
      return weekCough;
    }
    if (activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY) {
      return monthCough;
    }
    if (activeTab === TODAY_VIEW_MODE.VIEW_YEARLY) {
      return yearCough;
    }
    return {
      totalHour: {
        hours: 0,
        minutes: 0,
      },
      coughRate: 0,
    };
  }, [activeTab, todayCough, weekCough, monthCough, yearCough]);

  const dailyHistoryData = useMemo(() => {
    const data: any = Array(HISTORY_DAILY_LIMIT - 1)
      .fill(0)
      .map((item) => ({
        label: "",
        value: DataType.NOT_TRACK,
      }));

    for (let index = 0; index < HISTORY_DAILY_LIMIT - 1; index++) {
      data[index].label = format(subDays(reportTime, index + 1), "dd LLL y");

      const eventItem = historyDailyList.find(
        (item: any) =>
          format(new Date(item.time), "dd-MM-yyyy") === format(subDays(reportTime, index + 1), "dd-MM-yyyy")
      );
      if (eventItem) {
        if (eventItem.time_tracked_s >= EVENT_LIMIT.DAILY) {
          data[index].value = ((eventItem.count / eventItem.time_tracked_s) * 3600).toFixed(1);
        } else {
          data[index].value = DataType.NOT_ENOUGH;
        }
      }
    }
    return data;
  }, [historyDailyList, reportTime]);

  const weeklyHistoryData = useMemo(() => {
    const data: any = Array(HISTORY_WEEKLY_LIMIT - 1)
      .fill(0)
      .map((item) => ({
        label: "",
        value: DataType.NOT_TRACK,
      }));

    for (let index = 0; index < HISTORY_WEEKLY_LIMIT - 1; index++) {
      data[index].label = getWeeksLabel(reportTime, index + 1);
      const eventItem = historyWeeklyList.find(
        (item: any) =>
          format(new Date(item.time), "dd-MM-yyyy") ===
          format(startOfWeekMonday(subWeeks(reportTime, index + 1)), "dd-MM-yyyy")
      );
      if (eventItem) {
        if (eventItem.time_tracked_s >= EVENT_LIMIT.WEEKLY) {
          data[index].value = ((eventItem.count / eventItem.time_tracked_s) * 3600).toFixed(1);
        } else {
          data[index].value = DataType.NOT_ENOUGH;
        }
      }
    }
    return data;
  }, [historyWeeklyList, reportTime]);

  const monthlyHistoryData = useMemo(() => {
    const data: any = Array(HISTORY_MONTHLY_LIMIT - 1)
      .fill(0)
      .map((item) => ({
        label: "",
        value: DataType.NOT_TRACK,
      }));
    for (let index = 0; index < HISTORY_MONTHLY_LIMIT - 1; index++) {
      data[index].label = getCurrentMonthLabel(subMonths(reportTime, index + 1));
      const eventItem = historyMonthlyList.find(
        (item: any) =>
          format(new Date(item.time), "dd-MM-yyyy") ===
          format(startOfMonth(subMonths(reportTime, index + 1)), "dd-MM-yyyy")
      );
      if (eventItem) {
        if (eventItem.time_tracked_s >= EVENT_LIMIT.MONTHLY) {
          data[index].value = ((eventItem.count / eventItem.time_tracked_s) * 3600).toFixed(1);
        } else {
          data[index].value = DataType.NOT_ENOUGH;
        }
      }
    }
    return data;
  }, [historyMonthlyList, reportTime]);

  const yearlyHistoryData = useMemo(() => {
    const data: any = Array(HISTORY_YEARLY_LIMIT - 1)
      .fill(0)
      .map((item) => ({
        label: "",
        value: DataType.NOT_TRACK,
      }));
    for (let index = 0; index < HISTORY_YEARLY_LIMIT - 1; index++) {
      data[index].label = getWholeYearLabel(subYears(reportTime, index + 1));
      const eventItem = historyYearlyList.find(
        (item: any) =>
          format(new Date(item.time), "yyyy") ===
          format(startOfYear(subYears(reportTime, index + 1)), "yyyy")
      );
      if (eventItem) {
        if (eventItem.time_tracked_s >= EVENT_LIMIT.YEARLY) {
          data[index].value = ((eventItem.count / eventItem.time_tracked_s) * 3600).toFixed(1);
        } else {
          data[index].value = DataType.NOT_ENOUGH;
        }
      }
    }
    return data;
  }, [historyYearlyList, reportTime]);

  const historyData = useMemo(() => {
    if (activeTab === TODAY_VIEW_MODE.VIEW_DAILY) {
      return dailyHistoryData;
    }
    if (activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY) {
      return weeklyHistoryData;
    }
    if (activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY) {
      return monthlyHistoryData;
    }
    if (activeTab === TODAY_VIEW_MODE.VIEW_YEARLY) {
      return yearlyHistoryData;
    }
  }, [activeTab, dailyHistoryData, weeklyHistoryData, monthlyHistoryData, yearlyHistoryData]);

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => {
      // Swipe >>;
      setReportTime((prevTime) => {
        let nextTime = prevTime;
        switch (activeTab) {
          case TODAY_VIEW_MODE.VIEW_DAILY:
            nextTime = endOfDay(addDays(prevTime, 1));
            break;
          case TODAY_VIEW_MODE.VIEW_WEEKLY:
            nextTime = endOfWeekMonday(addWeeks(prevTime, 1));
            break;
          case TODAY_VIEW_MODE.VIEW_MONTHLY:
            nextTime = endOfMonth(addMonths(prevTime, 1));
            break;
          case TODAY_VIEW_MODE.VIEW_YEARLY:
            nextTime = endOfYear(addYears(prevTime, 1));
            break;
        }
        if (nextTime.getTime() > new Date().getTime()) {
          setHistoricalView(false);
          nextTime = new Date();
        }
        return nextTime;
      });
    },
    onSwipedRight: () => {
      // Swipe <<;
      setReportTime((currentTime) => {
        let prevTime = currentTime;
        switch (activeTab) {
          case TODAY_VIEW_MODE.VIEW_DAILY:
            prevTime = endOfDay(subDays(currentTime, 1));
            break;
          case TODAY_VIEW_MODE.VIEW_WEEKLY:
            prevTime = endOfWeekMonday(subWeeks(currentTime, 1));
            break;
          case TODAY_VIEW_MODE.VIEW_MONTHLY:
            prevTime = endOfMonth(subMonths(currentTime, 1));
            break;
          case TODAY_VIEW_MODE.VIEW_YEARLY:
            prevTime = endOfYear(subYears(currentTime, 1));
            break;
        }
        setHistoricalView(true);
        return prevTime;
      });
    },
    minSwipeDistanceX: window.innerWidth / 3,
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      switch (activeTab) {
        case TODAY_VIEW_MODE.VIEW_DAILY:
          await handleLoadDailyData();
          await handleLoadDailyHistory();
          break;
        case TODAY_VIEW_MODE.VIEW_WEEKLY:
          await handleLoadWeeklyData();
          await handleLoadWeeklyHistory();
          break;
        case TODAY_VIEW_MODE.VIEW_MONTHLY:
          await handleLoadMonthlyData();
          await handleLoadMonthlyHistory();
          break;
        case TODAY_VIEW_MODE.VIEW_YEARLY:
          await handleLoadYearlyData();
          await handleLoadYearlyHistory();
          break;
      }
      setLoading(false);
    })();
    return () => {};
  }, [
    reportTime,
    activeTab,
    handleLoadDailyData,
    handleLoadWeeklyData,
    handleLoadMonthlyData,
    handleLoadYearlyData,
    handleLoadDailyHistory,
    handleLoadWeeklyHistory,
    handleLoadMonthlyHistory,
    handleLoadYearlyHistory,
  ]);

  const onSelectDate = (date: Date) => {
    setReportTime(date);
  };

  // Solves the issue of the page not scrolling to the top when navigating to this page
  useEffect(() => {
    window.scrollY >= 0 && window.scrollTo(0, 0);
  }, []);

  // render
  return (
    <div className="rate">
      <div className="rate-wrapper">
        <div className="rate-content" {...swipeHandlers}>
          <div className="rate-content-container">
            <Tabs
              tabList={[
                {
                  value: TODAY_VIEW_MODE.VIEW_DAILY,
                  label: "Daily",
                },
                {
                  value: TODAY_VIEW_MODE.VIEW_WEEKLY,
                  label: "Weekly",
                },
                {
                  value: TODAY_VIEW_MODE.VIEW_MONTHLY,
                  label: "Monthly",
                },
                {
                  value: TODAY_VIEW_MODE.VIEW_YEARLY,
                  label: "Yearly",
                },
              ]}
              value={activeTab}
              onChange={async (tab) => {
                setLoading(true);
                setReportTime(new Date());
                historicalView && setHistoricalView(false);
                setActiveTab(tab);
              }}
            />
            <div className="rate-content-section">
              <div className="rate-content-section-label">
                <DatePicker
                  date={reportTime}
                  onSelectDate={onSelectDate}
                  viewMode={activeTab}
                  prependText={`${widgetConfig?.timeTitle} `}
                />
              </div>
              <div className="rate-content-section-wrapper">
                <div className="rate-content-section-wrapper-main">
                  {loading ? (
                    <div className="rate-content-section-wrapper-main-value">-</div>
                  ) : (
                    <>
                      {((widgetValue?.totalHour?.hours ?? 0) > 0 ||
                        ((widgetValue?.totalHour?.hours ?? 0) === 0 && activeTab === TODAY_VIEW_MODE.VIEW_DAILY)) && (
                        <>
                          <div className="rate-content-section-wrapper-main-value">
                            {widgetValue?.totalHour?.hours ?? 0}
                          </div>
                          <div className="rate-content-section-wrapper-main-unit">hrs</div>
                        </>
                      )}
                      {(((widgetValue?.totalHour?.minutes ?? 0) >= 0 && activeTab === TODAY_VIEW_MODE.VIEW_DAILY) ||
                        ((widgetValue?.totalHour?.minutes ?? 0) > 0 && activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY)) && (
                        <>
                          <div className="rate-content-section-wrapper-main-value">
                            {widgetValue?.totalHour?.minutes ?? 0}
                          </div>
                          <div className="rate-content-section-wrapper-main-unit">min</div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="rate-content-section-wrapper-sub">/{widgetConfig.totalHours} hrs</div>
              </div>
              {activeTab === TODAY_VIEW_MODE.VIEW_DAILY && <Progress percent={todayCough?.progress || 0} />}
              {activeTab === TODAY_VIEW_MODE.VIEW_WEEKLY && (
                <div className="rate-content-section-progress">
                  {weekCough?.progressList?.map((item: any, index: number) => (
                    <div
                      className="rate-content-section-progress-item progress-item-weekly"
                      key={`rate-content-section-progress-item-${index}`}
                    >
                      <Progress percent={item.progress} title={item.label} future={item.future} />
                    </div>
                  ))}
                </div>
              )}
              {activeTab === TODAY_VIEW_MODE.VIEW_MONTHLY && (
                <div className="rate-content-section-progress">
                  {monthCough?.progressList?.map((item: any, index: number) => (
                    <div
                      className="rate-content-section-progress-item"
                      key={`rate-content-section-progress-item-${index}`}
                    >
                      <Progress percent={item.progress} title={item.label} future={item.future} />
                    </div>
                  ))}
                </div>
              )}
              {activeTab === TODAY_VIEW_MODE.VIEW_YEARLY && (
                <>
                  <div className="rate-content-section-progress">
                    {yearCough?.progressList?.map(
                      (item: any, index: number) =>
                        index < 6 && (
                          <div
                            className="rate-content-section-progress-item"
                            key={`rate-content-section-progress-item-${index}`}
                          >
                            <Progress percent={item.progress} title={item.label} future={item.future} />
                          </div>
                        )
                    )}
                  </div>
                  <div className="rate-content-section-progress margin-top-10px">
                    {yearCough?.progressList?.map(
                      (item: any, index: number) =>
                        index > 5 && (
                          <div
                            className="rate-content-section-progress-item"
                            key={`rate-content-section-progress-item-${index}`}
                          >
                            <Progress percent={item.progress} title={item.label} future={item.future} />
                          </div>
                        )
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="rate-content-section">
              <div className="rate-content-section-label">{widgetConfig.rateTitle}</div>
              <div className="rate-content-section-wrapper">
                <div className="rate-content-section-wrapper-main">
                  {loading ? (
                    <div className="rate-content-section-wrapper-main-value">-</div>
                  ) : (
                    <>
                      <div className="rate-content-section-wrapper-main-value">{widgetValue?.coughRate || "0.0"}</div>
                      <div className="rate-content-section-wrapper-main-unit">/hr</div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rate-history">
          <div className="rate-history-label">{widgetConfig.historyTitle}</div>
          {historyData.map((historyItem: any, index: any) => (
            <div className="rate-history-item" key={`rate-history-item-${index}`}>
              <div className="rate-history-item-label">{historyItem.label}</div>
              {
                <>
                  {historyItem.value >= DataType.ZERO && (
                    <div className="rate-history-item-value">
                      <span className="rate-history-item-value-tracked">{historyItem.value}</span>/hr
                    </div>
                  )}
                  {historyItem.value === DataType.NOT_ENOUGH && (
                    <div className="rate-history-item-value-not">not enough data</div>
                  )}
                  {historyItem.value === DataType.NOT_TRACK && (
                    <div className="rate-history-item-value-not">did not track</div>
                  )}
                </>
              }
            </div>
          ))}
        </div>
      </div>
      <Loading loading={loading} />
    </div>
  );
};

export default Rate;
