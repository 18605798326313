/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AggregationsEnum } from './AggregationsEnum';
export type GetAggregatedEventsQS = {
    /**
     * Pagination. Max number of entries returned.
     */
    limit?: number;
    /**
     * Pagination. Offset.
     */
    offset?: number;
    /**
     * Id of the device to use, if omitted calculated across all devices.
     */
    device_id?: string | null;
    /**
     * Aggregation bucket size
     */
    aggregation?: AggregationsEnum;
    /**
     * Start time, timestamp with tz
     */
    start_date?: string | null;
    /**
     * End time, timestamp with tz
     */
    end_date?: string | null;
    /**
     * Ascending or descending in time
     */
    direction?: GetAggregatedEventsQS.direction;
    /**
     * Omit empty buckets
     */
    omit_empty_buckets?: boolean | null;
};
export namespace GetAggregatedEventsQS {
    /**
     * Ascending or descending in time
     */
    export enum direction {
        ASC = 'asc',
        DESC = 'desc',
    }
}

