import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend, ChartDataLabels);

type DonutDataset = {
  data: number[];
  label?: string;
  datalabels?: any;
  backgroundColor?: any | string | string[] | CanvasGradient | CanvasPattern;
  borderAlign?: "center" | "inner";
  borderWidth?: any | number;
  borderDash?: number[];
  borderColor?: any | string | string[] | CanvasGradient | CanvasPattern;
  borderDashOffset?: number;
  borderJoinStyle?: "bevel" | "round" | "miter";
  weight?: number;
};

type DonutChartProps = {
  id?: string;
  datasets: DonutDataset[];
  height?: number;
  width?: number;
  circleLabelData?: string[];
  options?: {
    responsive?: boolean;
    radius?: number | string;
    cutout?: number | string;
    aspectRatio?: number;
    mantainsAspectRatio?: boolean;
    animation?: {
      animateRotate?: boolean;
      animateScale?: boolean;
    };
    circumference?: number;
    rotation?: number;
    plugins?: any;
    elements?: any;
    layout?: any;
  };
  ref: any;
  className?: string;
};

interface DonutChartRef {
  toBase64Image: () => void;
}

const DonutChart = forwardRef<DonutChartRef, DonutChartProps>(
  ({ id, height, width, options, className, circleLabelData, datasets }, ref) => {
    const chartRef = useRef<any>(null);
    const mergedOptions = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
        ...options?.plugins,
      },
      cutout: options?.cutout || "70%",
      ...options,
    };

    const data = {
      labels: circleLabelData ?? [],
      datasets: datasets,
    };

    useImperativeHandle(ref, () => ({
      toBase64Image: () => {
        return chartRef.current?.toBase64Image();
      },
    }));

    return (
      <>
        <Doughnut
          id={id}
          height={height}
          width={width}
          ref={chartRef}
          options={mergedOptions}
          data={data}
          className={className}
        />
      </>
    );
  }
);
export default DonutChart;
