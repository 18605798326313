import React from "react";
// import { LoadingOutlined } from "@ant-design/icons";
// import { Spin } from "antd";
import "./style.scss";

type ProgressProps = {
  percent: number;
  title?: string;
  future?: boolean;
};

const Progress: React.FC<ProgressProps> = ({ percent, title, future }) => {
  return (
    <div className="progress">
      <div className="progress_container">
        <div className={percent === 0 ? "progress_container-back-empty" : "progress_container-back"}></div>
        <div className="progress_container-content" style={{ width: `${percent}%` }}></div>
        {future ? (
          <div className="progress_container-future"></div>
        ) : (
          percent === 0 && (
            <div className="progress_container-empty">
              <div className="progress_container-empty-slash"></div>
            </div>
          )
        )}
      </div>
      {title && <div className={percent === 0 ? "progress-title-empty" : "progress-title"}>{title}</div>}
    </div>
  );
};

export default Progress;
