import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { DonutChart } from "../../charts";
import { colors } from "../utils/colors";

type DidNotTrackDonutProps = {
  className?: string;
};
interface DidNotTrackDonutRef {
  toBase64Image: () => void;
}

const DidNotTrackDonut = forwardRef<DidNotTrackDonutRef, DidNotTrackDonutProps>(
  ({ className = "clock-chart-wrapper-chart", ...props }, ref) => {
    const chartRef = useRef<any>(null);
    useImperativeHandle(ref, () => ({
      toBase64Image: () => {
        return chartRef.current?.toBase64Image();
      },
    }));
    return (
      <DonutChart
        {...props}
        ref={chartRef}
        id="donut-did-not-track-colors"
        className={className}
        options={{
          rotation: 0,
          radius: "99.4%",
          cutout: "95%",
          responsive: true,
          mantainsAspectRatio: true,
          layout: {
            padding: 25,
          },
          animation: {
            animateRotate: false,
            animateScale: false,
          },
        }}
        datasets={[
          {
            borderColor: colors.ERROR_300,
            borderWidth: (context: any) => ((context.dataIndex + 1) % 7 === 4 ? 0 : 1),
            backgroundColor: (context: any) =>
              (context.dataIndex + 1) % 7 === 4 ? colors.NEUTRAL_0 : colors.ERROR_300,
            data: Array(24 * 7).fill(1),
          },
        ]}
      />
    );
  }
);

export default DidNotTrackDonut;
