import { useLocation, useNavigate } from "react-router-dom";
import { useStripeCheckoutDetails } from "../../../lib/stripe";
import { useEffect } from "react";
import Loading from "../../../components/loading";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useFbPixel } from "../../../lib/analytics/fb_pixel";

const StripeCheckoutCompleted = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // This route should be called from stripe on `checkout.session.complete`
  // with checkout_session_id as a parameter
  const params = new URLSearchParams(location.search);
  const { stripeCheckoutDetails } = useStripeCheckoutDetails(params.get("session_id"));

  const fbPixel = useFbPixel();

  useEffect(() => {
    if (stripeCheckoutDetails) {
      // Send conversion events to google analytics
      const ga = getAnalytics();
      logEvent(
        ga,
        "purchase",
        stripeCheckoutDetails.amount_total != null &&
          ({
            value: (stripeCheckoutDetails.amount_total / 100).toString(),
            currency: stripeCheckoutDetails.currency,
          } as any)
      );
      // Send conversion events to google fb pixel
      fbPixel?.track(
        "Subscribe",
        stripeCheckoutDetails.amount_total != null
          ? {
              currency: stripeCheckoutDetails.currency,
              value: stripeCheckoutDetails.amount_total / 100,
            }
          : {}
      );

      navigate("/");
    }
  }, [stripeCheckoutDetails, fbPixel, navigate]);

  return (
    <div className="">
      <Loading loading />
    </div>
  );
};

export default StripeCheckoutCompleted;
