/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExportSurveyAnswers_200 } from '../models/ExportSurveyAnswers_200';
import type { ListSurveys_200 } from '../models/ListSurveys_200';
import type { SubmitSurvey_200 } from '../models/SubmitSurvey_200';
import type { SubmitSurveyBody } from '../models/SubmitSurveyBody';
import type { Survey } from '../models/Survey';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SurveysService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List surveys
     * Get the list of surveys available
     * @param customerId Id of the tenant
     * @param uid Id of the user
     * @returns ListSurveys_200
     * @throws ApiError
     */
    public getUsersSurveys(
        customerId: string,
        uid: string,
    ): CancelablePromise<ListSurveys_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/users/{uid}/surveys',
            path: {
                'customer_id': customerId,
                'uid': uid,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Get survey contents
     * Get the contents of a survey
     * @param customerId Id of the tenant
     * @param uid Id of the user
     * @param surveyId
     * @returns Survey
     * @throws ApiError
     */
    public getUsersSurveys1(
        customerId: string,
        uid: string,
        surveyId: string,
    ): CancelablePromise<Survey> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/users/{uid}/surveys/{survey_id}',
            path: {
                'customer_id': customerId,
                'uid': uid,
                'survey_id': surveyId,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Submit a survey
     * Submit the results of a survey
     * @param customerId Id of the tenant
     * @param uid Id of the user
     * @param surveyId
     * @param requestBody
     * @returns SubmitSurvey_200
     * @throws ApiError
     */
    public postUsersSurveys(
        customerId: string,
        uid: string,
        surveyId: string,
        requestBody: SubmitSurveyBody,
    ): CancelablePromise<SubmitSurvey_200> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/{customer_id}/users/{uid}/surveys/{survey_id}',
            path: {
                'customer_id': customerId,
                'uid': uid,
                'survey_id': surveyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Export surveys
     * Export all survey answers for all users in a group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param limit Pagination. Max number of entries returned.
     * @param offset Pagination. Offset.
     * @returns ExportSurveyAnswers_200
     * @throws ApiError
     */
    public getGroupsSurveysAnswers(
        customerId: string,
        groupId: string,
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<ExportSurveyAnswers_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/groups/{group_id}/surveys/answers',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
}
