import React, { useEffect } from "react";

import { signOut } from "../../lib/firebase/firebase";
import { useNavigate } from "react-router-dom";
import { discourseLogout } from "../../lib/discourse";
import { useRecoilValue } from "recoil";
import { AuthState } from "../../state/auth";

const Logout = () => {
  const { user, isFbAuthInit } = useRecoilValue(AuthState);
  const navigate = useNavigate();

  useEffect(() => {
    const logOutFlow = async () => {
      try {
        discourseLogout();
        await signOut();
        navigate("/login");
      } catch (err) {
        console.error(err);
      }
    };
    if (isFbAuthInit) {
      if (user) {
        logOutFlow();
      }
      if (!user) {
        navigate("/login");
      }
    }
  }, [user, navigate, isFbAuthInit]);

  return <div>Signing out...</div>;
};

export default Logout;
