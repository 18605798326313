/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
}

export const interpolateColors = (col1: string, col2: string, percent: number) => {
  const rgb1 = parseInt(col1.replace("#", ""), 16);
  const rgb2 = parseInt(col2.replace("#", ""), 16);

  const toArray = (rgb: number) => [rgb >> 16, (rgb >> 8) % 256, rgb % 256];

  const [r1, g1, b1] = toArray(rgb1);
  const [r2, g2, b2] = toArray(rgb2);

  const q = 1 - percent;
  const rr = Math.round(r1 * percent + r2 * q);
  const rg = Math.round(g1 * percent + g2 * q);
  const rb = Math.round(b1 * percent + b2 * q);

  return "#" + Number((rr << 16) + (rg << 8) + rb).toString(16);
};

export const generateGradient = (startColor: string, endColor: string, steps: number, reverse?: boolean): string[] => {
  steps = steps < 2 ? 2 : steps;
  const stepFactor = 1 / (steps - 1);
  const gradient = Array(Math.floor(steps))
    .fill(0)
    .map((_, i) => interpolateColors(startColor, endColor, stepFactor * i));
  return reverse ? [...gradient].reverse() : gradient;
};

export const generateDonutGradient = (
  startColor: string,
  endColor: string,
  totalSections: number,
  percentage: number
) => generateGradient(startColor, endColor, calculateGradientPercentage(percentage, totalSections)).reverse();

export const calculateGradientPercentage = (totalSections: number, percentage: number) =>
  Math.floor((percentage * totalSections) / 2 / 100);

export const isDesktopVersion = () => {
  return process.env.REACT_APP_DESKTOP_VERSION === "true";
};

export const isPhoneBrowser = () => {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  );
};

export const zeroPad = (num: number | undefined) => String(num ?? 0).padStart(2, "0");

export const fillArrayWithRange = (start: number, finish: number, addOffset: number = 1) => {
  if (start > finish) [start, finish] = [finish, start];
  const length = (finish - start) / addOffset;
  return Array.from({ length: length + 1 }, (_, index) => addOffset * index + start);
};