import "./style.scss";

type ReferenceBarProps = {
  /** The title to be used for the reference bar */
  title: string;
  /** The colors to be used for the reference bar */
  sectionBgColors: string[];
  /** The minimum value to be used for the reference bar */
  minValue: number;
  /** The maximum value to be used for the reference bar */
  maxValue: number;
  /** If true, the reference bar will be reversed */
  reverse?: boolean;
  /** If true, the reference bar will be shown as empty */
  isEmpty?: boolean;
  /** The message to be used for the empty reference bar */
  emptyMessage?: string;
};

const ReferenceBar = ({
  title,
  sectionBgColors,
  minValue,
  maxValue,
  reverse,
  isEmpty,
  emptyMessage,
}: ReferenceBarProps) => {
  const gradientBgColors = reverse ? [...sectionBgColors].reverse() : sectionBgColors;
  let maxValueLabel = maxValue.toString(),
    minValueLabel = minValue.toString();
  if (isEmpty) {
    maxValueLabel = "";
    minValueLabel = emptyMessage ?? "No data for this period";
  }
  return (
    <div className="reference-bar">
      <div className="reference-bar-content">
        <div className="reference-bar-content-title">{title}</div>
        <div className="reference-bar-content-bar">
          <div className="reference-bar-content-bar-sections">
            {gradientBgColors.map((bgColor, index) => {
              if (isEmpty) bgColor = gradientBgColors[0];
              return (
                <div
                  key={`reference-bar-section--${index}-${bgColor}`}
                  className="reference-bar-content-bar-sections-item"
                  style={{
                    backgroundColor: bgColor,
                  }}
                ></div>
              );
            })}
          </div>
          <div className="reference-bar-content-bar-values">
            <div className="reference-bar-content-bar-values-min">{minValueLabel}</div>
            <div className="reference-bar-content-bar-values-max">{maxValueLabel}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferenceBar;
