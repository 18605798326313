import React, { useEffect, useState } from "react";
import { startOfHour, endOfHour, setHours, addMilliseconds } from "date-fns";
import {
  CoughClockEvent,
  CoughRawEvent,
  CoughRawEventResponse,
  EVENT_AGGREGATION,
  MetricType,
} from "../../models/event";
import { api } from "../../utils/axios";
import { apiDateTimeFormat } from "../../utils/date-operation";
import CardLoader from "../card-loader";
import { AppState } from "../../state/global";
import { useRecoilValue } from "recoil";
import BurstDetailCard from "../burst-detail-card";
import "./style.scss";

const TOP_BURSTS = 20;

type BurstDetailsProps = {
  today: Date;
  burstList: CoughClockEvent[];
};

const getCoughBurstDetails = async (startDate: string, endDate: string) => {
  try {
    const result = await api<CoughRawEventResponse>({
      url: `/events/v2`,
      method: "GET",
      params: {
        metric: MetricType.COUGH_BURST,
        aggregation: EVENT_AGGREGATION.RAW,
        start_date: startDate,
        end_date: endDate,
        // fields: "uid,device_id,time,metric,utc_time,sensor,value1,value2",
        fields: "time,value1,value2",
      },
    });
    return result?.data?.events || [];
  } catch (error) {
    console.error("burstDetails.getCoughBurstDetails:", error);
    return [];
  }
};

const BurstDetails: React.FC<BurstDetailsProps> = ({ today, burstList }) => {
  const globalState = useRecoilValue(AppState);
  const [loading, setLoading] = useState<boolean>(true);
  const [burstData, setBurstData] = useState<any[]>([]);

  useEffect(() => {
    const handleBurstData = async () => {
      if (burstList.length < 1) return;
      setLoading(true);
      const filtered = burstList.filter((item: CoughClockEvent) => item.count > 0);
      filtered.sort((a: CoughClockEvent, b: CoughClockEvent) => b.count - a.count);
      const events: CoughRawEvent[] = [];
      for (let i = 0, accHours = 0; i < filtered.length; i++) {
        const item = filtered[i];
        if (item.count < 1) continue;
        accHours += item.count;
        if (accHours <= TOP_BURSTS) {
          const burstTime = setHours(today, item.hour);
          events.push(
            ...(await getCoughBurstDetails(
              apiDateTimeFormat(startOfHour(burstTime)),
              apiDateTimeFormat(endOfHour(burstTime))
            ))
          );
        } else break;
      }
      setBurstData(
        events
          .sort((a: CoughRawEvent, b: CoughRawEvent) => Number.parseInt(b.value1) - Number.parseInt(a.value1))
          .splice(0, TOP_BURSTS)
      );
      setLoading(false);
    };
    handleBurstData();
    return () => {};
  }, [burstList, today]);

  if (loading) {
    return (
      <div className="burst-card">
        <CardLoader rows={TOP_BURSTS} />
      </div>
    );
  }

  return (
    <div>
      {burstData &&
        burstData.map((item: CoughRawEvent, index: number) => {
          const coughs = Number.parseInt(item.value1 ?? 0),
            durationMS = Number.parseInt(item.value2 ?? 1),
            timeStart = new Date(item.time),
            timeEnd = addMilliseconds(timeStart, Math.ceil(durationMS));
          return (
            <BurstDetailCard
              timeStart={timeStart}
              timeEnd={timeEnd}
              key={`burst-detail-card-${index}`}
              metric={{
                label: (
                  <>
                    <span>{`${globalState.i18n.currentCurrencyFormat.format(coughs)}`}</span>
                    <span> coughs</span>
                  </>
                ),
                value: "",
                unit: "DURATION",
              }}
              // right={{
              //   label: "",
              //   value: duration,
              //   unit: durationUnit,
              // }}
            />
          );
        })}
    </div>
  );
};

export default BurstDetails;
