import { createBrowserRouter, Outlet, RouterProvider, useLocation } from "react-router-dom";
import Login from "../containers/login";
import Insight from "../containers/insight";
import Report from "../containers/report";
import Main from "../containers/main";
import Rate from "../containers/rate";
import Burst from "../containers/burst";
import Trend from "../containers/trend";
import Logout from "../containers/logout";
import { ErrorBoundary } from "react-error-boundary";
import DiscourseHandler from "../containers/discourse";
import EmailLoginHandler from "../containers/email_login";
import Loading from "../components/loading";
import { useRecoilValue } from "recoil";
import { AuthState } from "../state/auth";
import StripeCheckoutCanceled from "../containers/stripe/checkout_canceled";
import StripeCheckoutCompleted from "../containers/stripe/checkout_completed";
import { useEffect, useState } from "react";
import { useFbPixel } from "../lib/analytics/fb_pixel";
import { getCountry } from "../utils/getCountry";
import { isDesktopVersion } from "../utils/misc";
import { useSignInWithJWT } from "../lib/firebase/firebase";

const AuthenticatedRoute = () => {
  const { user } = useRecoilValue(AuthState);
  useSignInWithJWT();
  if (user === undefined) {
    // Authentication not yet settled
    return (
      <div>
        <Loading loading />
      </div>
    );
  } else if (user === null) {
    // Not authenticated
    return <Login automaticRedirect={false} />;
  } else {
    // Authenticated
    return <Outlet />;
  }
};

const MainLayout = () => {
  const fbPixel = useFbPixel();
  const location = useLocation();
  // Tack PageView on location change
  useEffect(() => {
    fbPixel?.pageView();
  }, [fbPixel, location]);

  // useEffect(() => {
  //   // Don't geocheck in the `/_` route or if the `skipGeoCheck` flag is set.
  //   // Done this way so that admins can navigate to `/_` and enter the right commands in the console
  //   const skip = location.pathname === "/_" || window.localStorage.getItem("skipGeoCheck") === "true";
  //   // Site not available in the US
  //   if (!skip && isDesktopVersion() && getCountry() === "United States of America") {
  //     window.alert("CoughPro is not available in the United States. Continue to CoughTracker.com.");
  //     window.location.replace("https://www.coughtracker.com/");
  //   }
  // }, []);

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <Outlet />
    </ErrorBoundary>
  );
};

const router = createBrowserRouter([
  {
    element: <MainLayout />,
    errorElement: <p>⚠️Something went wrong</p>,
    children: [
      {
        element: <AuthenticatedRoute />,
        children: [
          {
            path: "/",
            element: <Main />,
            children: [
              {
                path: "",
                element: <Insight />,
              },
              {
                path: "report",
                element: <Report />,
              },
              {
                path: "rate",
                element: <Rate />,
              },
              {
                path: "bursts",
                element: <Burst />,
              },
              {
                path: "trend",
                element: <Trend />,
              },
              {
                path: "community",
                element: <DiscourseHandler />,
              },
            ],
          },
          {
            path: "/stripe_checkout_completed",
            element: <StripeCheckoutCompleted />,
          },
          {
            path: "/stripe_checkout_canceled",
            element: <StripeCheckoutCanceled />,
          },
        ],
      },
      {
        path: "/tokenLoggedOut",
        element: <div />,
      },
      {
        path: "/_",
        element: <div />,
      },
      {
        path: "/email_login_redirect",
        element: <EmailLoginHandler />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
    ],
  },
]);

export default function Layout() {
  return <RouterProvider router={router} />;
}
