/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MetricsEnum } from './MetricsEnum';
export type GetTrend_QS = {
    /**
     * Date around which the trend is calculated.
     */
    date?: string;
    /**
     * Id of the device to use, if omitted calculated across all devices.
     */
    device_id?: string | null;
    /**
     * Compare day to day, or week to week, etc...
     */
    granularity?: GetTrend_QS.granularity;
    /**
     * Metric to use.
     */
    metric?: MetricsEnum;
};
export namespace GetTrend_QS {
    /**
     * Compare day to day, or week to week, etc...
     */
    export enum granularity {
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
    }
}

