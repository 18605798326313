/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetClock_200 } from '../models/GetClock_200';
import type { GetStreak_200 } from '../models/GetStreak_200';
import type { GetTrend_200 } from '../models/GetTrend_200';
import type { MetricsEnum } from '../models/MetricsEnum';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InsightsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get a cough clock
     * Get a cough clock for the requested time period
     * @param from Start time, timestamp with tz
     * @param to End time, timestamp with tz
     * @param customerId Id of the tenant
     * @param uid Id of the user
     * @param deviceId Id of the device to use, if omitted calculated across all devices.
     * @param metric Metric used to calculate the clock
     * @returns GetClock_200 Successful response
     * @throws ApiError
     */
    public getUsersInsightsClock(
        from: string,
        to: string,
        customerId: string,
        uid: string,
        deviceId?: string | null,
        metric?: MetricsEnum,
    ): CancelablePromise<GetClock_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/users/{uid}/insights/clock',
            path: {
                'customer_id': customerId,
                'uid': uid,
            },
            query: {
                'from': from,
                'to': to,
                'device_id': deviceId,
                'metric': metric,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Get days of streak usage
     * From the `start` date how many days straight has this user tracked for more than `tracking_threshold_minutes`?
     * @param start Start point of the streak calculation. Most times should be set to the current time of the user.
     * @param customerId Id of the tenant
     * @param uid Id of the user
     * @param deviceId Id of the device to use, if omitted calculated across all devices.
     * @param trackingThresholdMinutes Minimum minutes of tracking for a given day to contribute in a streak.
     * @returns GetStreak_200 Successful response
     * @throws ApiError
     */
    public getUsersInsightsStreak(
        start: string,
        customerId: string,
        uid: string,
        deviceId?: string | null,
        trackingThresholdMinutes: number = 60,
    ): CancelablePromise<GetStreak_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/users/{uid}/insights/streak',
            path: {
                'customer_id': customerId,
                'uid': uid,
            },
            query: {
                'start': start,
                'device_id': deviceId,
                'tracking_threshold_minutes': trackingThresholdMinutes,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Get cough trend
     * Get the cough trend at a point in time
     * @param customerId Id of the tenant
     * @param uid Id of the user
     * @param date Date around which the trend is calculated.
     * @param deviceId Id of the device to use, if omitted calculated across all devices.
     * @param granularity Compare day to day, or week to week, etc...
     * @param metric Metric to use.
     * @returns GetTrend_200 Successful response
     * @throws ApiError
     */
    public getUsersInsightsTrend(
        customerId: string,
        uid: string,
        date: string = '2024-03-08T14:15:25.180589Z',
        deviceId?: string | null,
        granularity: 'day' | 'week' | 'month' = 'day',
        metric?: MetricsEnum,
    ): CancelablePromise<GetTrend_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/users/{uid}/insights/trend',
            path: {
                'customer_id': customerId,
                'uid': uid,
            },
            query: {
                'date': date,
                'device_id': deviceId,
                'granularity': granularity,
                'metric': metric,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Get a cough clock (group)
     * Get a cough clock for the requested time period of a user in a group
     * @param from Start time, timestamp with tz
     * @param to End time, timestamp with tz
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param uid Id of the user
     * @param deviceId Id of the device to use, if omitted calculated across all devices.
     * @param metric Metric used to calculate the clock
     * @returns GetClock_200 Successful response
     * @throws ApiError
     */
    public getGroupsUsersInsightsClock(
        from: string,
        to: string,
        customerId: string,
        groupId: string,
        uid: string,
        deviceId?: string | null,
        metric?: MetricsEnum,
    ): CancelablePromise<GetClock_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/groups/{group_id}/users/{uid}/insights/clock',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
                'uid': uid,
            },
            query: {
                'from': from,
                'to': to,
                'device_id': deviceId,
                'metric': metric,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Get days of streak usage (group)
     * From the `start` date how many days straight has this user tracked for more than `tracking_threshold_minutes`? for a user that belongs to a group
     * @param start Start point of the streak calculation. Most times should be set to the current time of the user.
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param uid Id of the user
     * @param deviceId Id of the device to use, if omitted calculated across all devices.
     * @param trackingThresholdMinutes Minimum minutes of tracking for a given day to contribute in a streak.
     * @returns GetStreak_200 Successful response
     * @throws ApiError
     */
    public getGroupsUsersInsightsStreak(
        start: string,
        customerId: string,
        groupId: string,
        uid: string,
        deviceId?: string | null,
        trackingThresholdMinutes: number = 60,
    ): CancelablePromise<GetStreak_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/groups/{group_id}/users/{uid}/insights/streak',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
                'uid': uid,
            },
            query: {
                'start': start,
                'device_id': deviceId,
                'tracking_threshold_minutes': trackingThresholdMinutes,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
    /**
     * Get cough trend (group)
     * Get the cough trend at a point in time for a user in a group
     * @param customerId Id of the tenant
     * @param groupId Id of the group
     * @param uid Id of the user
     * @param date Date around which the trend is calculated.
     * @param deviceId Id of the device to use, if omitted calculated across all devices.
     * @param granularity Compare day to day, or week to week, etc...
     * @param metric Metric to use.
     * @returns GetTrend_200 Successful response
     * @throws ApiError
     */
    public getGroupsUsersInsightsTrend(
        customerId: string,
        groupId: string,
        uid: string,
        date: string = '2024-03-08T14:15:25.180589Z',
        deviceId?: string | null,
        granularity: 'day' | 'week' | 'month' = 'day',
        metric?: MetricsEnum,
    ): CancelablePromise<GetTrend_200> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{customer_id}/groups/{group_id}/users/{uid}/insights/trend',
            path: {
                'customer_id': customerId,
                'group_id': groupId,
                'uid': uid,
            },
            query: {
                'date': date,
                'device_id': deviceId,
                'granularity': granularity,
                'metric': metric,
            },
            errors: {
                400: `Validation error`,
            },
        });
    }
}
