import axios, { AxiosResponse } from "axios";

export const discourseLogin = async (sso: string, sig: string, abortSignal: AbortSignal | undefined = undefined) => {
  // Assumes a firebase user is logged in and the axios interceptor in `firebase.ts` is running
  try {
    const response = await axios.post<any, AxiosResponse<{ redirectUrl: string }>>(
      process.env.REACT_APP_DISCOURSE_SSO_URL as string,
      {
        discoursePayload: sso,
        discourseSignature: sig,
      },
      { signal: abortSignal }
    );
    if (response.data.redirectUrl) {
      // Go back to discourse with credentials in the redirectUrl
      window.location.replace(response.data.redirectUrl);
    } else {
      throw new Error(`Redirect URL not found in response - ${response.status} - ${response.data}`);
    }
  } catch (err) {
    console.error(err);
  }
};

export const discourseLogout = async () => {
  // Assumes a firebase user is logged in and the axios interceptor in `firebase.ts` is running
  return axios.post(process.env.REACT_APP_DISCOURSE_FORCE_LOGOUT as string);
};
