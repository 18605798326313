import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isDesktopVersion } from "../../utils/misc";
import { AuthState } from "../../state/auth";
import { useRecoilValue } from "recoil";

import {
  getAuth,
  GoogleAuthProvider,
  TwitterAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signOut as firebaseSignOut,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  signInAnonymously,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

export const app = initializeApp({
  databaseURL: "https://hyfe-app.firebaseio.com",
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY as string,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN as string,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID as string,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET as string,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID as string,
  appId: process.env.REACT_APP_FIREBASE_APPID as string,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID as string,
});

export const firestore = getFirestore();

export const analytics = getAnalytics(app);

export const signInWithEmailAndPassword = (email: string, password: string) => {
  const auth = getAuth(app);
  return firebaseSignInWithEmailAndPassword(auth, email, password);
};

export const signInAnonymousUser = () => {
  const auth = getAuth(app);
  return signInAnonymously(auth);
};

export const signInWithProvider = (provider: string) => {
  const auth = getAuth();
  var pvdr: any;
  switch (provider) {
    case "google":
      pvdr = new GoogleAuthProvider();
      break;
    case "twitter":
      pvdr = new TwitterAuthProvider();
      break;
    case "facebook":
      pvdr = new FacebookAuthProvider();
      break;
    case "apple":
      pvdr = new OAuthProvider("apple.com");
      break;
  }
  try {
    return signInWithPopup(auth, pvdr);
  } catch {
    return null;
  }
};

export const startSignInWithEmailLink = async (email: string, redirect: string) => {
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: redirect,
    // This must be true.
    handleCodeInApp: true,
  };
  const auth = getAuth();

  await sendSignInLinkToEmail(auth, email, actionCodeSettings);
  window.localStorage.setItem("emailForSignIn", email);
};

export const completeSignInWithEmailLink = async () => {
  const auth = getAuth();
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt("Please provide your email for confirmation");
    }
    if (email == null) {
      console.error("Invalid email !");
      return null;
    }
    // The client SDK will parse the code from the link for you.
    try {
      const result = await signInWithEmailLink(auth, email, window.location.href);
      window.localStorage.removeItem("emailForSignIn");
      return result;
    } catch (error: any) {
      console.error(error.code, error.message);
      return null;
    }
  } else {
    return null;
  }
};
export const signInWithCustomToken = async (token: string) => {
  const auth = getAuth(app);
  try {
    return await firebaseSignInWithCustomToken(auth, token);
  } catch (error) {
    console.error("firebase.signInWithCustomToken:", error);
    return null;
  }
};

const getCustomToken = async (token: string) => {
  try {
    const response = await axios.create().get(process.env.REACT_APP_CUSTOM_TOKEN_URL || "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return null;
  }
};

export enum SignInWithJWTStates {
  UNINIT = 0,
  SUCCESS = 1,
  ERROR = -1,
  FAILED_GET_CUSTOM_TOKEN = -2,
  FAILED_LOGIN_CUSTOM_TOKEN = -3,
}

export const doJWTSignIn = async (token: string) => {
  try {
    // 1. Contact get_custom_auth_token to get custom token
    const response = await getCustomToken(token as string);
    // console.debug({ response });
    if (!response?.data?.custom_token) {
      return SignInWithJWTStates.FAILED_GET_CUSTOM_TOKEN;
    }
    // 2. Use custom token to log in
    var uid = await signInWithCustomToken(response?.data?.custom_token);
    if (!uid) {
      return SignInWithJWTStates.FAILED_LOGIN_CUSTOM_TOKEN;
    } else {
      return SignInWithJWTStates.SUCCESS;
    }
  } catch (error) {
    console.error(error);
    return SignInWithJWTStates.ERROR;
  }
};

export const signOut = async () => {
  const auth = getAuth(app);
  try {
    await firebaseSignOut(auth);
  } catch (error: any) {
    console.error("firebase.signOut: ", error.message);
  }
};

axios.interceptors.request.use(
  async (config) => {
    const token = await getAuth().currentUser?.getIdToken();
    if (config.headers && !config.headers.Authorization) config.headers.Authorization = `Bearer ${token}`;

    if (
      !config.url?.includes("v2.api.hyfe.ai") &&
      !config.url?.includes("hyfe-app-sandbox-gateway") &&
      !config.url?.includes("hyfe-app-gateway") &&
      config.headers &&
      !config.headers["X-Forwarded-Authorization"]
    )
      config.headers["X-Forwarded-Authorization"] = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export * as InitFirestore from "firebase/firestore";

export const useSignInWithJWT = () => {
  const [status, setStatus] = useState(SignInWithJWTStates.UNINIT);
  const { user, isFbAuthInit } = useRecoilValue(AuthState);
  const navigate = useNavigate();
  const location = useLocation();

  // On location change check for a token in the url
  useEffect(() => {
    // if (isDesktopVersion()) return;
    let timeout_id: any;
    let params = new URLSearchParams(location.search);
    const token = params.get("token");
    const asyncHandler = async (token: string) => {
      try {
        const status = await doJWTSignIn(token);
        clearTimeout(timeout_id);
        setStatus(status);
        if (
          [
            SignInWithJWTStates.ERROR,
            SignInWithJWTStates.FAILED_GET_CUSTOM_TOKEN,
            SignInWithJWTStates.FAILED_LOGIN_CUSTOM_TOKEN,
          ].includes(status)
        ) {
          navigate("#error-auth-error");
        } else if (status === SignInWithJWTStates.SUCCESS) {
          // If success, remove the token from the url
          navigate(location.pathname);
        }
      } catch (error) {
        clearTimeout(timeout_id);
        setStatus(SignInWithJWTStates.ERROR);
        console.error(error);
        navigate("#error-auth-error");
      }
    };

    if (token) {
      timeout_id = setTimeout(() => {
        navigate("#error-auth-error");
      }, 30 * 1000);
      asyncHandler(token);
    } else if (!token && user) {
      // All good, no token but user already logged in
    } else if (!token && !user && isFbAuthInit) {
      // Error, force a refresh from the native app
      // If needed to prevent an infinite loop since location is in the
      // dependency list of the useEffect
      if (location.hash !== "#error-auth-error" && !isDesktopVersion()) {
        setStatus(SignInWithJWTStates.ERROR);
        console.error("No token and not logged in -> error");
        navigate("#error-auth-error");
      }
    }

    return () => clearTimeout(timeout_id);
  }, [location, navigate, user, isFbAuthInit]);

  return { status };
};
